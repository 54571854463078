import { DataContext } from "contexts/DataContextContainer";
import useSound from "use-sound";
import { soundSetting } from "service";
import { SoundClickButton } from "assets/audio";
import React, { useContext } from "react";
import Theme from "containers/Theme";
import FreeStar from "components/FreeStar";
import SevenIcon from "components/SevenIcon";
import SingleAntImage from "components/SingleAntImage";
import { StarStreak } from "assets/images";
import { getMultiplier, get777Multiplier } from "utils";
import { currencyFormatter } from "utils/currency-formatter";
import ThemeVisibility from 'components/ThemeVisibility';
import { Trans, useTranslation } from "react-i18next";

const GuideLeft = ({ visible, styleModule }) => {
  const { t, i18n } = useTranslation();
  const {
    currencyFormat,
    bet,
    gameEngineState,
    isPlayingSound,
    setShowPayTable,
  } = useContext(DataContext);
  const [playSoundClickButton] = useSound(SoundClickButton, soundSetting);

  const handleClose = () => {
    isPlayingSound && playSoundClickButton();
    setShowPayTable(false);
  };

  const {
    GuideLeftContainer,
    SingleSeven,
    PayoutsHeader,
    PayoutsSubHeader,
    SectionTable,
    GroupOneHeaderWrapper,
    Center,
    Label,
    LabelWrapper,
    Header,
    FifteenRollsWrapper,
    WrapContents,
  } = styleModule;

  if (!visible) {
    return null;
  }

  const payoutLine = (line) => {
    if (getMultiplier(gameEngineState, line) !== 'free-game') {
      return (
        <td>
          <span>{currencyFormatter(bet * getMultiplier(gameEngineState, line), currencyFormat)}</span>
        </td>
      )
    }
    return (
      <td className="free-game"><FreeStar /> {t('common.freeGame')}</td>
    )
  }

  return (
    <GuideLeftContainer className="modal-paytable cricket-paytable">
      <WrapContents>
        <i
          className="fas fa-times close-modal"
          onClick={handleClose}
        />
        <PayoutsHeader>{ t('paytable.payoutTitle') }</PayoutsHeader>
        <PayoutsSubHeader>{ t('paytable.payoutSubTitle') }</PayoutsSubHeader>
        <SectionTable className="group-1">
          <thead>
            <tr>
              <th>
                <GroupOneHeaderWrapper>
                <ThemeVisibility themes={['dice']}>
                  <Trans t={t}>paytable.anyLineDiagonal</Trans>
                </ThemeVisibility>
                <ThemeVisibility themes={['baseball']}>
                  <Trans t={t}>paytable.baseball.anyLineDiagonal</Trans>
                </ThemeVisibility>
                <ThemeVisibility themes={['cricket']}>
                  <Trans t={t}>paytable.cricket.anyLineDiagonal</Trans>
                </ThemeVisibility>
                <ThemeVisibility themes={['bigwheel']}>
                  <Trans t={t}>paytable.bigwheel.anyLineDiagonal</Trans>
                </ThemeVisibility>
                </GroupOneHeaderWrapper>
              </th>
              <th>
              {t('paytable.payout')}
                <div>
                  <Label>{t('paytable.topPrizeAwarded')}</Label>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('paytable.any')} 1 {t('paytable.line')}</td>
              {payoutLine('sb.single.line-1')}
            </tr>
            <tr>
              <td>{t('paytable.anys')} 2 {t('paytable.lines')}</td>
              {payoutLine('sb.single.line-2')}
            </tr>
            <tr>
              <td>{t('paytable.anys')} 3 {t('paytable.lines')}</td>
              {payoutLine('sb.single.line-3')}
            </tr>
            <tr>
              <td>{t('paytable.anys')} 4 {t('paytable.lines')}</td>
              {payoutLine('sb.single.line-4')}
            </tr>
            <tr>
              <td>{t('paytable.anys')} 5 {t('paytable.lines')}</td>
              {payoutLine('sb.single.line-5')}
            </tr>
            <tr>
              <td>{t('paytable.anys')} 6 {t('paytable.lines')}</td>
              {payoutLine('sb.single.line-6')}
            </tr>
            <tr>
              <td>{t('paytable.anys')} 7 {t('paytable.lines')}</td>
              {payoutLine('sb.single.line-7')}
            </tr>
            <tr>
              <td>{t('paytable.anys')} 8 {t('paytable.lines')}</td>
              {payoutLine('sb.single.line-8')}
            </tr>
            <tr>
              <td>{t('paytable.anys')} 9 {t('paytable.lines')}</td>
              {payoutLine('sb.single.line-9')}
            </tr>
            <tr>
              <td>{t('paytable.anys')} 10 {t('paytable.lines')}</td>
              {payoutLine('sb.single.line-10')}
            </tr>
            <tr>
              <td className="two-seven">
              <ThemeVisibility themes={['dice']}>
                {t('paytable.fullCardWith')}
                  <SevenIcon />
                  <SevenIcon />
              </ThemeVisibility>
              <ThemeVisibility themes={['cricket']}>
                {t('paytable.cricket.fullCardWithTwoOuts')}
              </ThemeVisibility>
              <ThemeVisibility themes={['baseball']}>
                {t('paytable.cricket.fullCardWithTwoOuts')}
              </ThemeVisibility>
              <ThemeVisibility themes={['bigwheel']}>
                {t('paytable.bigwheel.fullCardWithTwoOuts')}
              </ThemeVisibility>
              </td>
              <td>
                <span>{currencyFormatter(bet * getMultiplier(gameEngineState, 'sb.single.full-2-strike'), currencyFormat)}</span>
              </td>
            </tr>
            <tr>
              <td className="one-seven">
              <ThemeVisibility themes={['dice']}>
                {t('paytable.fullCardWith')}
                <SevenIcon />
              </ThemeVisibility>
              <ThemeVisibility themes={['cricket']}>
                {t('paytable.cricket.fullCardWithOneOuts')}
              </ThemeVisibility>
              <ThemeVisibility themes={['baseball']}>
                {t('paytable.cricket.fullCardWithOneOuts')}
              </ThemeVisibility>
              <ThemeVisibility themes={['bigwheel']}>
                {t('paytable.bigwheel.fullCardWithOneOuts')}
              </ThemeVisibility>
              </td>
              <td>
                <span>{currencyFormatter(bet * getMultiplier(gameEngineState, 'sb.single.full-1-strike'), currencyFormat)}</span>
              </td>
            </tr>
            <tr>
              <ThemeVisibility themes={['dice']}>
                <td>{t('paytable.fullCardWithZeroStrikes')}</td>
              </ThemeVisibility>
              <ThemeVisibility themes={['cricket']}>
                <td>{t('paytable.cricket.fullCardWithZeroOuts')}</td>
              </ThemeVisibility>
              <ThemeVisibility themes={['baseball']}>
                <td>{t('paytable.cricket.fullCardWithZeroOuts')}</td>
              </ThemeVisibility>
              <ThemeVisibility themes={['bigwheel']}>
                <td>{t('paytable.bigwheel.fullCardWithZeroOuts')}</td>
              </ThemeVisibility>
              <td>
                <span>{currencyFormatter(bet * getMultiplier(gameEngineState, 'sb.single.full-0-strike'), currencyFormat)}</span>
              </td>
            </tr>
          </tbody>
        </SectionTable>
        <SectionTable className="group-2">
          <thead>
            <tr>
              <th colSpan="2">
                <span>{t('paytable.bonusPayouts')}</span>
                <LabelWrapper className="align-middle">
                  <Label>{t('paytable.paidOnce')}</Label>
                </LabelWrapper>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="fifteen-rolls">
                <FifteenRollsWrapper>
                  <SingleAntImage className="star-streak" src={StarStreak} width={25} height="auto" />
                  <ThemeVisibility themes={['dice']}>
                    {t('paytable.15rolls')}
                    <SevenIcon />
                  </ThemeVisibility>
                  <ThemeVisibility themes={['cricket']}>
                    {t('paytable.cricket.15rolls')}
                  </ThemeVisibility>
                  <ThemeVisibility themes={['baseball']}>
                    {t('paytable.baseball.15rolls')}
                  </ThemeVisibility>
                  <ThemeVisibility themes={['bigwheel']}>
                    {t('paytable.bigwheel.15rolls')}
                  </ThemeVisibility>
                </FifteenRollsWrapper>
              </td>
              <td className="free-game"><FreeStar /> {t('common.freeGame')}</td>
            </tr>
            <tr>
              <td className="consecutive-seven">

                <FifteenRollsWrapper>
                  <ThemeVisibility themes={['dice']}>
                    { i18n.language === 'pt' || i18n.language === 'es' ? (
                      <>
                        {t('paytable.any3Cons')}
                        <SevenIcon />
                        &nbsp;{t('paytable.seguidos')}
                      </>
                    ) : (
                      <>
                      {t('paytable.any3Cons')}
                        <SevenIcon />s
                      </>
                    ) }
                  </ThemeVisibility>
                  <ThemeVisibility themes={['cricket']}>
                    {t('paytable.cricket.any3Cons')}
                  </ThemeVisibility>
                  <ThemeVisibility themes={['baseball']}>
                    {t('paytable.baseball.any3Cons')}
                  </ThemeVisibility>
                  <ThemeVisibility themes={['bigwheel']}>
                    {t('paytable.bigwheel.any3Cons')}
                  </ThemeVisibility>
                </FifteenRollsWrapper>
              </td>
              <td>
                <span>{currencyFormatter(bet * get777Multiplier(gameEngineState), currencyFormat)}</span>
              </td>
            </tr>
          </tbody>
        </SectionTable>
        <Center>
          <Header className="sidebet">{t('paytable.sideBets')}</Header>
          <Label className="sidebet-label">
            <Trans t={t}>paytable.sideBetsPay</Trans>
          </Label>
        </Center>
      </WrapContents>
    </GuideLeftContainer>
  );
}


export default Theme(GuideLeft, 'layouts/components/GuideLeft')