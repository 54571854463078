import React, { useEffect, useRef } from 'react'
import Theme from 'containers/Theme';
import { XMarkRotationAnimated } from "assets/images";
import ApngComponent from 'react-apng';

const Hole = ({
  hole,
  holeTimes,
  styleModule,
  animate,
}) => {
  const ref = useRef();

  useEffect(() => {
    if (animate && holeTimes === 2 && ref.current) {
      ref.current.play();
    } else if (ref.current) {
      ref.current.stop();
    }
  }, [holeTimes, animate]);

  return (
    <styleModule.Container className={`hole`}>
      <styleModule.HoleContainer hide={holeTimes < hole} className={`hole`}>
        <ApngComponent rate={2.5} className="strike-x" ref={ref} src={XMarkRotationAnimated} />
      </styleModule.HoleContainer>
    </styleModule.Container>
  )
};

export default Theme(Hole, 'components/Hole');