import React from 'react';
import ThemeVisibility from "components/ThemeVisibility";
import DiceNumberReveal from "components/DiceNumberReveal";
import CricketNumberReveal from "components/CricketNumberReveal";
import BaseballNumberReveal from "components/BaseballNumberReveal";
import BigWheelNumberReveal from "components/BigWheelNumberReveal";
const NumberReveal = () => {
  return (
    <>
      <ThemeVisibility themes={['dice']}>
        <DiceNumberReveal />
      </ThemeVisibility>
      <ThemeVisibility themes={['cricket']}>
        <CricketNumberReveal />
      </ThemeVisibility>
      <ThemeVisibility themes={['baseball']}>
        <BaseballNumberReveal />
      </ThemeVisibility>
      <ThemeVisibility themes={['bigwheel']}>
        <BigWheelNumberReveal />
      </ThemeVisibility>
    </>
  )
};

export default NumberReveal;