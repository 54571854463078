import React, { useEffect, useRef, useContext, useCallback } from 'react';
import { DataContext } from "contexts/DataContextContainer";
import Theme from 'containers/Theme';
import ApngComponent from 'react-apng';
import { currencyFormatter } from 'utils/currency-formatter';
import CountUp from 'react-countup';
import {
	TripleBonusRay,
	BaseballTrophyWin,
	BaseballGlitter,
	BaseballHomeRun,
	BaseballWinLabelContainer,
	BaseballWinLight1,
	BaseballWinLight2,
} from 'assets/images';
import { LineWinAnimationType } from 'service';

const Baseball = ({ type, styleModule }) => {
	const {
		Container,
		Countup,
		HomeRunContainer,
		HomeRunBackground,
		WinLight1,
		WinLight2,
		Jackpot,
		TrophyContainer,
	} = styleModule;
	const raysRef = useRef();
	const {
		bonus, currencyFormat,
	} = useContext(DataContext);

	const formatValueCallback = useCallback((val) => {
    return currencyFormatter(val, currencyFormat);
	})

	useEffect(() => {
		if (raysRef && raysRef.current) {
			raysRef.current.play();
		}
	}, []);

	return <Container className="line-win-animation">
		<TrophyContainer>
			<img className="glitter g1" src={BaseballGlitter} />
			<img className="glitter g2" src={BaseballGlitter} />
			<img className="glitter g3" src={BaseballGlitter} />
			<img className="glitter g4" src={BaseballGlitter} />
			<img className="glitter g5" src={BaseballGlitter} />
			<img className="glitter g6" src={BaseballGlitter} />
			<img className="glitter g7" src={BaseballGlitter} />
			<ApngComponent className="rays" ref={raysRef} src={TripleBonusRay} />
			<img className="trophy" src={BaseballTrophyWin} alt="" />
		</TrophyContainer>
		<HomeRunContainer>
			<WinLight1 src={BaseballWinLight1} />
			<WinLight2 src={BaseballWinLight2} />
			<HomeRunBackground src={BaseballWinLabelContainer} />
			<img className="home-run" src={BaseballHomeRun} />
		</HomeRunContainer>
		<Jackpot hide={type !== LineWinAnimationType.FullCard}>
			<div>Jackpot</div>
		</Jackpot>
		<Countup>
			<CountUp formattingFn={formatValueCallback} end={bonus} duration={5} decimals={2} delay={0}>
				{({ countUpRef }) => (
					<span
					ref={countUpRef}
					style={{
						color: '#f2de00',
						fontWeight: 'bold',
						fontSize: '3vh',
						fontFamily: 'Roboto',
					}}
					/>
				)}
			</CountUp>
		</Countup>
	</Container>;
}

export default Theme(Baseball, 'components/LineWinAnimation');