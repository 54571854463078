import styled from "@emotion/styled";
import { mixins } from "theme";

export const InstructionContainer = styled.div`
  position: absolute;
  width: calc(100% + 30px);
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  z-index: 1;
  .pochingo-logo {
    img {
      width: 327px;
    }
  }
  .hit-streak-text {
    color: #face00;
    top: 2em !important;
    position: relative;
    /* left: -31% !important; */
    /* width: 24%; */
    z-index: 9;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: 'Impact';
    text-align: center;
    text-transform: uppercase;
    left: -157px;
    text-align: center;
    .hit-streak-small {
      font-size: 1rem;
      top: 2.1em !important;
    }
    .hit-streak-medium {
      /* font-size: 0.8rem;
      position: relative;
      top: -0.3em !important; */
    }
  }
  .ins-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: calc(100% - 30px);
    height: 100%;

    &.no-justify {
      justify-content: unset;
    }

    .btn-guide {
      z-index: 1;
      cursor: pointer;
      transition: opacity 0.45s cubic-bezier(0.25, 1, 0.33, 1),
        transform 0.45s cubic-bezier(0.25, 1, 0.33, 1),
        border-color 0.45s cubic-bezier(0.25, 1, 0.33, 1),
        color 0.45s cubic-bezier(0.25, 1, 0.33, 1),
        background-color 0.45s cubic-bezier(0.25, 1, 0.33, 1),
        box-shadow 0.45s cubic-bezier(0.25, 1, 0.33, 1);
      &:hover {
        transform: translateY(-3px);
      }
      &.guide1 {
        margin-top: 20px;
      }
      &.guide2 {
        margin-top: 20px;
      }
      &.guide3 {
        margin-top: 20px;
      }
      &.guide3Pt {
        position: relative;
        top: 10%;
      }
      &.guide4 {
        margin-top: 20px;
      }
      &.guide5 {
        margin-top: 20px;
      }
    }
  }
  .instruction-toggle {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .music-toggle {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 165px;
    margin-left: -300px;
    font-size: 24px;
    color: #ffffff;

    .ant-image-img {
      width: 100%;
    }
  }
  .icon-sound {
    position: absolute;
    bottom: 165px;
    right: 45px;
    cursor: pointer;
    i {
      font-size: 24px;
      color: white;
    }
  }
  @media (max-width: ${mixins.mobileWidth}px),
    (max-height: ${mixins.ipadHeight}px) {
    .ins-wrapper {
      .btn-guide {
        width: 128px;
        height: 47.5px;
        #blockAnim {
          #textOverlay {
            font-size: 22px !important;
          }
        }
        &.guide1 {
          top: 143px;
        }
        &.guide2 {
          top: 135px !important;
          left: 47px;
        }
        &.guide3 {
          top: 4% !important;
        }
        &.guide5 {
          top: 115px !important;
        }
      }
    }
  }
  @media (max-width: ${mixins.mobileWidth}px),
    (max-height: ${mixins.mobileHeight}px) {
    .instruction-toggle,
    .music-toggle,
    .icon-sound {
      bottom: 10px;
    }
  }

  .fa-times-circle {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 15px;
    margin-top: 40px;
    z-index: 2;
  }
`;

export const CloseButton = styled.i`
  cursor: pointer;
  font-size: 20pt;
  color: #ffffff;
  margin-top: 16px;
`;

export const TranslateDiv = styled.div`
  font-family: "Roboto";
  color: #d06f07;
  font-weight: 500;
  position: absolute;
  z-index: 9999;
  font-size: 20px;
  left: 13px;
  line-height: 1;
  text-align: center;
  width: 269px;
`;

export const BoldText = styled.div`
  font-weight: bold;
  font-size: 23px;
`;

export const InstructionOne = styled(TranslateDiv)`
  z-index: 999;
  height: 115px;
`;

export const InstructionTwo = styled(TranslateDiv)`
  z-index: 999;
  left: 23px;
  top: 7%;
`;

export const InstructionThree = styled(TranslateDiv)`
  z-index: 999;
  left: 17px;
  top: 9%;
  .guide-screen-left {
    .modal-paytable {
      top: 20rem !important;
      @media screen and (min-width: 431px) {
        position: absolute;
        left: -2px;
        top: 6rem !important;
      }
      & .close-modal {
        visibility: hidden !important;
      }
      & > div {  
        transform: scale(0.9) !important; 
      }
      .singleseven-en {
        left: 151px !important;
        top: 122px !important;
      }
      .singleseven-es {
        left: 165px !important;
        top: 122px !important;
        @media screen and (max-height: 650px) {
          left: 163px !important;
          top: 123px !important;
        }
      }
      .singleseven-pt {
        left: 151px !important;
        top: 123px !important;
      }
    }
  }
`;

export const InstructionThreeEn = styled(TranslateDiv)`
  z-index: 999;
  left: 17px;
  top: 11%;
`;

export const InstructionFour = styled(TranslateDiv)`
  z-index: 999;
  left: 18px;
  top: 626px;
`;

export const InstructionFive = styled(TranslateDiv)`
  z-index: 999;
  left: 24px;
  top: 7%;
`;

export const InstructionImageContainer = styled(TranslateDiv)`
  z-index: 999;
  position: absolute;
  width: 99%;
  left: 0;
  top: 28%;
`;

export const BatOnBall = styled.div`
  position: absolute;
  top: 1%;
  left: 3.438rem;
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
  z-index: 999;
`;
