/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { ScaleLoader } from "react-spinners";
import customAxios from 'service/customAxios';
import { LogsContainer } from './style';
import moment from 'moment';
import LogPane from './LogPane';
import { Select } from 'antd';

const { Option } = Select;

function Logs({
  visible = false,
}) {

  const [logs, setLogs] = useState();
  const [ips, setIps] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getLogsByIp = async (ip) => {
    setLoading(true);
    const { ok, data } = await customAxios.get(`rounds/getRoundsByIp?ip=${ip}`);
    if (ok) {
      setLogs(data)
      let tmpSessions = [];
      data.forEach(_data => {
        if (!tmpSessions.includes(_data.sessionId)) {
          tmpSessions.push(_data.sessionId)
        }
      })
      setSessions(tmpSessions);
    }
    setLoading(false);
  }

  const getAllIps = async () => {
    const { ok, data } = await customAxios.get(`rounds/getAllIps`);
    if (ok) {
      setIps(data)
    }
  }

  useEffect(() => {
    visible && getAllIps();
  }, [visible])

  return (
    <Scrollbars autoHide>
      <LogsContainer>
        <div className='logs-wrapper'>
          <Select placeholder='Select Ip Address..' style={{ width: '100%' }} onChange={getLogsByIp}>
            {ips.map((_ip, _i) =>
              <Option key={_i} value={_ip}>{_ip}</Option>
            )}
          </Select>
          {loading ? <ScaleLoader size={30} color={"white"} /> : ips && ips.length > 0 &&
            sessions.map((_session, _i) =>
              <div className='session-container'>
                <h3 className='session-title'>Session {_i + 1}</h3>
                <div className='session-items-container'>
                  {logs.filter((_log) => _log.sessionId === _session).map((_log, _j) => {
                    // let logsByIp = logs.filter((_log) => _log.ip === _ip)
                    return (
                      <div className='session-item'>
                        <LogPane key={_j} log={_log} />
                        <p className='session-item-date'>{moment(_log.createdAt).format('YYYY-MM-DD hh:mm')}</p>
                        <p className='session-item-rollCnt'><b>{_log.rollCnt}</b> times rolled</p>
                        <p className='session-item-duration'><b>{_log.duration}</b> s</p>
                        {/* <p className='session-item-duration'><b>{logsByIp[_j - 1] && logsByIp[_j - 1].duration}</b> s</p> */}
                        {/* <p className='session-item-duration'><b>{_log.sessionId}</b></p> */}
                        <p className='session-item-numbers'>
                          {_log.logs.map((_num, _k) =>
                            <span>[<b>{_num.value}</b>-{_num.pos}]</span>
                          )}
                        </p>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          }
        </div>
      </LogsContainer>
    </Scrollbars>
  );
}

export default Logs;