import React, { useContext } from 'react';
import { DataContext } from "contexts/DataContextContainer";
import Theme from 'containers/Theme';
import { currencyFormatter } from 'utils/currency-formatter';
import {
	TripleBonusRay,
	BigWheelRedCircleBonus,
	BigWheelBannerBonus,
	BigWheelThree,
} from 'assets/images';

const Bigwheel = ({ win, styleModule }) => {
	const {
		Container,
		PrizeContainer,
		RedBanner,
		RayCirclecOntainer,
	} = styleModule;
  const { bonus, currencyFormat } = useContext(DataContext);
	
	if (!win) {
		return null;
	}

	return <Container>
		<RedBanner>
			<div className="img-text">
				<div className="text">
					<img className="three" src={BigWheelThree} />
					<span className="gremlin">GREMLINS</span>
					<span className="bonus">BONUS!</span>
				</div>
				<img className="bonus-banner" src={BigWheelBannerBonus} />
			</div>
		</RedBanner>
		<RayCirclecOntainer>
			<img className="rays" src={TripleBonusRay} />
			<img className="red-circle" src={BigWheelRedCircleBonus} />
			<PrizeContainer>{currencyFormatter(bonus, currencyFormat)}</PrizeContainer>
		</RayCirclecOntainer>
	</Container>
}

export default Theme(Bigwheel, 'components/TripleSevenAnimation');