export { default as BgCoinContainer } from './bgCoinContainer2.png';
export { default as BgHolesContainer } from './holes.png';
export { default as BgHole } from './bgHole.png';
export { default as Pane } from './paneContainer.png';
export { default as BtnRoll } from './btn.gif';
export { default as NumBlue } from './numBlue.png';
export { default as NumGreen } from './numGreen.png';
export { default as NumOrange } from './numOrange.png';
export { default as NumWhite } from './numWhite.png';
export { default as Num7 } from './num7.png';
export { default as JackPot } from './jackpot.png';
export { default as JackPotAnimated } from './jackpot_animated.png';
export { default as AnimatedBarWinNoText } from './no_strike_animate_no_text.png';
export { default as Handler } from './handler.png';
export { default as WIN } from './WIN2.png';
export { default as Ray } from './RAY.png';
export { default as Star1 } from './Star1.png';
export { default as Star2 } from './Star2.png';
export { default as Star3 } from './Star3.png';
export { default as Star4 } from './Star4.png';
export { default as Star5 } from './Star5.png';
export { default as Star6 } from './Star6.png';
export { default as IconCheck } from './icon-check.png';
export { default as StarSilver } from './StarSilver.png';
export { default as IconCheckNum } from './icon-checknum.png';
export { default as BetPane } from './BetPane.png';
export { default as OneStrike } from './OneStrike.png';
export { default as TwoStrike } from './TwoStrike.png';
export { default as NoStrike } from './NoStrike.png';
export { default as BalanceValue } from './BalanceValue.png';
export { default as Border } from './border1.png';
export { default as StarStreak } from './StarStreak.png';
export { default as FreeStarLeft } from './FreeGame/FreeStarLeft.png';
export { default as FreeStarPane } from './FreeGame/FreeStarPane.png';
export { default as FreeStarLeftPt } from './FreeGame/pt/FreeStarLeft.png';
export { default as FreeStarPanePt } from './FreeGame/pt/FreeStarPane.png';
export { default as FreeStarRightPt } from './FreeGame/pt/FreeStarRight.png';
export { default as FreeStarLeftEs } from './FreeGame/es/FreeStarLeft-es.png';
export { default as FreeStarPaneEs } from './FreeGame/es/FreeStarPane-es.png';
export { default as FreeStarRightEs } from './FreeGame/es/FreeStarRight-es.png';
export { default as FakeDice } from './guide/FakeDice.png';
export { default as FakeDice12 } from './guide/FakeDice12.png';
export { default as WhiteFinger } from './guide/WhiteFinger.png';
export { default as FakePane } from './guide/FakePane.png';
export { default as BtnGotit } from './guide/BtnGotit.png';
export { default as NewGuide1 } from './guide/default/guide1.png';
export { default as NewGuide2 } from './guide/default/guide2.png';
export { default as NewGuide3 } from './guide/default/guide3.png';
export { default as NewGuide4 } from './guide/default/guide4.png';
export { default as NewGuide5 } from './guide/default/guide5.png';
export { default as GuidePayoutPt } from './guide/pt/guide-payout.png';
export { default as RollAgainDialog } from './guide/roll-again-dialog.png';
export { default as RollAgainFemale } from './guide/roll-again-female.png';
export { default as Logo } from './Logo.png';
export { default as MessageContainer } from './MessageContainer.png';
export { default as JackpotContainer } from './Jackpot_container.png';
export { default as BetBackground } from './bet-bg.png';
export { default as BetMore } from './bet-more.png';
export { default as BetMorePt } from './pt/bet-more.png';
export { default as BetMoreEs } from './es/bet-more-es.png';

export { default as FullCard } from './full-card.png';
export { default as FullCardPt } from './pt/full-card.png';
export { default as FullCardEs } from './es/full-card-es.png';
export { default as OneStrikeContainer } from './1-strike.png';
export { default as TwoStrikeContainer } from './2-strikes.png';
export { default as NoStrikeContainer } from './no-strikes.png';
export { default as OneStrikeContainerPt } from './pt/1-strike.png';
export { default as TwoStrikeContainerPt } from './pt/2-strikes.png';
export { default as NoStrikeContainerPt } from './pt/no-strikes.png';
export { default as BetButtonContainer } from './btn-bet.png';
export { default as ExtraActionsContainer } from './btn-extra-actions.png';
export { default as GreenMinusContainer } from './green-minus-container.png';
export { default as GreenPlusContainer } from './green-plus-container.png';
export { default as MenuButton } from './btn-menu.png';
export { default as ToggleOnContainer } from './autopick-on.png';
export { default as ToggleContainer } from './autopick_border.png';
export { default as ToggleButton } from './btn-toggle.png';
export { default as TripleBonusRay } from './triple_bonus_ray.png';
export { default as TripleWordAnimated } from './triple_word_animated.png';
export { default as TripleWordAnimatedPt } from './pt/triple_word_animated.png';
export { default as TripleWordAnimatedEs } from './es/triple_word_animated.png';
export { default as TripleWordCover } from './triple_word_cover.png';
export { default as TripleWordCoverDefault } from './triple-word-cover-default.png';
export { default as XMarkRotationAnimated } from './x_mark_rotation_animated.png';
export { default as BonusMeterContainer } from './bonus-meter-container.png';
export { default as Trophy } from './trophy.png';
export { default as JackpotCountupContainer } from './jackpot-countup-container.png';
export { default as PayTableContainer } from './paytable_container.png';
export { default as RedDice7 } from './red_dice_7.png';
export { default as CoinVector } from './coin-vector.png';
export { default as NextConditionContainer } from './next-condition-container.png';
export { default as Particle } from './Particle_tile_click.png';
export { default as SideBetAccept } from './side_bet_accept.png';
export { default as SideBetDecline } from './side_bet_decline.png';
export { default as NoStrikeAnimated } from './no_strike_animated.png';
export { default as NoStrikeAnimatedPt } from './pt/no_strike_animated.png';
export { default as CheckMark } from './check_mark.png';
export { default as XMark } from './x_mark.png';
export { default as OneMoreSeven } from './onemore7.png';
export { default as OneMoreSevenPt } from './pt/onemore7.png';
export { default as OneMoreSevenEs } from './es/onemore7.png';

//Speech bubble
export { default as SpeechBubbleImage } from './char_speech_bubble.png';

// Cricket images
export { default as CricketPaneContainer } from './cricket/cricket-paneContainer.png';
export { default as Umpire } from './cricket/umpire/umpire.png';
export { default as UmpireRed } from './cricket/umpire/red-box.png';
export { default as UmpireBlue } from './cricket/umpire/blue-box.png';
export { default as UmpireOrange } from './cricket/umpire/orange-box.png';
export { default as CricketBackground } from './cricket/cricket-bg.jpg';
export { default as CricketScoreBoardBackground } from './cricket/cricket-score-board.png';
export { default as CricketOutRedBall } from './cricket/cricket-red-ball.png';
export { default as ScoreBoardBraces } from './cricket/score-board-braces.png';
export { default as MetalBeamBackground } from './cricket/metal-beam-with-spotlights.png';
export { default as PlayballIntro } from './cricket/playball_intro.png';
export { default as PlayballOutro } from './cricket/playball_outro.png';
export { default as CricketRollBtnIntro } from './cricket/cricket_roll_btn_intro.png';
export { default as CricketRollBtnLoop } from './cricket/cricket_roll_btn_loop.png';
export { default as CricketRollBtnOutro } from './cricket/cricket_roll_btn_outro.png';
export { default as ParticleTileClick } from './cricket/particle_tile_click.png';
export { default as SmallContainer } from './cricket/smallContainer1.png';
export { default as BigContainer } from './cricket/bigContainer1.png';
export { default as CricketNoStrikeAnimated } from './cricket/no_strike_animated.png';
export { default as CricketLogo } from './cricket/Logo.png';
export { default as CricketGuide1 } from './cricket/guide/guide1.png';
export { default as CricketPitchIntro } from './cricket/bowler_intro_anim.png';
export { default as CricketPitchOut } from './cricket/bowler_out_anim.png';
export { default as CricketPitchOutro } from './cricket/bowler_outro_anim.png';
export { default as RolingRedBall } from './cricket/rolling_red_anim.png';
export { default as IntroBallAlign } from './cricket/ball_align.png';
export { default as IntroBallContainer } from './cricket/ball_container.png';
export { default as OutBar } from './cricket/out_container.png';
export { default as CricketOutPerson } from './cricket/out_person.png';
export { default as CricketFakePane } from './cricket/guide/fake_guide.png';
export { default as CricketGuide3 } from './cricket/guide/guide3.png';
export { default as CricketGuide2 } from './cricket/guide/guide2.png';
export { default as CricketGuide4 } from './cricket/guide/guide4.png';
export { default as CricketGuide5 } from './cricket/guide/guide5.png';

// Baseball
export { default as BaseballBackground } from './baseball/baseball-background.jpg';
export { default as BaseballUmpire } from './baseball/umpire/umpire.png';
export { default as BaseballBallIntro } from './baseball/baseball_ball_intro.png';
export { default as BaseballBallOutro } from './baseball/baseball_ball_outro.png';
export { default as BaseballLauncher } from './baseball/baseball_launcher.png';
export { default as BaseballRollButton } from './baseball/ball_refresh.png';
export { default as BaseballPitchIntro } from './baseball/Launcher_animated.png';
export { default as BaseballPitchOut } from './baseball/whiteball_animated.png';
export { default as BaseballHit } from './baseball/particle_hit.png';
export { default as BaseballRollingRedBall } from './baseball/flying_red.png';
export { default as BaseballRollingRedWhiteBall } from './baseball/flying_redwhite_ball.png';
export { default as BaseballMoneyContainer } from './baseball/money_container.png';
export { default as BaseballGuide1 } from './baseball/guide/guide1.png';
export { default as BaseballGuide2 } from './baseball/guide/guide2.png';
export { default as BaseballGuide3 } from './baseball/guide/guide3.png';
export { default as BaseballGuide4 } from './baseball/guide/guide4.png';
export { default as BaseballGuide5 } from './baseball/guide/guide5.png';
export { default as BaseballOutPerson } from './baseball/ampire_strikeback.png';
export { default as BaseballFoulBall } from './baseball/foul-ball-text.png';
export { default as BaseballBallWhite } from './baseball/white-ball@2x.png';
export { default as BaseballFakePane } from './baseball/guide/fake_guide.png';
export { default as BaseballTrophyWin } from './baseball/win-animation/trophy-win.png';
export { default as BaseballHomeRun } from './baseball/win-animation/home-run.png';
export { default as BaseballWinLabelContainer } from './baseball/win-animation/win-label-container.png';
export { default as BaseballWinLight1 } from './baseball/win-animation/win-light-1.png';
export { default as BaseballWinLight2 } from './baseball/win-animation/win-light-2.png';
export { default as BaseballJackpotRedContainer } from './baseball/win-animation/jackpot-red-container.png';
export { default as BaseballCountupContainer } from './baseball/win-animation/count-up-container.png';
export { default as BaseballGlitter } from './baseball/win-animation/glitter.png';
export { default as BaseballScoreBoardBackground } from './baseball/baseball-score-board.png';
export { default as BaseballScoreBoardBraces } from './baseball/score-board-braces.png';
export { default as BaseballMetalBeamBackground } from './baseball/metal_beam_with_spotlights.png';
export { default as BaseballSmallBball } from './baseball/small_bball.png';
export { default as BaseballOutsRedBall } from './baseball/red-ball@2x.png';
export { default as BaseballLogo } from './baseball/Logo.png';
export { default as BaseballRedBall } from './baseball/red_ball.png';
export { default as BaseballWhiteBall } from './baseball/white_ball.png';

// Bigwheel
export { default as BigWheelBoardContainer } from './bigwheel/bigw_board_container.png';
export { default as BigWheelStrikesContainer } from './bigwheel/strikes-container.png';
export { default as BigWheelGremlinStrike1 } from './bigwheel/strike-gremlin-1.png';
export { default as BigWheelGremlinStrike2 } from './bigwheel/strike-gremlin-2.png';
export { default as BigWheelGremlinStrike3 } from './bigwheel/strike-gremlin-3.png';
export { default as BigWheelCenterGremlin } from './bigwheel/center-gremlin.png';
export { default as BigWheelCenterGremlinOrange } from './bigwheel/center-gremlin-orange.png';
export { default as BigWheelCenterGremlinRed } from './bigwheel/center-gremlin-red.png';
export { default as BigWheelLogo } from './bigwheel/Logo.png';
export { default as BigWheelGreenTileBg } from './bigwheel/green-tile-bg.png';
export { default as BigWheelPurpleTileBg } from './bigwheel/purple-tile-bg.png';
export { default as BigWheelJackpotContainer } from './bigwheel/jackpot-container.png';
export { default as BigWheelFreeRoll } from './bigwheel/free-roll.png';
export { default as BigWheelRoundedRectangle } from './bigwheel/rounded-rectangle.png';
export { default as BigWheelStar } from './bigwheel/star.png';
export { default as BigWheelGuide1 } from './bigwheel/guide/guide1.png';
export { default as BigWheelGuide2 } from './bigwheel/guide/guide2.png';
export { default as BigWheelGuide3 } from './bigwheel/guide/guide3.png';
export { default as BigWheelGuide4 } from './bigwheel/guide/guide4.png';
export { default as BigWheelGuide5 } from './bigwheel/guide/guide5.png';
export { default as BigWheelWinContainer } from './bigwheel/win-container.svg';
export { default as BigWheelFakePane } from './bigwheel/guide/fake_guide.png';
export { default as BigWheelGremlin } from './bigwheel/gremlin-face1.png';
export { default as BigWheelNextConditionContainer } from './bigwheel/next-condition-container.png';
export { default as BigWheelSpinBtn } from './bigwheel/spin_button_en.png';
export { default as BigWheelSpinWheel } from './bigwheel/wheel-copy@3x.png';
export { default as BigWheelBannerBonus } from './bigwheel/bonus-banner.png';
export { default as BigWheelRedCircleBonus } from './bigwheel/red-bonus-circle.svg';
export { default as BigWheelTripleGremlin } from './bigwheel/triple-gremlin.png';
export { default as BigWheelThree } from './bigwheel/three.png';
export { default as BigWheelBarberPole } from './bigwheel/barberpole/barberpole-bg.png';
export { default as BigWheelStripe } from './bigwheel/barberpole/redstripe-barber-pole.png';
export { default as BigWheelWheel } from './bigwheel/inside-wheel.png';
export { default as BigWheelWheel1 } from './bigwheel/inside-wheel-1.png';
export { default as BigWheelWheel2 } from './bigwheel/inside-wheel-2.png';
export { default as BigWheelWheel3 } from './bigwheel/inside-wheel-3.png';
export { default as BigWheelWheelCircle } from './bigwheel/wheel-circle@3x.png';
export { default as BigWheelLight1 } from './bigwheel/light1.png';
export { default as BigWheelLight2 } from './bigwheel/light2.png';
export { default as BigWheelGremlinBlue } from './bigwheel/gremlins/gremlin_blu.png';
export { default as BigWheelGremlinBlueIdle } from './bigwheel/gremlins/bluemonster_idle.png';
export { default as BigWheelGremlinBlueLaunch } from './bigwheel/gremlins/bluemonster_launch.png';
export { default as BigWheelGremlinPink } from './bigwheel/gremlins/gremlin_kakamfink.png';
export { default as BigWheelGremlinPinkIdle } from './bigwheel/gremlins/pinkmonster_idle.png';
export { default as BigWheelGremlinPinkLaunch } from './bigwheel/gremlins/pinkmonster_launch.png';
export { default as BigWheelGremlinYellow } from './bigwheel/gremlins/gremlin_yelo.png';
export { default as BigWheelGremlinYellowIdle } from './bigwheel/gremlins/orangemonster_idle.png';
export { default as BigWheelGremlinYellowLaunch } from './bigwheel/gremlins/orangemonster_launch.png';
export { default as BigWheelLaunchSmoke } from './bigwheel/gremlins/launchsmoke.png';
export { default as BigWheelLandingSmoke } from './bigwheel/gremlins/landingsmoke.png';
export { default as BigWheelLineWinContainer } from './bigwheel/line-win/container.png';
export { default as BigWheelLineWinMessage } from './bigwheel/line-win/line_win.png';
export { default as BigWheelLineMessageContainer } from './bigwheel/line-win/line-win-container.png';
export { default as BigWheelLineRay } from './bigwheel/line-win/ray.png';
export { default as BigWheelStarIntro } from './bigwheel/free-game/StarIntro.png';
export { default as BigWheelStarLoop } from './bigwheel/free-game/Starloop.png';
export { default as BigWheelStarIntroEs } from './bigwheel/free-game/es/StarIntro-es.png';
export { default as BigWheelStarLoopEs } from './bigwheel/free-game/es/StarLoop-es.png';
export { default as BigWheelStarIntroPt } from './bigwheel/free-game/pt/StarIntro-pt.png';
export { default as BigWheelStarLoopPt } from './bigwheel/free-game/pt/StarLoop-pt.png';

export { default as WinBgContainerEn } from './win-bg-container/win-en.png';
export { default as WinBgContainerPt } from './win-bg-container/win-pt.png';
export { default as WinBgContainerEs } from './win-bg-container/win-es.png';

export { default as BigWheelSpinBtnOthers } from './bigwheel/spin_button_others.png';
