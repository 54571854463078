import { Row, Col } from "antd";
import moment from "moment";
import {
  FreeStarPane,
  FreeStarPanePt,
  FreeStarPaneEs,
  MessageContainer,
} from "assets/images";
import Box from "components/Box";
import PlayballIntro from "components/PlayballIntro";
import SingleAntImage from "components/SingleAntImage";
import SingleText from "components/SingleText";
import React, { Fragment, useContext, useEffect } from "react";
import {
  sizes,
  numberGroup,
  credits,
  numbersTemp,
  statusColors,
  pairs,
  getFirstOrange,
  LineWinAnimationType,
} from "service";
import { Fade } from "react-awesome-reveal";
import Number from "components/Number";
import NumberReveal from "components/NumberReveal";
import Strikes from "components/Strikes";
import { DataContext } from "contexts/DataContextContainer";
import { ThemeContext } from "contexts/ThemeContext";
import { useHistory } from "react-router-dom";
import Toggle from "components/Toggle";
import LetsRoll from "components/LetsRoll";
import TripleSevenAnimation from "components/TripleSevenAnimation";
import RollBonusMeter from "components/RollBonusMeter";
import Theme from "containers/Theme";
import { mapGameEngineBoard } from "utils";
import { roll, pick } from "api";
import { getBoardCoordinate } from "utils";
import ThemeVisibility from "components/ThemeVisibility";
import CricketScoreBoard from "components/CricketScoreBoard";
import { Trans, useTranslation } from "react-i18next";
import { updateRefWithSounds } from "service/sound";

const moveRoundBonusDelay = 5000;

function Rolling(props) {
  const { styleModule, controller, themeSounds } = props;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dataContext = useContext(DataContext);
  const {
    autoRef,
    balance,
    setBalance,
    bet,
    bonus,
    setBonus,
    isGameOver,
    setIsGameOver,
    setWinnedLines,
    holeTimes,
    setHoleTimes,
    activeBtn,
    showRollButton,
    skipping,
    setSkipping,
    skippingCount,
    setSkippingCount,
    filled,
    setFilled,
    freeCnt,
    setFreeCnt,
    showFreeCelebration,
    setShowFreeCelebration,
    streakStrike,
    setStreakStrike,
    show777,
    setShow777,
    isAuto,
    setIsAuto,
    isPlayingSound,
    setIsInstruction,
    setShowLineWinAnimation,
    statuses,
    setStatuses,
    rollCnt,
    rollCntRef,
    setRollCnt,
    gameEngineState,
    setGameEngineState,
    setError,
    isRolling,
    setIsRolling,
    waitingForRoll,
    isPicking,
    skippingRef,
    isSideBetLoadingRef,
    sideBet,
    setSideBet,
    rollRef,
    rollAllRef,
    sideBetsEnabled,
    setSideBetsEnabled,
    rollButtonEnabled,
    setRollButtonEnabled,
    setSideBetWin,
    setShowSideBetWin,
    statusesRef,
    startedRound,
    setStartedRound,
    secondStrikeRef,
    diceFaceInterval,
    isPlayingSoundRef,
    gameOverTimeoutRef,
    gameEngineStateRef,
    newWinGroupKeys,
    setNewWinGroupKeys,
    showConfetti,
    setShowConfetti,
    rolledNumRef,
    rolledNum,
    setRolledNum,
    showAnimationStrike,
    setShowAnimationStrike,
    startedFreeGame,
    setStartedFreeGame,
    showWinAnimation,
    showGameOverBanner,
    setShowGameOverBanner,
    setLastMoment,
    freeGameAwarded,
    setFreeGameAwarded,
    setShowWinAnimation,
    gameEvents,
    fromBetScreen,
    audioRef,
    showSideBetPopup,
    displayOuts,
    setDisplayOuts,
    setSideBetsIntro,
    isAutoRef,
    sideBetsIntro,
    isTurbo,
    setIsTurbo,
    freeGameAvailable,
    setFreeGameAvailable,
  } = dataContext;
  isPlayingSoundRef.current = isPlayingSound;
  const sounds = themeSounds.useSound(isPlayingSoundRef.current);
  const {
    playRollingBallSuspense,
    playSuspense777,
    stopSuspense777,
    playSoundClickButton,
    playSoundOrangeTileDelay,
    playSoundRolling,
    stopSoundRolling,
    playSoundDuplicate,
    playSideBetWinner,
    playCountUpWin,
    playGameOver,
    playJackpotSound,
    playSwooshSound,
    playTripleSevenLastStrike,
    playSoundOrangeClick,
    playOut,
  } = sounds;
  updateRefWithSounds(audioRef, sounds);
  const theme = useContext(ThemeContext);
  const totalPicked = statuses.filter(
    (status) => status === statusColors.blue || status === statusColors.green
  ).length;
  const gameOverMessage =
    holeTimes >= 3 ? t(controller.thirdStrikeTrans()) : t("common.gameOver");
  // const [playSoundClickButton] = useSound(SoundClickButton, soundSetting);
  const getRandomNumbers = () => {
    let result1 = Math.floor(Math.random() * (6 - 1 + 1)) + 1;
    let result2 = Math.floor(Math.random() * (6 - 1 + 1)) + 1;
    if (result1 + result2 == 7 && props.location.search.indexOf("god") !== -1) {
      return getRandomNumbers();
    }
    return [result1, result2];
  };

  const handleRollToggle = (val) => {
    window.ga_log_user_click(`sidebet-toggle-${val}`);
    if (isRolling || sideBet !== undefined) {
      return;
    }
    isPlayingSound && playSoundClickButton();

    setRollButtonEnabled(val);

    if (val) {
      setIsAuto(false);
    }
  };

  const handleAutopickChanged = (val) => {
    window.ga_log_user_click(`auto-pick-toggle-${val}`);
    isPlayingSound && playSoundClickButton();
    const firstOrange = getFirstOrange(statusesRef.current);
    if ((firstOrange === 0 && !activeBtn) || isPicking.current) {
      return;
    }

    if (val) {
      setSideBet(undefined);
      // setSideBetsEnabled(false);
      setStartedRound(true);
      setRollButtonEnabled(false);
      if (secondStrikeRef.current) {
        clearTimeout(secondStrikeRef.current);
      }
    }
    setIsAuto(val);
  };

  const handleTurboToggle = (val) => {
    setIsTurbo(val);
  };

  const getWinnedLines = (param_statuses) => {
    let winnedKeys = Object.keys(numberGroup).filter((_groupKey) => {
      let lineKey = numberGroup[_groupKey].find(
        (_order) => param_statuses[parseInt(_order) - 1] != statusColors.green
      );
      if (lineKey === undefined) {
        return _groupKey;
      }
    });
    return winnedKeys;
  };

  const formatLastMoment = () => setLastMoment(moment());

  const getNewGreenLines = (order) => {
    let newWinGroupKeys = pairs[order].filter((_key) => {
      let checkHasNotGreen = numberGroup[_key].find(
        (_order) =>
          order != parseInt(_order) &&
          statusesRef.current[parseInt(_order) - 1] != statusColors.blue &&
          statusesRef.current[parseInt(_order) - 1] != statusColors.green
      );
      if (!checkHasNotGreen) {
        return _key;
      }
    });
    return newWinGroupKeys;
  };
  const getCountSuggestions = (order) => {
    return numbersTemp.filter(
      (_number, _i) =>
        _number === numbersTemp[order - 1] &&
        statuses[_i] === statusColors.orange
    ).length;
  };

  useEffect(() => {
    controller.onRollCount({
      rollCntRef,
      setFilled,
      fromBetScreen,
    });
  }, [rollCnt]);

  useEffect(() => {
    if (showAnimationStrike) {
      const timer = setTimeout(() => {
        setShowAnimationStrike(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [showAnimationStrike]);

  useEffect(() => {
    if (!isRolling && startedRound) {
      let clockSoundCounter = 0;
      const intervalOrangeTileDelay = setInterval(() => {
        clockSoundCounter++;

        if (clockSoundCounter <= 2) {
          isPlayingSoundRef.current && playSoundOrangeTileDelay();
        }
      }, 15000);
      return () => {
        clearInterval(intervalOrangeTileDelay);
      };
    }
  }, [isRolling]);

  useEffect(() => {
    if (isPlayingSound) {
      if (isRolling && !isTurbo) {
        playSoundRolling();
        if (sessionStorage.rollCnt) {
          sessionStorage.rollCnt = (parseInt(sessionStorage.rollCnt) + 1) % 4;
        } else {
          sessionStorage.rollCnt = 0;
        }
        controller.stopThemeAnthem(audioRef);
        controller.playOrganSound(
          audioRef,
          parseInt(sessionStorage.rollCnt),
          streakStrike
        );
      } else {
        stopSoundRolling();
      }
    } else {
      controller.stopOrganSound(audioRef);
      controller.stopThemeAnthem(audioRef);
    }
  }, [isRolling, isPlayingSound, isTurbo]);

  useEffect(() => {
    if (displayOuts && isPlayingSound) {
      controller.stopOrganSound(audioRef);
      setTimeout(() => {
        playOut();
      }, 250);
    }
  }, [displayOuts, isPlayingSound]);

  const onClickNumber = async (order) => {
    if (!order) return false;

    document.getElementById(order + "anim").style.opacity = 1;
    setTimeout(() => {
      document.getElementById(order + "anim").style.opacity = 0;
    }, 1000);

    gameEvents.emit("on-click-number", { order });
    formatLastMoment();
    let newWinnedLines = getNewGreenLines(order);
    setNewWinGroupKeys(newWinnedLines);

    if (isAutoRef.current) {
      isPlayingSound &&
        controller.playOrangeClickSound({
          play: playSoundOrangeClick,
          streakStrike,
          rolledNum: rolledNumRef.current,
        });
    }

    let winnedIds = [];
    if (newWinnedLines.length > 0) {
      controller.playThemeSoundGreenLine({ isPlayingSound, sounds, audioRef });
      newWinnedLines.forEach((_groupKey) => {
        winnedIds = [...winnedIds, ...numberGroup[_groupKey]];
      });
    }
    let newStatuses = statusesRef.current.map((_status, _i) => {
      if (winnedIds.includes((_i + 1).toString())) {
        return statusColors.green;
      } else {
        if (_status == statusColors.orange) {
          if (_i === order - 1) {
            return statusColors.blue;
          } else {
            return statusColors.white;
          }
        } else {
          if (_i === order - 1) {
            return statusColors.blue;
          } else {
            return _status;
          }
        }
      }
    });
    setStatuses(newStatuses);
    const coordinates = getBoardCoordinate(order);
    try {
      setShowSideBetWin(null);
      controller.sideBetUndefined({ setSideBet, isAuto });
      if (!rollButtonEnabled) {
        setIsRolling(true);
      }
      isPicking.current = true;
      waitingForRoll.current = false;
      if (rollCntRef.current !== 15) {
        fromBetScreen.current = true;
      }
      gameEvents.emit("pick-api-call", { order });
      const pickResult = await pick(coordinates, isTurbo);
      gameEngineStateRef.current = pickResult;
      isPicking.current = false;
      setGameEngineState(pickResult);

      if (pickResult && pickResult.gameSession) {
        setBonus(pickResult.gameSession.winnings);
        if (pickResult.gameSession.gameState.indexOf("game-over") !== -1) {
          setIsGameOver(true);
          setStatuses(mapGameEngineBoard(pickResult.gameSession.gameBoard));
          return;
        }
      }

      if (!isSideBetLoadingRef.current && !rollButtonEnabled) {
        rollAll(true);
      }

      if (rollButtonEnabled) {
        showRollButton(true);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    if (isRolling) {
      let dice1 = document.getElementById("dice1");
      let dice2 = document.getElementById("dice2");
      disableEndSideAnimations();

      if (!dice1 || !dice2) {
        return;
      }

      if (!isTurbo) {
        dice1.className = "indefinite";
        dice2.className = "indefinite";
      }
    }
  }, [isRolling, isTurbo]);

  const onClickRollBtn = async (auto = false) => {
    window.ga_log_user_click(`roll-btn`);
    formatLastMoment();
    isPlayingSound && playSoundClickButton();
    gameEvents.emit("roll-button-clicked", { auto });
    setStartedRound(true);
    let numbersToRoll = getRandomNumbers();
    rollAll(numbersToRoll);
  };

  useEffect(() => {
    if (isAuto && !isRolling && !waitingForRoll.current && !skipping) {
      let firstOrange = getFirstOrange(statuses);
      if (firstOrange === 0) {
        onClickRollBtn();
      } else {
        onClickNumber(firstOrange);
      }
    }
    controller.autoPickResetSideBet({ setSideBet, isAuto });
  }, [isAuto]);

  const rollAll = (immediate) => {
    const numberOfGreens = statusesRef.current.filter((s) => s === 1).length;
    if (isGameOver || numberOfGreens >= 25) {
      return;
    }

    showRollButton(false);
    setIsRolling(true);
    waitingForRoll.current = false;
    if (immediate) {
      rollDice();
    } else {
      rollRef.current = setTimeout(() => {
        if (
          gameEngineStateRef.current &&
          gameEngineStateRef.current.gameSession.gameState === "needs-to-roll"
        ) {
          rollDice();
        }
      }, 1500);
    }
  };

  const resetSidebetWin = () => {
    setSideBetWin({
      prize: null,
      type: null,
      label: null,
    });
  };

  const handleSideBetWinAnimation = (result) => {
    const { sideBetEvent } = result.gameSession;
    if (sideBetEvent && sideBetEvent.winOutcome) {
      isPlayingSound && playSideBetWinner();
      setSideBetWin({
        prize: sideBetEvent.winAmount,
        type: sideBetEvent.sideBet.condition,
        label: sideBetEvent.sideBet.label,
      });
    } else {
      resetSidebetWin();
    }
  };

  const disableEndSideAnimations = () => {
    let dice1 = document.getElementById("dice1");
    let dice2 = document.getElementById("dice2");

    if (!dice1 || !dice2) {
      return;
    }

    const sideEnd1 = [].slice
      .call(dice1.classList)
      .find((b) => b.indexOf("side-") != -1);
    const sideEnd2 = [].slice
      .call(dice2.classList)
      .find((b) => b.indexOf("side-") != -1);

    if (sideEnd1) {
      dice1.className = sideEnd1;
    }

    if (sideEnd2) {
      dice2.className = sideEnd2;
    }
  };

  const rollDice = async () => {
    if (startedFreeGame) setStartedFreeGame(false);

    let dice1 = document.getElementById("dice1");
    let dice2 = document.getElementById("dice2");

    let rollResult = null;

    try {
      waitingForRoll.current = true;
      if (rollCntRef.current !== 15) {
        fromBetScreen.current = true;
      }

      setShowSideBetWin(null);

      setSideBetWin({
        prize: null,
        type: null,
        label: null,
      });

      gameEvents.emit("start-roll");
      rollResult = await roll(sideBet ? sideBet.id : undefined);
      controller.handleApiRollDone(
        dataContext,
        rollResult,
        handleSideBetWinAnimation
      );
    } catch (error) {
      setError(error.message);
      return;
    }

    const numbersToRoll = rollResult.gameSession.roll;
    let result1 = numbersToRoll
      ? numbersToRoll[0]
      : Math.floor(Math.random() * 6) + 1;
    let result2 = numbersToRoll
      ? numbersToRoll[1]
      : Math.floor(Math.random() * 6) + 1;

    setTimeout(() => {
      controller.rollDoneCallback(
        {
          ...dataContext,
          onClickRollBtn,
          onClickNumber,
        },
        sounds,
        result1,
        result2,
        rollResult
      );
    }, 250);

    if (!dice1 || !dice2) {
      return;
    }

    dice1.className = `side-${result1}-end`;
    dice2.className = `side-${result2}-end`;
  };

  const getScore = (param_statuses) => {
    const winnedLinesCnt = getWinnedLines(param_statuses).length;
    setWinnedLines(winnedLinesCnt);

    if (streakStrike >= 2) {
      return bet * 7;
    }

    let tmpBonus = 0;
    if (winnedLinesCnt > 10) {
      if (holeTimes === 1) {
        tmpBonus = credits.strike1 * bet;
      } else if (holeTimes === 2) {
        tmpBonus = credits.strike2 * bet;
      }
    } else {
      tmpBonus = credits[winnedLinesCnt] * bet;
    }
    return tmpBonus;
  };

  useEffect(() => {
    if (!filled && skipping) {
      isPlayingSound && playSoundDuplicate();
      let delay = controller.getSkippingTime();

      if (skippingCount === 1) {
        delay = controller.getSkippingTime(true);
      }

      const timer = setTimeout(() => {
        skippingRef.current = false;
        setSkipping(false);

        if (!rollButtonEnabled) {
          rollAll();
        } else {
          showRollButton(true);
        }
      }, delay);
      return () => clearTimeout(timer);
    }
  }, [skipping]);

  useEffect(() => {
    if (freeGameAwarded) {
      controller.onFreeGameAwarded({
        rollButtonEnabled,
        rollAll,
        showRollButton,
      });
    }
  }, [freeGameAwarded]);

  useEffect(() => {
    if (isPlayingSound && rollCntRef.current === 15) {
      isPlayingSound && playSoundDuplicate();
    }
  }, [rollCnt]);

  useEffect(() => {
    if (show777) {
      const timer = setTimeout(() => {
        setShow777(false);
      }, 4500);
      return () => clearTimeout(timer);
    }
  }, [show777]);

  const hasFreeGame = () => {
    if (!gameEngineState.gameSession) return true;
    const condition = gameEngineState.gameSession.gameConfig.prizes.prizes.find(
      (value) => value.label === "sb.single.bonus.game"
    ).condition;
    return (
      gameEngineState.gameSession.strikes <= condition.maxStrikesAllowed &&
      gameEngineState.gameSession.isFreeGameAvailable
    );
  };

  const resetGame = () => {
    waitingForRoll.current = false;
    setShow777(false);

    setShowWinAnimation(false);
    setFreeGameAwarded(false);
    setFreeGameAvailable(hasFreeGame());
    setFilled(false);
    setShowGameOverBanner(false);
    setNewWinGroupKeys([]);
    setShowConfetti(false);
    setIsRolling(false);
    setRolledNum();
    setShowAnimationStrike(false);
    setStartedFreeGame(false);
    setStartedRound(false);
    setShowSideBetWin(null);
    setSideBet(undefined);
    setSkippingCount(0);

    setStreakStrike(0);
    setRollCnt(0);
    setHoleTimes(0);
    setIsAuto(false);
    setStatuses(Array.from({ length: 25 }, () => 0));
    showRollButton(true);
    setLastMoment(moment());
  };

  const handleGameOverTimeout = () => {
    fromBetScreen.current = true;
    setShowLineWinAnimation(LineWinAnimationType.Hide);
    resetGame();
    setFreeCnt((cnt) => {
      const redirect = cnt <= 0;

      if (redirect) {
        history.push("/game/bet?next=true");
      } else {
        setIsGameOver(false);
        setStartedFreeGame(true);
        return cnt - 1;
      }

      return cnt;
    });
  };

  const onGameOverBannerClicked = () => {
    clearTimeout(gameOverTimeoutRef.current);
    handleGameOverTimeout();
  };

  const setDiceFace = () => {
    if (
      gameEngineState.gameSession &&
      gameEngineState.gameSession.roll &&
      gameEngineState.gameSession.roll.length
    ) {
      const [result1, result2] = gameEngineState.gameSession.roll;

      clearInterval(diceFaceInterval.current);
      diceFaceInterval.current = setInterval(() => {
        let dice1 = document.getElementById("dice1");
        let dice2 = document.getElementById("dice2");

        if (dice1 && dice2) {
          clearInterval(diceFaceInterval.current);
          dice1.className = `side-${result1}-end`;
          dice2.className = `side-${result2}-end`;
        }
      }, 100);
    }
  };

  useEffect(() => {
    if (isGameOver) {
      const numberOfGreens = statusesRef.current.filter((s) => s === 1).length;
      isPlayingSound && playGameOver();
      setIsRolling(false);
      setBonus(gameEngineState.gameSession.winnings);

      setBalance(balance + getScore(statuses));
      controller.stopOrganSound(audioRef);

      const winnedLinesCnt = getWinnedLines(statuses).length;
      if (winnedLinesCnt === 3 && !freeCnt && freeGameAvailable) {
        setFreeCnt((cnt) => cnt + 1);
        setShowFreeCelebration(true);
      }

      if (winnedLinesCnt > 3) {
        isPlayingSound && playCountUpWin();
      }

      controller.setLineWin({
        winnings: gameEngineState.gameSession.winnings,
        streakStrike,
        holeTimes,
        bonus,
        statusesRef,
        numberOfGreens,
        setShowLineWinAnimation,
        gameOverTimeoutRef,
        handleGameOverTimeout,
        winnedLinesCnt,
        setShowGameOverBanner,
        freeCnt,
      });

      return () => clearTimeout(gameOverTimeoutRef.current);
    }
  }, [isGameOver]);

  useEffect(() => {
    if (showConfetti) {
      const timer = setTimeout(() => {
        setShowConfetti(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [showConfetti]);

  useEffect(() => {
    if (showFreeCelebration) {
      const timer = setTimeout(() => {
        setShowFreeCelebration(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [showFreeCelebration]);

  useEffect(() => {
    const numberOfGreens = statuses.filter((s) => s === 1).length;

    if (
      !isGameOver &&
      statuses.length > 0 &&
      numberOfGreens === statuses.length
    ) {
      setIsGameOver(true);
    }
  }, [statuses]);
  useEffect(() => {
    controller.onFilled({
      filled,
      isPlayingSound,
      playJackpotSound,
      playSwooshSound,
      setFreeCnt,
      freeCnt,
      setShowFreeCelebration,
      rollCntRef,
      setFreeGameAwarded,
      setFilled,
      onClickNumber,
      getFirstOrange,
      statuses,
      skippingRef,
      setSkipping,
      setSkippingCount,
      skippingCount,
      moveRoundBonusDelay,
      rolledNumRef,
      statusesRef,
      isAuto,
      audioRef,
    });
  }, [filled]);

  useEffect(() => {
    if (streakStrike == 2) {
      controller.suspenseSound({
        isPlayingSound,
        playRollingBallSuspense,
        playSuspense777,
        streakStrike,
        holeTimes,
        audioRef,
      });
      controller.stopOrganSound(audioRef);
      controller.stopThemeAnthem(audioRef);
    } else if (streakStrike === 3) {
      controller.stopSuspenseSound({
        stopSuspense777,
        streakStrike,
      });
      isPlayingSound && playTripleSevenLastStrike();
    } else {
      controller.stopSuspenseSound({
        stopSuspense777,
        streakStrike,
      });
    }
  }, [streakStrike]);

  useEffect(() => {
    if (gameEngineState.gameSession && gameEngineState.gameSession.freeGames) {
      setFreeCnt(gameEngineState.gameSession.freeGames);
    }

    setDiceFace();

    if (gameEngineState.gameSession) {
      setStartedRound(true);
    }

    setStreakStrike(0);
    setStatuses(Array.from({ length: 25 }, () => 0));
    controller.shouldShowRollButtonOnLoad(showRollButton, theme);
  }, [history.location.pathname]);

  useEffect(() => {
    if (activeBtn) {
      setDisplayOuts(false);
    }
  }, [activeBtn]);

  useEffect(() => {
    rollAllRef.current = rollAll;
    const alreadyDisplayed = localStorage.getItem(
      "instrusctionsAlreadyDisplayed"
    );

    if (alreadyDisplayed !== null && alreadyDisplayed === "no") {
      setIsInstruction(true);
      localStorage.setItem("instrusctionsAlreadyDisplayed", "yes");
    }

    setDiceFace();

    if (
      gameEngineState.gameSession &&
      gameEngineState.gameSession.gameState === "game-over-free"
    ) {
      resetGame();
      return;
    }

    if (
      gameEngineState.gameSession &&
      gameEngineState.gameSession.strikes !== undefined
    ) {
      setHoleTimes(gameEngineState.gameSession.strikes);
    }

    controller.shouldTriggerIntroAnimation(
      fromBetScreen,
      gameEvents,
      gameEngineState,
      showRollButton,
      sideBetsEnabled,
      setSideBetsIntro
    );
    controller.sideBetIntro({ gameEvents, setSideBetsEnabled, sideBetsIntro });

    window.addEventListener("click", (evt) => {
      const target = evt.target;
      if (target && target.parentNode && target.parentNode.parentNode) {
        const parent = target.parentNode.parentNode;
        if (
          (parent &&
            parent.className &&
            parent.className.toString().indexOf("auto-pick-toggle") !== -1 &&
            isAutoRef.current) ||
          (parent.className.toString().indexOf("toggle-container") !== -1 &&
            isAutoRef.current)
        ) {
          return;
        }
      }
      setIsAuto(false);
    });
  }, []);

  useEffect(() => {
    if (Object.keys(gameEngineState).length <= 0) {
      history.push("/");
      return;
    }

    if (gameEngineState.gameSession.gameState === "needs-to-pick") {
      showRollButton(false);
    }

    if (
      gameEngineState.gameSession.gameBoard &&
      gameEngineState.gameSession.gameState !== "game-over-free"
    ) {
      setStatuses(mapGameEngineBoard(gameEngineState.gameSession.gameBoard));
    }

    if (
      gameEngineState.gameSession &&
      gameEngineState.gameSession.gameState !== "game-over-free"
    ) {
      const b = hasFreeGame();
      if (
        gameEngineState.gameSession.rollStreakCounter &&
        rollCntRef.current < 15 &&
        b
      ) {
        setRollCnt(gameEngineState.gameSession.rollStreakCounter);
      }
      if (freeGameAvailable !== b) setFreeGameAvailable(b);
    }
  }, [gameEngineState]);

  useEffect(() => {
    window.scaleScreen();
  });

  const handleFreeStarPane = (lang) => {
    switch (lang) {
      case "pt":
      case "pt-BR":
      case "pt-br":
        return FreeStarPanePt;
      case "es":
        return FreeStarPaneEs;
      default:
        return FreeStarPane;
    }
  };

  const handleTryAgain = (lang) => {
    switch (lang) {
      case "pt":
      case "pt-BR":
      case "pt-br":
        return "message-pt";
      case "es":
        return "message-es";
      default:
        return "";
    }
  };

  const getRolledClass = (_i) => {
    if (rolledNum === numbersTemp[_i] && skipping) {
      return "rolled-num";
    }

    return "";
  };

  return (
    <styleModule.RollingContainer
      rollCnt={rollCnt}
      freeGameAvailable={freeGameAvailable || freeCnt}
    >
      {showAnimationStrike && (
        <div id="animation_container">
          <canvas id="canvas"></canvas>
          <div id="dom_overlay_container"></div>
        </div>
      )}
      <Box className="rolling-wrapper">
        <ThemeVisibility themes={["dice", "bigwheel"]}>
          <div className="bars-container">
            <RollBonusMeter
              filled={filled}
              rollCount={freeGameAvailable ? rollCnt : 0}
            />
            <ThemeVisibility themes={["dice"]}>
              <SingleText
                className="roll-bonus"
                color="#e1b353"
                size={18}
                weight="bold"
              >
                {t("common.rollBonus")}
              </SingleText>
            </ThemeVisibility>
          </div>
        </ThemeVisibility>
        <ThemeVisibility themes={["cricket", "baseball"]}>
          <CricketScoreBoard />
        </ThemeVisibility>
        <div className="pane-container">
          <ThemeVisibility themes={["bigwheel"]}>
            <div className="barber-pole">
              <div className="stripe-container">
                <div className="stripe"></div>
              </div>
            </div>
            <div className="barber-pole right-side">
              <div className="stripe-container">
                <div className="stripe"></div>
              </div>
            </div>
          </ThemeVisibility>
          {(skipping || filled) && (
            <Fragment>
              <div className="stop-loader"></div>
              {rollCntRef.current === 15 && !freeGameAwarded ? (
                <div className="covered-mask bonus">{t("common.bonus")}!</div>
              ) : (
                <>
                  <ThemeVisibility themes={["dice", "bigwheel"]}>
                    <div className="covered-mask">{t("common.covered")}!</div>
                  </ThemeVisibility>
                  <ThemeVisibility themes={["cricket"]}>
                    <div className="noball-container">
                      <div className="from-left">{t("common.cricket.no")}</div>
                      <div className="from-right">{t("common.ball")}</div>
                    </div>
                  </ThemeVisibility>
                  <ThemeVisibility themes={["baseball"]}>
                    <div className="noball-container">
                      <div className="foul-ball-text">
                        {t("gameAward.baseball.foulBall")}
                      </div>
                    </div>
                  </ThemeVisibility>
                </>
              )}
            </Fragment>
          )}
          {startedFreeGame && (
            <div className="free-pane">
              <SingleAntImage src={handleFreeStarPane(i18n.language)} />
            </div>
          )}
          {showGameOverBanner && (
            <div onClick={onGameOverBannerClicked} className="game-over">
              <div className="game-over-center">
                <img
                  alt="img-gameover"
                  className="game-over"
                  src={MessageContainer}
                />
                <ThemeVisibility themes={["cricket"]}>
                  <div className="title">{t("common.cricket.3rdStrike")}</div>
                </ThemeVisibility>
                <ThemeVisibility themes={["baseball", "bigwheel"]}>
                  <div className="title">{gameOverMessage}</div>
                </ThemeVisibility>
                <div className={`message ${handleTryAgain(i18n.language)}`}>
                  {t("common.tryAgain")}
                </div>
              </div>
            </div>
          )}
          <div className="pane">
            <ThemeVisibility themes={["cricket"]}>
              <styleModule.MetalBeam />
            </ThemeVisibility>
            <Row gutter={[sizes.gutter, sizes.gutter]}>
              {statuses.map((_status, _i) => (
                <Col
                  key={`number-col-${_i}`}
                  className={`gutter-row status-${_status} ${getRolledClass(
                    _i
                  )} number-col-${_i}`}
                  style={{
                    zIndex:
                      (rolledNum === numbersTemp[_i] &&
                        statuses.filter(
                          (_item, _j) =>
                            numbersTemp[_j] == rolledNum &&
                            (_item == statusColors.white ||
                              _item == statusColors.orange)
                        ).length === 0 &&
                        skipping) ||
                      (totalPicked >= 22 && _status === statusColors.white)
                        ? 1
                        : _i === 12
                        ? 2
                        : 0,
                  }}
                >
                  <Fade delay={50 * _i} triggerOnce>
                    <Number
                      key={`number-component-${_i}`}
                      totalPicked={totalPicked}
                      order={_i + 1}
                      status={_status}
                      countRemained={getCountSuggestions(_i + 1)}
                      newWinGroupKeys={newWinGroupKeys}
                      rolledNum={rolledNum}
                      onClick={() => {
                        setIsAuto(false);
                        onClickNumber(_i + 1, "0");
                      }}
                    />
                    <div
                      id={_i + 1 + "anim"}
                      className="particle-hit-anim"
                    ></div>
                  </Fade>
                </Col>
              ))}
            </Row>
          </div>
        </div>
        <Strikes />
        <Fragment>
          <NumberReveal />
          <PlayballIntro />
          <ThemeVisibility themes={["dice"]}>
            <styleModule.SpeechBubble
              className={showSideBetPopup ? "show" : ""}
            >
              <div className={i18n.language === "en" ? "enfontsize" : ""}>
                <span>
                  <Trans t={t}>common.trySideBets</Trans>
                </span>
              </div>
            </styleModule.SpeechBubble>
          </ThemeVisibility>
          <styleModule.BottomContents>
            {activeBtn && <LetsRoll onClickRollBtn={onClickRollBtn} />}
            <styleModule.BottomToggles className="toggle-container">
              <Toggle
                title={<Trans t={t}>common.sideBets</Trans>}
                state={rollButtonEnabled}
                onChange={handleRollToggle}
                className="auto-pick-toggle"
              />
              <Toggle
                title={<Trans t={t}>common.autoPick</Trans>}
                reference={autoRef}
                state={isAuto}
                onChange={handleAutopickChanged}
                className="auto-pick-toggle"
              />
              <Toggle
                title={<Trans t={t}>common.turboMode</Trans>}
                state={isTurbo}
                onChange={handleTurboToggle}
                className="auto-pick-toggle"
              />
            </styleModule.BottomToggles>
          </styleModule.BottomContents>
        </Fragment>
      </Box>
      {!isGameOver && streakStrike >= 2 && streakStrike === holeTimes && (
        <TripleSevenAnimation win={showWinAnimation && holeTimes >= 3} />
      )}
      <ThemeVisibility themes={["cricket"]}>
        {displayOuts && (
          <>
            <div className="out-container">
              <span>{t("scoreboard.cricket.outs")}</span>
            </div>
            <div className="out-person"></div>
          </>
        )}
      </ThemeVisibility>
      <ThemeVisibility themes={["baseball"]}>
        {displayOuts && <div className="out-person"></div>}
      </ThemeVisibility>
    </styleModule.RollingContainer>
  );
}

export default Theme(Rolling, "containers/Rolling");
