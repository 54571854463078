import Theme from 'containers/Theme';
import React, { useEffect, useContext, useRef, useState } from 'react';
import { DataContext } from "contexts/DataContextContainer";
import ApngComponent from 'react-apng';
import { Particle } from 'assets/images';
import { currencyFormatter } from 'utils/currency-formatter';

const PrizeExplode = ({
	styleModule,
	prize,
	type,
	onFinish,
}) => {
	const {
		currencyFormat,
	} = useContext(DataContext);
	const { Container, Prize } = styleModule;
	const dust = useRef();
	const [show, toggle] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			toggle(true);
		}, 100);

		setTimeout(() => {
			toggle(false);
		}, 3500);

		setTimeout(() => {
			if (dust && dust.current) {
				dust.current.one();
			}

			setTimeout(() => {
				onFinish && onFinish();
			}, 1000);
		}, 250);
	}, []);

	return <Container type={type}>
		{show && <ApngComponent className="dust" ref={dust} src={Particle} rate={1} />}
		<Prize>
			<span className="plus">+</span> <span style={{
				fontWeight: 700,
				fontSize: '30pt',
				color: "#ffa55a",
				fontFamily: "Roboto",
				textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
			}} className='prize'>{currencyFormatter(prize, currencyFormat)}</span>
		</Prize>
	</Container>;
};

export default Theme(PrizeExplode, 'components/PrizeExplode');