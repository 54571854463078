import React, { useContext } from "react";
import { DataContext } from "contexts/DataContextContainer";
import Slider from "rc-slider";
import { GreenPlusContainer, Handler, GreenMinusContainer } from "assets/images";
import RollBtn from "components/RollBtn";
import SingleText from "components/SingleText";
import Theme from 'containers/Theme';
import 'rc-slider/assets/index.css';
import { currencyFormatter } from "utils/currency-formatter";
import { useTranslation } from "react-i18next";

const SingleSlider = ({
  defaultIdx = 0,
  numberOfSteps,
  minDenomValue,
  maxDenomValue,
  onSlide = () => { },
  onClickPlus = () => { },
  onClickMinus = () => { },
  styleModule,
}) => {
  const { t } = useTranslation();
  const {
    currencyFormat,
  } = useContext(DataContext);
  return (
    <styleModule.SingleSliderContainer className="bet-slider">
      <div className="btn-min">
        <styleModule.MinMaxLabel>{t("common.min")}</styleModule.MinMaxLabel>
        <RollBtn
          bg={GreenMinusContainer}
          width="45px"
          height="45px"
          onPress={onClickMinus}
        />
        <SingleText weight={800}>{currencyFormatter(minDenomValue, currencyFormat)}</SingleText>
      </div>
      <div className="slider-wrapper">
        <Slider
          defaultValue={defaultIdx}
          value={defaultIdx}
          min={0}
          max={numberOfSteps - 1}
          railStyle={{ backgroundImage: 'linear-gradient(0deg, rgb(38, 10, 73) 0%, rgb(38, 10, 73) 100%)', height: 13 }}
          trackStyle={{
            backgroundImage: 'linear-gradient(0deg, rgb(78, 183, 12) 0%, rgb(18, 120, 0) 51%, rgb(56, 150, 8) 51%, rgb(180, 188, 168) 100%)',
            height: 13,
            borderRadius: 0,
          }}
          handleStyle={{
            height: 28,
            width: 38,
            marginLeft: 16,
            marginTop: -6,
            backgroundImage: `url(${Handler})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '39px',
            backgroundColor: 'transparent',
            border: 0,
            borderRadius: 0,
          }}
          onChange={onSlide}
        />
      </div>
      <div className="max-btn-container">
        <div className="btn-max">
          <styleModule.MinMaxLabel>{t("common.max")}</styleModule.MinMaxLabel>
          <RollBtn
            bg={GreenPlusContainer}
            width="45px"
            height="45px"
            onPress={onClickPlus}
          />
          <div className="max-value">
            <SingleText weight={800}>{currencyFormatter(maxDenomValue, currencyFormat)}</SingleText>
          </div>
        </div>
      </div>
    </styleModule.SingleSliderContainer>
  );
}

SingleSlider.propTypes = {};

export default Theme(SingleSlider, 'components/SingleSlider');