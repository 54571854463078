/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import query from 'query-string';
import Box from "components/Box";
import SingleText from "components/SingleText";
import React, { Fragment, useContext, useEffect, useState } from "react";
import SingleButton from "components/SingleButton";
import { DataContext } from "contexts/DataContextContainer";
import SingleSlider from "components/SingleSlider";
import OptionsHud from "components/OptionsHud";
import { useHistory } from "react-router-dom";
import useSound from "use-sound";
import { SoundBetPlus, SoundClickButton, SoundBetMinus, CricketBallLineup } from "assets/audio";
import { soundSetting } from "service";
import Theme from 'containers/Theme';
import { wager } from 'api';
import { getMultiplier } from "utils";
import { currencyFormatter } from "utils/currency-formatter";
import ThemeVisibility from "components/ThemeVisibility";
import {
  BetButtonContainer,
  BetMore,
  BetMorePt,
  BetMoreEs,
  FullCardEs,
  FullCard,
  FullCardPt
 } from "assets/images";
 import { useTranslation } from "react-i18next";

import './style.css';

function Bet(props) {
  const { t, i18n } = useTranslation();
  const { styleModule } = props;
  const history = useHistory();
  const [playSoundClickButton] = useSound(SoundClickButton, soundSetting);
  const [playSoundBetPlus] = useSound(SoundBetPlus, soundSetting);
  const [playSoundBetMinus] = useSound(SoundBetMinus, soundSetting);
  const {
    bet,
    setBet,
    setBonus,
    isPlayingSound,
    setShowPayTable,
    setShowGuide,
    setIsGameOver,
    gameEngineState,
    setGameEngineState,
    setError,
    currencyFormat,
    waitingForRoll,
    fromBetScreen,
    audioRef,
    setMenuOpen,
  } = useContext(DataContext);
  const [playBallIntroSound] = useSound(CricketBallLineup, {
    ...soundSetting,
    playbackRate: 1.5,
    soundEnabled: isPlayingSound,
  });
  const [isBetting, setIsBetting] = useState(false);
  const [denomIdx, setDenomIdx] = useState(0);
  const [minDenomValue, setMinDenomValue] = useState(0);
  const [maxDenomValue, setMaxDenomValue] = useState(0);

  const next = query.parse(history.location.search).next;

  const onClickMinus = () => {
    const wagerDenominations = gameEngineState?.gameSession?.gameConfig?.wagerDenoms;
    const newDenomIdx = denomIdx - 1;

    if (wagerDenominations && newDenomIdx >= 0) {
      isPlayingSound && playSoundBetMinus();
      setBet(wagerDenominations[newDenomIdx].amount);
      setDenomIdx(newDenomIdx);
    }
  };

  const onClickPlus = () => {
    const wagerDenominations = gameEngineState?.gameSession?.gameConfig?.wagerDenoms;
    const newDenomIdx = parseInt(denomIdx) + 1;
    if (wagerDenominations && newDenomIdx <= wagerDenominations.length - 1) {
      isPlayingSound && playSoundBetPlus();
      setBet(wagerDenominations[newDenomIdx].amount);
      setDenomIdx(newDenomIdx);
    }
  };

  const onSlide = (idx) => {
    const wagerDenominations = gameEngineState?.gameSession?.gameConfig?.wagerDenoms;
    if (wagerDenominations) {
      setBet(wagerDenominations[idx].amount);
      setDenomIdx(idx);
    }
  };

  const onClickBet = async () => {
    window.ga_log_user_click('bet');
    setMenuOpen(false);
    isPlayingSound && playSoundClickButton();

    if (isBetting) {
      return;
    }

    try {
      setIsBetting(true);
      const state = await wager(bet);
      setGameEngineState(state);
      history.push("/game/rolling");
      audioRef.current = {
        ...audioRef.current,
        playBallIntroSound
      };
      setBonus(0);
      localStorage.setItem('previousBet', denomIdx);
    } catch (error) {
      setError(error.message);
      setIsBetting(false);
    }
  };

  const handleShowGuide = () => {
    window.ga_log_user_click('guide');
    isPlayingSound && playSoundClickButton();
    setShowGuide(true);
    setMenuOpen(false);
  }

  const handleShowPaytable = () => {
    window.ga_log_user_click('paytable');
    isPlayingSound && playSoundClickButton();
    setShowPayTable(true);
    setMenuOpen(false);
  }

  useEffect(() => {
    fromBetScreen.current = true;
    if (Object.keys(gameEngineState).length <= 0) {
      history.push('/');
      return;
    }
    waitingForRoll.current = false;

    setIsGameOver(false);
    if (!next) {
      setBonus(0);
    }

    const wagerDenominations = gameEngineState?.gameSession?.gameConfig?.wagerDenoms;
    if (wagerDenominations) {
      for (let i = 0; i < wagerDenominations.length; i++) {
        if (wagerDenominations[i].default) {
          const defaultIdx = parseInt(localStorage.getItem('previousBet')) <= wagerDenominations.length ? localStorage.getItem('previousBet') : i;
          if (wagerDenominations[defaultIdx] && wagerDenominations[defaultIdx].amount !== undefined) {
            setBet(wagerDenominations[defaultIdx].amount);
          }
          setDenomIdx(defaultIdx)
          break;
        }
      }

      setMinDenomValue(wagerDenominations[0].amount);
      setMaxDenomValue(wagerDenominations[wagerDenominations.length - 1].amount);
    }
  }, []);

  useEffect(() => {
    window.scaleScreen();
  });

  const handleBetMore = (lang) => {
    switch (lang) {
      case 'pt':
      case 'pt-BR':
      case 'pt-br':
        return BetMorePt
      case 'es':
        return BetMoreEs
      default:
        return BetMore
    }
  }

  const handleFullCard = (lang) => {
    switch (lang) {
      case 'pt':
      case 'pt-BR':
      case 'pt-br':
        return FullCardPt
      case 'es':
        return FullCardEs
      default:
        return FullCard
    }
  }

  const handleNoStrike = (lang) => {
    switch (lang) {
      case 'pt':
      case 'pt-BR':
      case 'pt-br':
        return 'no-strike-pt'
      default:
        return 'no-strike'
    }
  }

  const handleOneStrike = (lang) => {
    switch (lang) {
      case 'pt':
      case 'pt-BR':
      case 'pt-br':
        return 'one-strike-pt'
      default:
        return 'one-strike'
    }
  }

  const handleTwoStrike = (lang) => {
    switch (lang) {
      case 'pt':
      case 'pt-BR':
      case 'pt-br':
        return 'two-strike-pt'
      default:
        return 'two-strike'
    }
  }

  return (
    <styleModule.BetContainer>
      <styleModule.Overlay />
      <Box className="rolling-wrapper">
        <div className="bet-pane-container">
          <ThemeVisibility themes={['dice','cricket', 'baseball', 'bigwheel']}>
            <img className="bet-more-image" src={handleBetMore(i18n.language)} alt="" />
          </ThemeVisibility>
          <img className="two-strike-full-card" src={handleFullCard(i18n.language)} alt="" />
          <ThemeVisibility themes={['cricket', 'baseball', 'bigwheel', 'dice']}>
            <ThemeVisibility themes={['cricket', 'baseball' , 'dice']}>
              <styleModule.TwoOutsLabel className={`two-outs-${i18n.language}`}>{ t("common.baseball.twoOuts") }</styleModule.TwoOutsLabel>
            </ThemeVisibility>
            <div className={`two-strike-default sm-strike-container`}>
              <ThemeVisibility themes={['bigwheel']}>
                <styleModule.TwoOutsLabel>{t("common.bigwheel.twoOuts")}</styleModule.TwoOutsLabel>
              </ThemeVisibility>
              <span>{currencyFormatter(bet * getMultiplier(gameEngineState, 'sb.single.full-2-strike'), currencyFormat)}</span>
            </div>
          </ThemeVisibility>
          <img className="one-strike-full-card" src={handleFullCard(i18n.language)} alt="" />
          <ThemeVisibility themes={['cricket', 'baseball', 'bigwheel', 'dice']}>
            <ThemeVisibility themes={['cricket', 'baseball', 'dice']}>
              <styleModule.OneOutLabel className={`one-out-${i18n.language}`}>{ t("common.baseball.oneOut") }</styleModule.OneOutLabel>
            </ThemeVisibility>
            <div className={`one-strike-default sm-strike-container`}>
            <ThemeVisibility themes={['bigwheel']}>
              <styleModule.OneOutLabel>{t("common.bigwheel.oneOut")}</styleModule.OneOutLabel>
            </ThemeVisibility>
              <span>{currencyFormatter(bet * getMultiplier(gameEngineState, 'sb.single.full-1-strike'), currencyFormat)}</span>
            </div>
          </ThemeVisibility>
          <img className="no-strike-full-card" src={handleFullCard(i18n.language)} alt="" />
          <ThemeVisibility themes={['cricket', 'baseball', 'bigwheel', 'dice']}>
            <ThemeVisibility themes={['cricket', 'baseball', 'dice']}>
              <styleModule.NoOutsLabel className={`zero-outs-bot-${i18n.language}`}>{ t("common.baseball.noOuts") }</styleModule.NoOutsLabel>
            </ThemeVisibility>
            <ThemeVisibility themes={['bigwheel']}>
              <styleModule.NoOutsLabel>{t("common.bigwheel.noOuts")}</styleModule.NoOutsLabel>
            </ThemeVisibility>
            <div className={`no-strike-default bg-strike-container`}>
              <span>{currencyFormatter(bet * getMultiplier(gameEngineState, 'sb.single.full-0-strike'), currencyFormat)}</span>
            </div>
          </ThemeVisibility>
        </div>
        <styleModule.BetSlideContainer
          className="bet-slide-container"
          isBetting={isBetting}
        >
          <Fragment>
            <div className="slide-bar-container">
              <SingleSlider
                numberOfSteps={gameEngineState?.gameSession?.gameConfig?.wagerDenoms?.length || 0}
                defaultIdx={denomIdx}
                minDenomValue={minDenomValue}
                maxDenomValue={maxDenomValue}
                onSlide={onSlide}
                onClickMinus={onClickMinus}
                onClickPlus={onClickPlus}
              />
            </div>
            <styleModule.BottomContents>
              <styleModule.ExtraButton onClick={handleShowGuide}>?</styleModule.ExtraButton>
              <SingleButton
                className="bet-btn"
                renderTitle={() => (
                  <SingleText
                    size={18}
                    color="#ffffff"
                    weight={700}
                    filter="drop-shadow(rgba(0, 74, 29, 0.6) 2px 2.928px 0px)"
                  >
                    <span>{t("common.bet")}</span> {currencyFormatter(bet, currencyFormat).length >= 8 && (<br/>)} <span>{currencyFormatter(bet, currencyFormat)}</span>
                  </SingleText>
                )}
                width="30%"
                height="50px"
                bg={BetButtonContainer}
                onPress={() => {
                  onClickBet();
                }}
              />
              <styleModule.ExtraButton onClick={handleShowPaytable}>$</styleModule.ExtraButton>
            </styleModule.BottomContents>
          </Fragment>
        </styleModule.BetSlideContainer>
      </Box>
      <OptionsHud className="in-bet-screen" />
    </styleModule.BetContainer>
  );
}

export default Theme(Bet, 'containers/Bet');