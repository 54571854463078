import React, { useContext, useEffect, useRef } from 'react';
import { DataContext } from 'contexts/DataContextContainer';
import Theme from 'containers/Theme';
import ApngComponent from 'react-apng';
import {
	BaseballBallIntro,
	BaseballBallOutro,
	BaseballLauncher,
} from 'assets/images';

const BaseballPlayballIntroOutro = (props) => {
  const { styleModule, controller } = props;
	const { showRollButton, gameEngineState, gameEvents, audioRef, setSideBetsEnabled, sideBetsIntro, isPlayingSound, isTurbo } = useContext(DataContext);
	const { Wrapper } = styleModule;
	const ballLauncherRef = useRef();
	const playIntroRef = useRef();
	const playOutroRef = useRef();
	const wrapperRef = useRef();
	const waitForPlayer = useRef(false);
  const fromIntro = useRef(false);
	const turboRef = useRef(false);

	const handleComplete = () => {
		if (sideBetsIntro) {
			setSideBetsEnabled(true);
		}
		if (gameEngineState.gameSession.gameState === 'needs-to-roll') {
			showRollButton(true);
		}
	};

	const handleOutroAnimationCompleted = (f) => {
    if (f >= playOutroRef.current.apng.frames.length - 1) {
			playOutroRef.current.refs.canvasBox.style.opacity = 0;
			handleComplete();
		}
	}

	const handleIntroAnimationCompleted = (f) => {
    if (f >= playIntroRef.current.apng.frames.length - 1) {
			gameEvents.emit('baseball-animate-before-pitch');
			playIntroRef.current.refs.canvasBox.style.opacity = 0;
			playOutroRef.current.refs.canvasBox.style.opacity = 1;
			playOutroRef.current.player.on('frame', handleOutroAnimationCompleted);
			playOutroRef.current.one();
			ballLauncherRef.current.player.on('frame', handlePitcherAnimationCompleted(null));
			ballLauncherRef.current.one();
		}
	}

	const handleBeginAnimation = () => {
		fromIntro.current = true;
		waitForPlayer.current = setInterval(() => {
			if (playIntroRef.current && playIntroRef.current.player) {
				clearInterval(waitForPlayer.current);
				wrapperRef.current.style.opacity = 1;
				playIntroRef.current.refs.canvasBox.style.opacity = 1;
				playIntroRef.current.player.on('frame', handleIntroAnimationCompleted);
				playIntroRef.current.one();
				isPlayingSound && controller.playThemeAnthem(audioRef);
			}
		}, 50);
	}

	const handlePitcherAnimationCompleted = (number) => (f) => {
    if (f === 28) {
			ballLauncherRef.current.pause();

			if (!fromIntro.current && number) {
				gameEvents.emit('baseball-number-reveal-start', number);
			}

			return;
		}

    if (fromIntro.current && f === ballLauncherRef.current.apng.frames.length - 5) {
			if (number) {
				gameEvents.emit('baseball-number-reveal-start', number);
			}
		}
	}

	const handleAnimatePitcher = () => {
		if (fromIntro.current) {
			fromIntro.current = false;
		}

		wrapperRef.current.style.opacity = 1;
		ballLauncherRef.current.refs.canvasBox.style.opacity = 1;

		ballLauncherRef.current.player._events.frame = undefined;
		if(!turboRef.current) ballLauncherRef.current.continue();
	}

	const handleRestPitcher = (number) => {
		wrapperRef.current.style.opacity = 1;
		ballLauncherRef.current.refs.canvasBox.style.opacity = 1;

		ballLauncherRef.current.player._events.frame = undefined;
		ballLauncherRef.current.player.on('frame', handlePitcherAnimationCompleted(number));

		if (fromIntro.current || turboRef.current) {
			gameEvents.emit('baseball-number-reveal-start', number);
		} else {
			ballLauncherRef.current.one();
		}
	}

	useEffect(() => {
		turboRef.current = isTurbo;
	}, [isTurbo]);

	useEffect(() => {
		gameEvents.on('baseball-animate-ball-intro', handleBeginAnimation);
		gameEvents.on('roll-api-done', handleRestPitcher);
		gameEvents.on('pitch-animation-begin', handleAnimatePitcher);

		return () => {
			gameEvents.off('baseball-animate-ball-intro', handleBeginAnimation);
			gameEvents.off('roll-api-done', handleRestPitcher);
			gameEvents.off('pitch-animation-begin', handleAnimatePitcher);

			if (playIntroRef.current) {
				playIntroRef.current.player.off('frame', handleIntroAnimationCompleted);
			}

			if (playOutroRef.current) {
				playOutroRef.current.player.off('frame', handleOutroAnimationCompleted);
			}

			if (ballLauncherRef.current) {
				ballLauncherRef.current.player.off('frame', handlePitcherAnimationCompleted);
			}
		};
	}, [])

	return (
		<Wrapper ref={wrapperRef} className="cricket-playball-outro-intro">
			<ApngComponent className="playball-outro" ref={playOutroRef} rate={1} src={BaseballBallOutro} />
			<ApngComponent className="playball-intro" ref={playIntroRef} rate={1} src={BaseballBallIntro} />
			<ApngComponent className="ball-launcher" ref={ballLauncherRef} rate={1} src={BaseballLauncher} />
		</Wrapper>
	)
}

export default Theme(BaseballPlayballIntroOutro, 'components/BaseballPlayballIntroOutro');
