import React, { useContext, useEffect, useState } from 'react';
import Theme from 'containers/Theme';
import ErrorModal from 'components/ErrorModal';
import { getGameState } from 'api';
import query from 'query-string';
import { useHistory } from 'react-router';
import { DataContext } from 'contexts/DataContextContainer';
import { ThemeContext } from "contexts/ThemeContext";
import { preloadImage } from "assets/images/preload";
import { useTranslation } from "react-i18next";

const rollingStates = [
	'needs-to-roll',
	'needs-to-pick',
	'game-over-free',
];

const wagerStates = [
	'needs-to-wager',
	'game-over',
];

const Landing = ({ styleModule }) => {
  const { t, i18n } = useTranslation();
  const { LoadingText } = styleModule;
	const {
		setStreakStrike,
		setFreeCnt,
		setRollCnt,
		setBet,
		setGameEngineState,
		waitingForRoll,
		gameEngineState
	} = useContext(DataContext);
  const theme = useContext(ThemeContext);

	const { Container } = styleModule;
	const history = useHistory();
	const { sessionId, game } = query.parse(window.location.search);
	const [error, setError] = useState('');

	useEffect(() => {
		if (sessionId !== undefined && game !== undefined) {
			sessionStorage.setItem('x-session-id', sessionId);
			sessionStorage.setItem('gameId', game);
		}
	})

	useEffect(() => {
		if (!gameEngineState.gameSession) {
			return;
		}

		if (gameEngineState.gameSession && gameEngineState.gameSession.gameState !== 'game-over-free') {
			const condition = gameEngineState.gameSession.gameConfig.prizes.prizes.find(value => value.label === 'sb.single.bonus.game').condition;
			if(gameEngineState.gameSession.isFreeGameAvailable && gameEngineState.gameSession.strikes <= condition.maxStrikesAllowed){
				setRollCnt(gameEngineState.gameSession.rollStreakCounter);
			}
			
			if (gameEngineState.gameSession.strikeStreak !== undefined) {
				setStreakStrike(gameEngineState.gameSession.strikeStreak);
			}

			if (gameEngineState.gameSession.freeGames) {
				setFreeCnt(gameEngineState.gameSession.freeGames);
			}
		}

		if (rollingStates.indexOf(gameEngineState.gameSession.gameState) !== -1) {
			setBet(gameEngineState.gameSession.wagered);
      history.push('/game/rolling');
		}
		if (wagerStates.indexOf(gameEngineState.gameSession.gameState) !== -1) {
			history.push('/game/bet');
		}
	}, [gameEngineState])

	useEffect(() => {
		waitingForRoll.current = false;
		const getGameConfig = async () => {
			try {
				await preloadImage(theme, i18n.language);
				const state = await getGameState();

				setGameEngineState(state);
			} catch (err) {
				setError(err.message);
			}
		}
		const unexpectedError = t('error.unexpectedError');
		if (!sessionId || !game) {
			setError(unexpectedError);
		} else {
			getGameConfig();
		}
	}, []);

	return <Container>
	<ErrorModal message={error} />
	<LoadingText>{ t('common.loading') }...</LoadingText>
	</Container>
}

export default Theme(Landing, 'containers/Landing');