import React from 'react';
import Theme from 'containers/Theme';
import ThemeVisibility from 'components/ThemeVisibility';
import DiceLetsRoll from './dice';
import CricketLetsRoll from './cricket';
import BaseballRoll from './baseball';
import BigWheelRoll from './bigwheel';

const LetsRoll = ({ onClickRollBtn }) => {
	return (
		<>
			<ThemeVisibility themes={['dice']}>
				<DiceLetsRoll onClickRollBtn={onClickRollBtn} />
			</ThemeVisibility>
			<ThemeVisibility themes={['cricket']}>
				<CricketLetsRoll onClickRollBtn={onClickRollBtn} />
			</ThemeVisibility>
			<ThemeVisibility themes={['baseball']}>
				<BaseballRoll onClickRollBtn={onClickRollBtn} />
			</ThemeVisibility>
			<ThemeVisibility themes={['bigwheel']}>
				<BigWheelRoll onClickRollBtn={onClickRollBtn} />
			</ThemeVisibility>
		</>
	)
}

export default Theme(LetsRoll, 'components/LetsRoll');