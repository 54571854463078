import React from 'react';
import Toggle from "components/Toggle";
import Theme from 'containers/Theme';

const OptionsHud = (props) => {
  const { styleModule } = props;

  return (
    <styleModule.Wrapper>
      <Toggle />
    </styleModule.Wrapper>
  )
}

export default Theme(OptionsHud, 'components/OptionsHud');