import React, { useEffect, useRef, useContext, useCallback } from 'react';
import { DataContext } from "contexts/DataContextContainer";
import Theme from 'containers/Theme';
import ApngComponent from 'react-apng';
import { currencyFormatter } from 'utils/currency-formatter';
import CountUp from 'react-countup';
import {
	TripleBonusRay,
	Trophy,
	JackPotAnimated,
	AnimatedBarWinNoText,
} from 'assets/images';
import { LineWinAnimationType } from 'service';
import { Trans, useTranslation } from "react-i18next";

const LineWinAnimation = ({ type, styleModule }) => {
	const { t } = useTranslation();
	const raysRef = useRef();
	const {
		bonus, currencyFormat,
	} = useContext(DataContext);

	const formatValueCallback = useCallback((val) => {
    return currencyFormatter(val, currencyFormat);
	})

	useEffect(() => {
		if (raysRef && raysRef.current) {
			raysRef.current.play();
		}
	}, []);

	useEffect(() => {
		console.log({ myLineWin: LineWinAnimationType, type })
	}, [LineWinAnimationType])


	return <styleModule.Container className="line-win-animation">
		<ApngComponent className="rays" ref={raysRef} src={TripleBonusRay} />
		<img className="trophy" src={Trophy} alt="" />
		{type === LineWinAnimationType.FullCard ? (
			<styleModule.AnimatedPrizeWrapper className="prize-wrapper">
				<CountUp formattingFn={formatValueCallback} end={bonus} duration={5} decimals={2} delay={0}>
					{({ countUpRef }) => (
						<span
						ref={countUpRef}
						style={{
						color: 'white',
						fontWeight: 700,
						fontSize: 20,
						fontFamily: 'Roboto',
						}}
						/>
					)}
				</CountUp>
				<ApngComponent autoPlay={true} src={AnimatedBarWinNoText} />
				<styleModule.WinBarTextWrapper>
					{t('common.jackpot')}
				</styleModule.WinBarTextWrapper>
			</styleModule.AnimatedPrizeWrapper>
		) : null}
		{type === LineWinAnimationType.SixToTen ? (
			<styleModule.AnimatedPrizeWrapper className="prize-wrapper">
				<CountUp formattingFn={formatValueCallback} end={bonus} duration={5} decimals={2} delay={0}>
				{({ countUpRef }) => (
					<span
					ref={countUpRef}
					style={{
					color: 'white',
					fontWeight: 700,
					fontSize: 20,
					fontFamily: 'Roboto',
					}}
					/>
				)}
				</CountUp>
				<ApngComponent autoPlay={true} src={AnimatedBarWinNoText} />
				<styleModule.WinBarTextWrapper>
				{t('common.wins')}
				</styleModule.WinBarTextWrapper>
			</styleModule.AnimatedPrizeWrapper>
		) : null}
	</styleModule.Container>;
}

export default Theme(LineWinAnimation, 'components/LineWinAnimation');