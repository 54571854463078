import React, { useEffect, useRef, useState } from "react";
import {
  useThemController,
  useThemeStyles,
  useThemeSounds,
} from "service/theme";

const Theme = (Component, namespace) => {
  const Render = (props) => {
    const [controller, setController] = useState();
    const [styleModule, setStyleModule] = useState();
    const [themeSounds, setThemeSounds] = useState();
    const mounted = useRef();

    const hook = (setter) => {
      return (val) => {
        if (mounted.current) {
          setter(val);
        }
      }
    }

    useThemController(hook(setController));
    useThemeStyles(hook(setStyleModule), namespace);
    useThemeSounds(hook(setThemeSounds), namespace);

    useEffect(() => {
      mounted.current = true;
      return () => {
        mounted.current = false;
      }
    }, []);

    if(!styleModule || !themeSounds || !controller) {
      return null;
    }

    return (
      <Component
        {...props}
        controller={controller}
        styleModule={styleModule}
        themeSounds={themeSounds}
      />
    )
  }

  return Render;
}

export default Theme;