import Theme from 'containers/Theme';
import React from 'react';
import { BigWheelSpinBtnOthers } from 'assets/images';
import { useTranslation } from "react-i18next";

const BigWheelRoll = ({ styleModule, onClickRollBtn }) => {
	const { Wrapper } = styleModule;
	const { t } = useTranslation();

	return (
		<Wrapper onClick={onClickRollBtn}>
			<img src={BigWheelSpinBtnOthers} alt=""/>
			<div className="spin-tex-container">
				<p className="spin-text">{t('common.bigwheel.spin')}</p>
			</div>
		</Wrapper>
	)
}

export default Theme(BigWheelRoll, 'components/LetsRoll');