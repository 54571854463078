import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Theme from 'containers/Theme';
import ThemeVisibility from 'components/ThemeVisibility';
import { DataContext } from 'contexts/DataContextContainer';
import { currencyFormatter } from 'utils/currency-formatter';
import { getMultiplier, get777Multiplier } from 'utils';

const CricketScoreBoard = ({ styleModule }) => {
  const { t, i18n } = useTranslation();

  const {
    Braces,
    MetalBeam,
    Contents,
    Hit,
    HitStreakContainer,
    GreyLabel,
    GreyLabelContents,
    PrizeMoneyContainer,
    Blanket,
    OutsLevelContainer,
    OutsContainer,
    OutBall,
    RedValue,
    YellowLabel,
    YellowLabelContainer,
    Wrapper,
  } = styleModule;
  const {
    bonus,
    bet,
    currencyFormat,
    gameEngineState,
    rollCnt: actualRollCount,
    holeTimes,
    streakStrike,
    showGameOverBanner,
    gameEvents,
    audioRef,
    freeCnt,
    freeGameAvailable
  } = useContext(DataContext);
  const [animateWinAmount, setAnimateWinAmount] = useState(false);
  let rollCnt = actualRollCount > 15 ? 15 : actualRollCount;

  const handleShowPrize = () => {
    audioRef.current.playWinSound();
    setAnimateWinAmount(true);
  };

  useEffect(() => {
    if (showGameOverBanner) {
      setAnimateWinAmount(false);
    }
  }, [showGameOverBanner]);

  useEffect(() => {
    gameEvents.on('cricket-show-price', handleShowPrize);
    return () => {
      gameEvents.off('cricket-show-price', handleShowPrize);
    };
  }, []);

  return (
    <Wrapper>
      <Braces />
      <ThemeVisibility themes={['baseball']}>
        <MetalBeam />
      </ThemeVisibility>
      <Contents>
        <YellowLabelContainer className={`fullcard fullcard${'-'+i18n.language}`}>
          <YellowLabel>
            <ThemeVisibility themes={['baseball']}>
              <div className="scoreboard-label">
                {t('scoreboard.baseball.jackpot')}
              </div>
            </ThemeVisibility>
            <ThemeVisibility themes={['cricket']}>
              {t('scoreboard.cricket.jackpot')}
            </ThemeVisibility>
          </YellowLabel>
          <RedValue className="scoreboard-value">
            {currencyFormatter(
              bet * getMultiplier(gameEngineState, 'sb.single.full-0-strike'),
              currencyFormat
            )}
          </RedValue>
        </YellowLabelContainer>
        <YellowLabelContainer className={`winnings winnings${'-'+i18n.language}`} >
          <YellowLabel>
            <ThemeVisibility themes={['baseball']}>
              <div className="scoreboard-label">
                {t('scoreboard.baseball.win')}
              </div>
            </ThemeVisibility>
            <ThemeVisibility themes={['cricket']}>
              {t('scoreboard.cricket.win')}
            </ThemeVisibility>
          </YellowLabel>
          <RedValue className="scoreboard-value">
            {currencyFormatter(bonus, currencyFormat)}
          </RedValue>
        </YellowLabelContainer>
        <YellowLabelContainer className={`hit-streak hit-streak${'-'+i18n.language}`} style={{opacity: freeGameAvailable || freeCnt ? 1 : 0.25}}>
          <YellowLabel>
            <ThemeVisibility themes={['baseball']}>
              <div className="scoreboard-label">
                {t('scoreboard.baseball.streak')}
              </div>
            </ThemeVisibility>
            <ThemeVisibility themes={['cricket']}>
            <div className={`hit-streak-label hit-streak-label${'-'+i18n.language}`}>{t('scoreboard.cricket.streak')}</div>
            </ThemeVisibility>
          </YellowLabel>
          {freeGameAvailable && <HitStreakContainer className="bat-on-ball">
            {Array.from({ length: rollCnt }).map((i, index) => (
              <Hit key={`hit-${index}`} />
            ))}
          </HitStreakContainer>}
        </YellowLabelContainer>
        <OutsLevelContainer>
          <GreyLabel>
            {streakStrike <= 2 && !animateWinAmount && (
              <GreyLabelContents
                animate={streakStrike === 2 && holeTimes === streakStrike}
                lang={i18n.language}
              >
                <ThemeVisibility themes={['cricket']}>
                  <div className={`first first${'-'+i18n.language}`}>{t('scoreboard.cricket.bonus')}</div>
                  <div>{t('scoreboard.cricket.bonus')}</div>
                </ThemeVisibility>
                <ThemeVisibility themes={['baseball']}>
                  <div className="first">{t('scoreboard.baseball.bonus')}</div>
                  <div>{t('scoreboard.baseball.bonus')}</div>
                </ThemeVisibility>
              </GreyLabelContents>
            )}
            {animateWinAmount && (
              <>
                <PrizeMoneyContainer>
                  {currencyFormatter(
                    bet * get777Multiplier(gameEngineState),
                    currencyFormat
                  )}
                </PrizeMoneyContainer>
                <Blanket />
              </>
            )}
          </GreyLabel>
          <YellowLabel className={`${t('scoreboard.cricket.outs').length > 4 ? 'outs outs-pt' : 'outs'}`}>
            <div>{t('scoreboard.cricket.outs')}</div>
            <OutsContainer>
              {Array.from({ length: holeTimes }).map((i, index) => (
                <OutBall
                  shouldRotate={
                    streakStrike === 2 && holeTimes === streakStrike
                  }
                  key={`out-${index}`}
                />
              ))}
            </OutsContainer>
          </YellowLabel>
        </OutsLevelContainer>
      </Contents>
    </Wrapper>
  );
};

export default Theme(CricketScoreBoard, 'components/CricketScoreBoard');
