import React from 'react';
import Theme from 'containers/Theme';
import { Space } from "antd";

const DiceNumberReveal = ({ styleModule }) => {
  const { DiceContainer, MyDicesContainer } = styleModule;

  return (
    <DiceContainer>
      <MyDicesContainer>
        <Space size={15}>
          <div id="dice1" data-side="1">
            <div className="sides side-1" />
            <div className="sides side-2" />
            <div className="sides side-3" />
            <div className="sides side-4" />
            <div className="sides side-5" />
            <div className="sides side-6" />
          </div>
          <div id="dice2" data-side="1">
            <div className="sides side-1" />
            <div className="sides side-2" />
            <div className="sides side-3" />
            <div className="sides side-4" />
            <div className="sides side-5" />
            <div className="sides side-6" />
          </div>
        </Space>
      </MyDicesContainer>
    </DiceContainer>
  );
}

export default Theme(DiceNumberReveal, 'components/DiceNumberReveal');