import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DataContext } from 'contexts/DataContextContainer';
import Theme from 'containers/Theme';
import { Fade } from 'react-awesome-reveal';
import {
  BigWheelLineRay,
  BigWheelStarIntro,
  BigWheelStarLoop,
  BigWheelStarIntroEs,
  BigWheelStarLoopEs,
  BigWheelStarIntroPt,
  BigWheelStarLoopPt,
  BigWheelStar,
  FreeStarLeft,
  FreeStarLeftPt,
  FreeStarLeftEs,
} from 'assets/images';

const Bigwheel = ({ styleModule }) => {
  const {
    Container,
    ExplosionContainer,
    Overlay,
    MovingStar,
    MovingStarDown,
    RayContainer,
    StarContainer,
    StarLoopContainer,
    StarMovementContainer,
  } = styleModule;
  const { i18n } = useTranslation();
  const {
    freeCnt,
    fromBetScreen,
    freeGameAwarded,
    rollCntRef,
    filled,
    showFreeCelebration,
    setFreeGameAwarded,
  } = useContext(DataContext);
  const containerRef = useRef();
  const introRef = useRef();
  const loopRef = useRef();

  const PT_IMAGES = {
    starLeft: FreeStarLeftPt,
    starIntro: BigWheelStarIntroPt,
    starLoop: BigWheelStarLoopPt,
  };

  const ES_IMAGES = {
    starLeft: FreeStarLeftEs,
    starIntro: BigWheelStarIntroEs,
    starLoop: BigWheelStarLoopEs,
  };

  const EN_IMAGES = {
    starLeft: FreeStarLeft,
    starIntro: BigWheelStarIntro,
    starLoop: BigWheelStarLoop,
  };

  const IMAGE_MAP = {
    pt: PT_IMAGES,
    es: ES_IMAGES,
    en: EN_IMAGES,
    'pt-br': PT_IMAGES,
    'pt-BR': PT_IMAGES,
    'en-us': EN_IMAGES,
    'en-US': EN_IMAGES,
  };

  const handleAnimation = () => {
    setTimeout(() => {
      if (introRef.current) {
        loopRef.current.style.display = 'block';
        introRef.current.style.display = 'none';
      }
    }, 3000);
  };

  const getStyle = () => {
    if (rollCntRef.current < 15) {
      return {
        opacity: '0',
      };
    }

    return {
      opacity: '1',
    };
  };

  useEffect(() => {
    if (rollCntRef.current === 15 && !freeGameAwarded) {
      handleAnimation();
      window.initFreeGameExplosion();
      setTimeout(() => {
        window.freeGameExplosion();
      }, 1500);
    }
  }, [rollCntRef, freeGameAwarded]);

  useEffect(() => {
    if (!filled && containerRef.current && freeGameAwarded) {
      window.stopFreeGameExplosion();
      containerRef.current.style.display = 'none';
    }
  }, [filled, freeGameAwarded]);

  useEffect(() => {
    if (!showFreeCelebration && rollCntRef.current === 15) {
      setTimeout(() => {
        setFreeGameAwarded(true);
      }, 5000);
    }
  }, []);

  if (
    (!showFreeCelebration && rollCntRef.current !== 15) ||
    !fromBetScreen.current
  ) {
    if (freeCnt > 0) {
      return (
        <>
          <div className="free-star-left-wrapper">
            <Fade>
              <div className="free-star-left">
              <div className="free-star-image" style={{ backgroundImage: `url(${IMAGE_MAP[i18n.language].starLeft})` }}></div>
                <div className="free-left-value">{freeCnt}</div>
              </div>
            </Fade>
          </div>
        </>
      );
    }
    return false;
  }

  return (
    <>
      <div className="free-star-left-wrapper">
        <Fade>
          <div className="free-star-left">
            <div className="free-star-image" style={{ backgroundImage: `url(${IMAGE_MAP[i18n.language].starLeft})` }}></div>
            <div className="free-left-value">{freeCnt}</div>
          </div>
        </Fade>
      </div>
      <Container
        className="bigwheel-freegame"
        style={getStyle()}
        ref={containerRef}
      >
        <Overlay />
        <StarContainer
          ref={introRef}
          src={IMAGE_MAP[i18n.language].starIntro}
        />
        <StarLoopContainer
          ref={loopRef}
          src={IMAGE_MAP[i18n.language].starLoop}
        />
        <RayContainer src={BigWheelLineRay} />
        <ExplosionContainer id="free-game-explosion-container" />
        <StarMovementContainer>
          <MovingStar delay={1} src={BigWheelStar} />
          <MovingStar delay={1.3} src={BigWheelStar} />
          <MovingStar delay={1.6} src={BigWheelStar} />
          <MovingStarDown delay={1.9} src={BigWheelStar} />
          <MovingStar delay={2.8} src={BigWheelStar} />
          <MovingStar delay={3.1} src={BigWheelStar} />
          <MovingStar delay={3.4} src={BigWheelStar} />
          <MovingStarDown delay={3.7} src={BigWheelStar} />
        </StarMovementContainer>
      </Container>
    </>
  );
};

export default Theme(Bigwheel, 'components/FreeGameAnimation');
