import React from 'react';
import Theme from 'containers/Theme';
import Dice from './Dice';
import BigWheel from './BigWheel';
import ThemeVisibility from 'components/ThemeVisibility';

const RollBonusMeter = ({ rollCount, filled }) => {
	return (
		<>
			<ThemeVisibility themes={['dice']}>
				<Dice rollCount={rollCount} filled={filled} />
			</ThemeVisibility>
			<ThemeVisibility themes={['bigwheel']}>
				<BigWheel rollCount={rollCount} filled={filled} />
			</ThemeVisibility>
		</>
	)
}

export default Theme(RollBonusMeter, 'components/RollBonusMeter');