import React from 'react';
import Theme from 'containers/Theme';
import { useTranslation } from "react-i18next";
import { currencyFormatter } from 'utils/currency-formatter';

const CommonHeader = ({
	bet,
	currencyFormat,
	playerBalance,
	styleModule,
}) => {
  const { t } = useTranslation();
	const { HeaderWrapper } = styleModule;

	return (
		<HeaderWrapper>
			<div className="balance-value">
				<b className="balance-value-title">
				{t("common.balance") + ':'}
				</b>
				<span style={{
					fontWeight: 700,
					fontSize: 14,
					color: "white",
					fontFamily: "Roboto",
				}}>{currencyFormatter(playerBalance, currencyFormat)}</span>
			</div>
			<div className="bet-value">
				<b className="bet-value-title">
					{t("common.bet") + ':'}
				</b>
				<span style={{
					fontWeight: 700,
					fontSize: 14,
					color: "white",
					fontFamily: "Roboto",
				}}>{currencyFormatter(bet, currencyFormat)}</span>
			</div>
		</HeaderWrapper>
	)
}

export default Theme(CommonHeader, 'layouts');