import React, { useContext, useCallback, useEffect } from 'react';
import { DataContext } from "contexts/DataContextContainer";
import Theme from 'containers/Theme';
import { currencyFormatter } from 'utils/currency-formatter';
import CountUp from 'react-countup';
import {
	BigWheelLineWinContainer,
	BigWheelLineWinMessage,
	BigWheelLineMessageContainer,
	BigWheelLineRay,
} from 'assets/images';
import { isFullCard } from 'service';

const LineWinAnimation = ({ controller, styleModule, themeSounds }) => {

	const {
		Container,
		CountUpContainer,
		FullCardMessage,
		LineWinNumberContainer,
		LineWinContainer,
		MessageContainer,
		PrizeMoneyContainer,
		RayContainer,
	} = styleModule;
	const {
		audioRef,
		bonus,
		currencyFormat,
		winnedLines,
		isPlayingMusic,
		statusesRef,
	} = useContext(DataContext);
	const isFullCardWin = isFullCard(statusesRef.current);

	const formatValueCallback = useCallback((val) => {
    return currencyFormatter(val, currencyFormat);
	})

	useEffect(() => {
		setTimeout(() => {
			isPlayingMusic && audioRef.current.playLineWin();

			setTimeout(() => {
				controller.handleCustomBgSound({ audioRef });
			}, 6000);
		}, 5000);
	}, [])

	return <Container className="bigwheel-line-win">
		<MessageContainer src={BigWheelLineMessageContainer} />
		{!isFullCardWin ? (
			<LineWinContainer src={BigWheelLineWinMessage} />
		): null}
		{!isFullCardWin ? (
			<LineWinNumberContainer>{winnedLines}</LineWinNumberContainer>
		): null}
		{isFullCardWin ? (
			<FullCardMessage>Big Win!</FullCardMessage>
		): null}
		<PrizeMoneyContainer src={BigWheelLineWinContainer} />
		<RayContainer src={BigWheelLineRay} />
		<CountUpContainer>
			<CountUp formattingFn={formatValueCallback} end={bonus} duration={5} decimals={2} delay={0}>
				{({ countUpRef }) => (
					<span
					ref={countUpRef}
					style={{
					color: 'white',
					fontWeight: 700,
					fontSize: 50,
					fontFamily: 'Roboto',
					}}
					/>
				)}
			</CountUp>
		</CountUpContainer>
	</Container>
}

export default Theme(LineWinAnimation, 'components/LineWinAnimation');