import styled from '@emotion/styled';
import { BetPane } from 'assets/images';

export const GuideRightContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 3000;
  .guide-dice-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 70px;
    z-index: 2;
    top: -10px;
    left: -80px;
  }
  .cricket-guide-dice {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 70px;
    z-index: 2;
    top: -33px;
    left: -44px;
  }
  .guide-title {
    position: relative;
  }

  .fake-panes {
    aspect-ratio: 1;
    width: 100%;
  }

  @media screen and (max-width: 450px) {
    .modal-guide-wrapper {
      max-width: unset;
      width: 100%;
      height: unset;
    }
  }

  .slide-wrapper {
    position: fixed;
    left: 0;
    bottom: 0;
    max-width: 28.125rem;
    width: 100vw;
  }

  .modal-guide-wrapper {
    position: relative;
    max-width: 450px;
    width: 100%;
    overflow: hidden;
    text-align: center;
    background: transparent;
    border: none;
    border-radius: 2px;
    font-size: 13px;
    .close-modal {
      position: absolute;
      top: 17px;
      right: 17px;
      font-size: 26px;
      color: white;
      z-index: 3;
      cursor: pointer;
    }
    .guide-wrapper {
      position: relative;
      background: url(${BetPane});
      background-size: 100% 100%;
      color: white !important;
      padding: 20px 66px;
      .guide-row {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        @media screen and (max-height: 640px), screen and (max-width: 430px) {
          font-size: 10px;
        }
        .white-finger {
          position: absolute;
          bottom: 0;
          margin-right: 21px;
        }
      }
    }
  }

  @media screen and (min-width: 1500px) {
    .modal-guide-wrapper {
      max-height: 800px;
      max-width: 550px;

      .ant-image {
        margin: auto;
        aspect-ratio: 1;
      }
    }
  }
`;
