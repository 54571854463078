import React, { useEffect, useRef } from 'react';
import Theme from 'containers/Theme';
import ApngComponent from 'react-apng';
import ThemeVisibility from "components/ThemeVisibility";
import {
	TripleBonusRay,
	TripleWordAnimated,
	TripleWordAnimatedPt,
	TripleWordAnimatedEs,
	OneMoreSeven,
	OneMoreSevenPt,
	OneMoreSevenEs,
} from 'assets/images';
import { useTranslation } from "react-i18next";

const DiceTripleSevenAnimation = ({ win, styleModule }) => {
	const { i18n } = useTranslation();
	const tripleWordRef = useRef();
	const raysRef = useRef();

	useEffect(() => {
		if (tripleWordRef && tripleWordRef.current) {
			tripleWordRef.current.play();
		}
	});

	useEffect(() => {
		if (raysRef && raysRef.current) {
			raysRef.current.play();
		}
	}, [win]);

	const handleOneMoreSeven = (lang) => {
		switch (lang) {
			case 'pt':
			case 'pt-BR':
			case 'pt-br':
			  	return OneMoreSevenPt
			case 'es':
				return OneMoreSevenEs
			default:
			  return OneMoreSeven
		  }
	}

	const handleTripleWordAnimation = (lang) => {
		switch (lang) {
			case 'pt':
			case 'pt-BR':
			case 'pt-br':
				return TripleWordAnimatedPt
			case 'es':
				return TripleWordAnimatedEs
			default:
				return TripleWordAnimated
		}
	}

	return <styleModule.Container win={win}>
		<styleModule.TripleBonusWrapper className={win ? 'win' : ''}>
			<ApngComponent className="triple-bonus-word" ref={tripleWordRef} src={handleTripleWordAnimation(i18n.language)} />
		</styleModule.TripleBonusWrapper>
		{win && <ApngComponent className="rays" ref={tripleWordRef} src={TripleBonusRay} />}
		{win && <styleModule.Overlay />}
		{!win && (
			<ThemeVisibility themes={['dice']}>
				<styleModule.OneMoreSevenContainer src={handleOneMoreSeven(i18n.language)} />
			</ThemeVisibility>
		)}
	</styleModule.Container>;
}

export default Theme(DiceTripleSevenAnimation, 'components/TripleSevenAnimation');