export const getMultiplier = (gameEngineState, label) => {
  if (!gameEngineState.gameSession) {
    return 0;
  }

  const { gameSession: { gameConfig: { prizes: { prizes } } } } = gameEngineState;

  const group = prizes.find(p => p.label === 'sb.single.main-prize-group');
  const prize = group.prizes.find(p => p.label === label);

  return prize && prize.multiplier ? prize.multiplier : prize.prizeType === 'prize-free-game' ? 'free-game' : 0;
}

export const get777Multiplier = (gameEngineState) => {
  if (!gameEngineState.gameSession) {
    return 0;
  }

  const { gameSession: { gameConfig: { prizes: { prizes } } } } = gameEngineState;

  const prize = prizes.find(p => p.label === 'sb.single.bonus.triple-sevens');

  return prize && prize.multiplier ? prize.multiplier : 0;
}