/* eslint-disable eqeqeq */
import { NumBlue, NumGreen, NumOrange, NumWhite } from "assets/images";
import { keyframes } from "@emotion/core";

export const customAnimation = keyframes`
  0% { transform: scale( 1 ); }
  25% { transform: scale( 1.25 ); }
  50% { transform: scale( 1 ); }
  75% { transform: scale( 1.25 ); }
  100% { transform: scale( 1 ); }
`;
export const roundsCountainerFlash = keyframes`
  0% { transform: scaleX( 1 ); }
  25% { transform: scaleX( 1.1 ); }
  50% { transform: scaleX( 1 ); }
  75% { transform: scaleX( 1.1 ); }
  100% { transform: scaleX( 1 ); }
`;

const mainWidth = 490;
const holesWidth = 200;
const holesHeight = holesWidth / 5;
const betWidth = mainWidth / 3;
const betHeight = betWidth * 0.2;
const gutter = 4;
const numberWidth = (mainWidth - 31 - gutter * 4) / 5;
const coinContainerWidth = 200;
const coinContainerHeight = coinContainerWidth * 0.33;
const roundWidhtHeight = mainWidth / 9.5 - 20;
const gapBetweenRound = (mainWidth - 35 - roundWidhtHeight * 15) / 14;
const stepWidthRound = 14 + gapBetweenRound;
export const sizes = {
  mainWidth: mainWidth,
  mainWidthM: 350,
  betWidth: betWidth,
  bonusWidth: betWidth,
  betHeight: betHeight,
  bonusHeight: betHeight,
  headerTextWidth: mainWidth / 2,
  coinContainerWidth: coinContainerWidth,
  coinContainerHeight: coinContainerHeight,
  roundsContainerWidth: mainWidth,
  roundsContainerHeight: mainWidth / 9,
  gapBetweenRound: gapBetweenRound,
  roundWidhtHeight: roundWidhtHeight,
  holesWidth: holesWidth,
  holesHeight: holesHeight,
  holeWidth: holesHeight - 15,
  numberWidth: numberWidth,
  gutter: gutter,
  stepWidthRound: stepWidthRound,
  streakBonusWidth: (mainWidth * 2) / 3,
};

export const numbersTemp = [
  2, 9, 8, 6, 3, 6, 4, 12, 10, 8, 11, 5, 7, 2, 5, 5, 12, 10, 8, 9, 8, 6, 3, 4,
  11,
];

export const cricketNumbersTemp = [
  1,44,50,55,29,55,33,10,30,50,22,49,7,1,49,49,10,30,50,44,50,55,29,33,22
];

export const statusTemp = [
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0, 0,
];

export const numsTemp = {
  1: { id: "1", value: 2, status: "white" },
  2: { id: "2", value: 9, status: "white" },
  3: { id: "3", value: 8, status: "white" },
  4: { id: "4", value: 6, status: "white" },
  5: { id: "5", value: 3, status: "white" },
  6: { id: "6", value: 6, status: "white" },
  7: { id: "7", value: 4, status: "white" },
  8: { id: "8", value: 12, status: "white" },
  9: { id: "9", value: 10, status: "white" },
  10: { id: "10", value: 8, status: "white" },
  11: { id: "11", value: 11, status: "white" },
  12: { id: "12", value: 5, status: "white" },
  13: { id: "13", value: 7, status: "white" },
  14: { id: "14", value: 2, status: "white" },
  15: { id: "15", value: 5, status: "white" },
  16: { id: "16", value: 5, status: "white" },
  17: { id: "17", value: 12, status: "white" },
  18: { id: "18", value: 10, status: "white" },
  19: { id: "19", value: 8, status: "white" },
  20: { id: "20", value: 9, status: "white" },
  21: { id: "21", value: 8, status: "white" },
  22: { id: "22", value: 6, status: "white" },
  23: { id: "23", value: 3, status: "white" },
  24: { id: "24", value: 4, status: "white" },
  25: { id: "25", value: 11, status: "white" },
};
export const numsTutorials = {
  1: { id: "1", value: 2, status: "white" },
  2: { id: "2", value: 9, status: "blue" },
  3: { id: "3", value: 8, status: "blue" },
  4: { id: "4", value: 6, status: "white" },
  5: { id: "5", value: 3, status: "white" },
  6: { id: "6", value: 6, status: "blue" },
  7: { id: "7", value: 4, status: "blue" },
  8: { id: "8", value: 12, status: "white" },
  9: { id: "9", value: 10, status: "blue" },
  10: { id: "10", value: 8, status: "blue" },
  11: { id: "11", value: 11, status: "white" },
  12: { id: "12", value: 5, status: "blue" },
  13: { id: "13", value: 7, status: "blue" },
  14: { id: "14", value: 2, status: "white" },
  15: { id: "15", value: 5, status: "blue" },
  16: { id: "16", value: 5, status: "blue" },
  17: { id: "17", value: 12, status: "white" },
  18: { id: "18", value: 10, status: "white" },
  19: { id: "19", value: 8, status: "blue" },
  20: { id: "20", value: 9, status: "blue" },
  21: { id: "21", value: 8, status: "blue" },
  22: { id: "22", value: 6, status: "white" },
  23: { id: "23", value: 3, status: "white" },
  24: { id: "24", value: 4, status: "white" },
  25: { id: "25", value: 11, status: "blue" },
};

export const getRandomBetween = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const getRandomIn = (nums) => {
  const randomMonth = nums[Math.floor(Math.random() * nums.length)];
  return randomMonth;
};
export const numberInfo = {
  1: {
    background: NumGreen,
    color: "white",
  },
  2: {
    background: NumBlue,
    color: "white",
  },
  3: {
    background: NumOrange,
    color: "white",
  },
  0: {
    background: NumWhite,
    color: "#242e42",
  },
};

export const pairs = {
  1: ["w1", "h1", "c1"],
  2: ["w1", "h2"],
  3: ["w1", "h3"],
  4: ["w1", "h4"],
  5: ["w1", "h5", "c2"],
  6: ["w2", "h1"],
  7: ["w2", "h2", "c1"],
  8: ["w2", "h3"],
  9: ["w2", "h4", "c2"],
  10: ["w2", "h5"],
  11: ["w3", "h1"],
  12: ["w3", "h2"],
  13: ["w3", "h3", "c1", "c2"],
  14: ["w3", "h4"],
  15: ["w3", "h5"],
  16: ["w4", "h1"],
  17: ["w4", "h2", "c2"],
  18: ["w4", "h3"],
  19: ["w4", "h4", "c1"],
  20: ["w4", "h5"],
  21: ["w5", "h1", "c2"],
  22: ["w5", "h2"],
  23: ["w5", "h3"],
  24: ["w5", "h4"],
  25: ["w5", "h5", "c1"],
};

export const numberGroup = {
  w1: ["1", "2", "3", "4", "5"],
  w2: ["6", "7", "8", "9", "10"],
  w3: ["11", "12", "13", "14", "15"],
  w4: ["16", "17", "18", "19", "20"],
  w5: ["21", "22", "23", "24", "25"],
  h1: ["1", "6", "11", "16", "21"],
  h2: ["2", "7", "12", "17", "22"],
  h3: ["3", "8", "13", "18", "23"],
  h4: ["4", "9", "14", "19", "24"],
  h5: ["5", "10", "15", "20", "25"],
  c1: ["1", "7", "13", "19", "25"],
  c2: ["5", "9", "13", "17", "21"],
};

export const winGroupOfNum = {
  1: {
    w1: ["1", "2", "3", "4", "5"],
  },
};

export const dimentions = {
  totalLeft: 160,
  totalTop: 70,
  additionalLeft: 132,
  additionalTop: 373,
};

export const credits = {
  0: 0,
  1: 0.5,
  2: 0,
  3: 1,
  4: 2,
  5: 2,
  6: 4,
  7: 4,
  8: 7,
  9: 12,
  10: 15,
  buyStrike: 7,
  strike1: 150,
  strike2: 30,
};
export const statusColors = {
  green: 1,
  orange: 3,
  white: 0,
  blue: 2,
};

export const soundSetting = {
  volume: 0.5,
};

export const LineWinAnimationType = {
  Hide: 0,
  SixToTen: 1,
  FullCard: 2,
}

export const getFirstOrange = (statuses) => {
  return statuses.findIndex((_status) => _status == statusColors.orange) + 1;
};

export const isFullCard = (statuses) => {
  const numberOfWhites = statuses.filter((status) => status == statusColors.white);

  if (numberOfWhites.length === 1) {
    const whiteIndex = statuses.findIndex((status) => status == statusColors.white);

    return whiteIndex === 12;
  } else if (numberOfWhites.length === 0) {
    return true;
  }

  return false;
}