export const currencyFormatter = (number, formatter, attemptFormattingCents = true) => {
	let ret;
	const valuePrecisionAdjustedString = Number(number).toFixed(formatter.decimalPrec);
	const valuePrecisionAdjusted = parseFloat(valuePrecisionAdjustedString);
	const [whole, fraction] = valuePrecisionAdjustedString
		.toString()
		.split('.');

	if (
		valuePrecisionAdjusted >= 1 ||
		valuePrecisionAdjusted <= -1 ||
		valuePrecisionAdjusted === 0 ||
		!attemptFormattingCents ||
		formatter.centsPos === 'N' ||
		formatter.centsSym === '' ||
		formatter.centsSym === undefined ||
		formatter.centsSym === formatter.symbol
	) {
		const wholeSeparated = whole.replace(
			/\B(?=(\d{3})+(?!\d))/g,
			formatter.thousandSep,
		);

		ret = wholeSeparated;
		if (fraction) {
			ret += `${formatter.sep}${fraction}`;
		}

		if (formatter.pos === 'L') {
			ret = formatter.symbol + ret;
		} else {
			ret = ret + formatter.symbol;
		}
	} else {
		let symbol = formatter.centsSym ? formatter.centsSym : formatter.symbol;
		ret = parseInt(fraction) || '';
		if (formatter.centsPos === 'L') {
			ret = symbol + ret;
		} else {
			ret = ret + symbol;
		}
	}

	return ret;
}

export const getPlayerBalance = (gameEngineState) => {
	if (gameEngineState) {
		if (gameEngineState.gameSession) {
			const currency = gameEngineState.gameSession.gameConfig.gameCurrency;
			const balances = Object.keys(gameEngineState.player);

			const balance = balances.find((balance) => {
				const entity = gameEngineState.player[balance];

				if (entity && entity.currency === currency) {
					return true;
				}

				return false;
			})

			if (balance) {
				return parseFloat(gameEngineState.player[balance].total);
			}
		}
	}
	return 0;
}