import React from 'react'
import PropTypes from 'prop-types';
import Theme from 'containers/Theme';

const stars = [
  { sl: -10, el: -300, sb: 20, eb: 50 + 40, delay: 0, time: 1},
  { sl: -20, el: -300, sb: 20, eb: 60 + 40, delay: 1, time: 5},
  { sl: -10, el: -300, sb: 20, eb: 50 + 40, delay: 2, time: 2},
  { sl: -10, el: -300, sb: 20, eb: 60 + 40, delay: 3, time: 4},
  { sl: -20, el: -310, sb: 10, eb: 50 + 40, delay: 0, time: 1},
  { sl: -30, el: -310, sb: 10, eb: 60 + 40, delay: 1, time: 2},
  { sl: -20, el: -310, sb: 10, eb: 50 + 40, delay: 2, time: 4},
  { sl: 0, el: -310, sb: 10, eb: 60 + 40, delay: 0, time: 3},
]

const StarsFilled = (props) => {
  const { styleModule } = props;

  return (
    <styleModule.StarsFilledContainer>
      {stars.map((_star, _i) =>
        <styleModule.StarItemContainer
          key={_i}
          num={_i.toString()}
          startLeft={_star.sl}
          endLeft={_star.el}
          startBottom={_star.sb}
          moveHeight={_star.eb}
          time={_star.time}
          delay={_star.delay}
          size={15}
        >
          <span role='img' aria-label='star'>⭐</span>
        </styleModule.StarItemContainer>
      )}
    </styleModule.StarsFilledContainer>
  )
}

StarsFilled.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  framer: PropTypes.object,
  onClick: PropTypes.func,
};

export default Theme(StarsFilled, 'components/StarsFilled');