import styled from '@emotion/styled'

export const LogPaneContainer = styled.div`
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  outline: outset 1px white;
  color: white;
  .log-pane-item {
    height: 30px;
    border: outset 1px white;
    display: flex;
    justify-content: center;
    align-items: center;
    &:not(.white) {
      background: white;
      color: #000;
    }
  }
`;
