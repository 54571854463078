import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from "contexts/DataContextContainer";
import Theme from 'containers/Theme';
import {
	BigWheelGremlinPinkIdle,
	BigWheelGremlinYellowIdle,
	BigWheelGremlinBlueIdle,
} from "assets/images";

const BigWheel = ({ styleModule }) => {
	const { Container, Hole } = styleModule;
  const {
    holeTimesRef,
		gameEvents,
  } = useContext(DataContext);
	const [internalHoles, setInternalHoles] = useState(holeTimesRef.current);

	const getGremlin = (i) => {
		switch (i) {
			case 0:
				return BigWheelGremlinBlueIdle;
			case 1:
				return BigWheelGremlinYellowIdle;
			case 2:
				return BigWheelGremlinPinkIdle;
		}
	}

	const handleStrikeAnim = () => {
		setInternalHoles((val) => val + 1);
	}

	const handleGameOver = () => {
		setTimeout(() => setInternalHoles(0), 2000);
	}

	useEffect(() => {
		gameEvents.on('gremlin-strike-anim-done', handleStrikeAnim);
		gameEvents.on('game-over', handleGameOver);

		return () => {
			gameEvents.off('gremlin-strike-anim-done', handleStrikeAnim);
			gameEvents.off('game-over', handleGameOver);
		}
	}, []);

	return (
		<Container>
			{Array.from({ length: 3 }, (v, i) => i + 1).map((_hole, _i) => (
				<Hole visible={_i <= internalHoles - 1} className={`item-${_i}`} key={_i} src={getGremlin(_i)} />
			))}
		</Container>
	)
};

export default Theme(BigWheel, 'components/Strikes');