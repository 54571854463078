import React from 'react';
import Theme from 'containers/Theme';
import StarsFilled from "components/StarsFilled";

const BigWheel = ({ rollCount, styleModule, filled }) => {
  const getStarAnimationClass = () => {
    if (rollCount >= 13 && rollCount < 15) {
      return 'animate__animated animate__heartBeat animate__infinite animate__slow';
    }
  }

	return <styleModule.Container>
		<styleModule.MeterSquaresContainer>
			{Array.from({ length: 15 }).map((i, index) => (
				<styleModule.MeterSquaresEmpty active={index + 1 <= rollCount} key={`remaining-${index}`} />
			))}
		</styleModule.MeterSquaresContainer>
		<styleModule.Star className={getStarAnimationClass()}>
			{filled && <StarsFilled />}
		</styleModule.Star>
	</styleModule.Container>;
}

export default Theme(BigWheel, 'components/RollBonusMeter');