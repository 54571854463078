import React, { useContext } from 'react';
import { ThemeContext } from "contexts/ThemeContext";

const ThemeVisibility = ({ themes, children }) => {
  const theme = useContext(ThemeContext);

  if (!themes || themes.indexOf(theme) === -1) {
    return null;
  }

  return (
    <>
      {children}
    </>
  )
}

export default ThemeVisibility;