import React from "react";
import Theme from "containers/Theme";
import { RedDice7 } from "assets/images";
import SingleAntImage from "components/SingleAntImage";

const SevenIcon = ({ styleModule }) => {
	const { Wrapper } = styleModule;

	return (
		<Wrapper className="free-star">
			<SingleAntImage src={RedDice7} width={25} height="auto" />
		</Wrapper>
	);
}

export default Theme(SevenIcon, 'components/SevenIcon');