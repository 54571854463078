import React from 'react';
import ThemeVisibility from 'components/ThemeVisibility';
import CricketPlayballIntroOutro from "components/CricketPlayballIntroOutro";
import BaseballPlayballIntroOutro from "components/BaseballPlayballIntroOutro";

const PlayballIntro = () => {
	return (
		<>
			<ThemeVisibility themes={['cricket']}>
				<CricketPlayballIntroOutro />
			</ThemeVisibility>
			<ThemeVisibility themes={['baseball']}>
				<BaseballPlayballIntroOutro />
			</ThemeVisibility>
		</>
	)
}

export default PlayballIntro;