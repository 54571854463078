import {
  FakeDice,
  FakePane,
  CricketFakePane,
  CricketOutRedBall,
  WhiteFinger,
  BaseballBallWhite,
  BaseballFakePane,
  BigWheelFakePane,
  BigWheelGremlin,
} from 'assets/images';
import useSound from 'use-sound';
import { soundSetting } from 'service';
import { SoundClickButton } from 'assets/audio';
import SingleAntImage from 'components/SingleAntImage';
import SingleText from 'components/SingleText';
import { DataContext } from 'contexts/DataContextContainer';
import React, { useContext } from 'react';
import { Slide } from 'react-awesome-reveal';
import { colors } from 'theme';
import { GuideRightContainer } from './style';
import { useTranslation } from 'react-i18next';
import ThemeVisibility from 'components/ThemeVisibility';

export default function GuideRight({ value }) {
  const { t } = useTranslation();
  const { setShowGuide, isPlayingSound } = useContext(DataContext);
  const [playSoundClickButton] = useSound(SoundClickButton, soundSetting);

  const handleClose = () => {
    isPlayingSound && playSoundClickButton();
    setShowGuide(false);
  };

  return (
    <GuideRightContainer className="modal-guide">
      <Slide direction="left" className="slide-wrapper">
        <div className="modal-guide-wrapper">
          <i className="fas fa-times close-modal" onClick={handleClose} />
          <div className="guide-wrapper">
            <div className="guide-row">
              <SingleText
                className="guide-title"
                size={24}
                weight="bold"
                color={colors.yellow1}
                marginBottom={5}
              >
                <ThemeVisibility themes={['dice']}>
                  {t('howToPlay.howToPlay')}
                  <SingleAntImage
                    src={FakeDice}
                    className="guide-dice"
                    width="70px"
                    height="auto"
                  />
                </ThemeVisibility>
                <ThemeVisibility themes={['cricket']}>
                  {t('howToPlay.cricket.howToPlay')}
                  <SingleAntImage
                    src={CricketOutRedBall}
                    className="cricket-guide-dice"
                    width="40px"
                    height="auto"
                  />
                </ThemeVisibility>
                <ThemeVisibility themes={['bigwheel']}>
                  {t('howToPlay.cricket.howToPlay')}
                  <SingleAntImage
                    src={BigWheelGremlin}
                    className="cricket-guide-dice"
                    width="40px"
                    height="auto"
                  />
                </ThemeVisibility>
                <ThemeVisibility themes={['baseball']}>
                  {t('howToPlay.cricket.howToPlay')}
                  <SingleAntImage
                    src={BaseballBallWhite}
                    className="cricket-guide-dice"
                    width="40px"
                    height="auto"
                  />
                </ThemeVisibility>
              </SingleText>
            </div>
            <div className="guide-row">
              <SingleText
                className="guide-title"
                color={colors.redColor}
                marginBottom={10}
              >
                <ThemeVisibility themes={['dice']}>
                  {t('howToPlay.strikeSeven')}
                </ThemeVisibility>
                <ThemeVisibility themes={['cricket']}>
                  {t('howToPlay.cricket.strikeSeven')}
                </ThemeVisibility>
                <ThemeVisibility themes={['baseball']}>
                  {t('howToPlay.baseball.strikeSeven')}
                </ThemeVisibility>
                <ThemeVisibility themes={['bigwheel']}>
                  {t('howToPlay.bigwheel.strikeSeven')}
                </ThemeVisibility>
              </SingleText>
            </div>
            <div className="guide-row">
              <SingleText
                className="guide-title"
                color="white"
                marginBottom={10}
              >
                <ThemeVisibility themes={['dice']}>
                  {t('howToPlay.allOtherNumbers')}
                </ThemeVisibility>
                <ThemeVisibility themes={['cricket']}>
                  {t('howToPlay.cricket.allOtherNumbers')}
                </ThemeVisibility>
                <ThemeVisibility themes={['baseball']}>
                  {t('howToPlay.cricket.allOtherNumbers')}
                </ThemeVisibility>
                <ThemeVisibility themes={['bigwheel']}>
                  {t('howToPlay.bigwheel.allOtherNumbers')}
                </ThemeVisibility>
              </SingleText>
            </div>
            <div className="guide-row fake-panes" style={{ marginBottom: 10 }}>
              <ThemeVisibility themes={['dice']}>
                <SingleAntImage
                  src={FakePane}
                  height="unset"
                  width="100%"
                  style={{ margin: 'auto', height: 'unset', aspectRatio: 1 }}
                />
              </ThemeVisibility>
              <ThemeVisibility themes={['cricket']}>
                <SingleAntImage
                  src={CricketFakePane}
                  height="unset"
                  width="100%"
                  style={{ margin: 'auto', height: 'unset', aspectRatio: 1 }}
                />
              </ThemeVisibility>
              <ThemeVisibility themes={['baseball']}>
                <SingleAntImage
                  src={BaseballFakePane}
                  height="unset"
                  width="100%"
                  style={{ margin: 'auto', height: 'unset', aspectRatio: 1 }}
                />
              </ThemeVisibility>
              <ThemeVisibility themes={['bigwheel']}>
                <SingleAntImage
                  src={BigWheelFakePane}
                  height="unset"
                  width="100%"
                  style={{ margin: 'auto', height: 'unset', aspectRatio: 1 }}
                />
              </ThemeVisibility>

              <ThemeVisibility themes={['dice']}>
                <SingleAntImage
                  src={WhiteFinger}
                  className="white-finger"
                  width="60px"
                  height="auto"
                />
              </ThemeVisibility>
            </div>
            <div className="guide-row">
              <SingleText
                className="guide-title"
                color="white"
                marginBottom={10}
                align="center"
              >
                <ThemeVisibility themes={['dice']}>
                  {t('howToPlay.makeTheChoice')}
                </ThemeVisibility>
                <ThemeVisibility themes={['cricket']}>
                  {t('howToPlay.cricket.makeTheChoice')}
                </ThemeVisibility>
                <ThemeVisibility themes={['baseball']}>
                  {t('howToPlay.cricket.makeTheChoice')}
                </ThemeVisibility>
                <ThemeVisibility themes={['bigwheel']}>
                  {t('howToPlay.bigwheel.makeTheChoice')}
                </ThemeVisibility>
              </SingleText>
            </div>
            <div className="guide-row">
              <SingleText
                className="guide-title"
                color={colors.green}
                marginBottom={10}
              >
                {t('howToPlay.bingoAnyRow')}
              </SingleText>
            </div>
            <div className="guide-row">
              <SingleText
                className="guide-title"
                color={colors.green}
                marginBottom={10}
              >
                <ThemeVisibility themes={['dice']}>
                  {t('howToPlay.plusThree')}
                </ThemeVisibility>
                <ThemeVisibility themes={['cricket']}>
                  {t('howToPlay.cricket.plusThree')}
                </ThemeVisibility>
                <ThemeVisibility themes={['baseball']}>
                  {t('howToPlay.cricket.plusThree')}
                </ThemeVisibility>
                <ThemeVisibility themes={['bigwheel']}>
                  {t('howToPlay.cricket.plusThree')}
                </ThemeVisibility>
              </SingleText>
            </div>
            {/* <div className="guide-row">
              <ThemeVisibility themes={['dice']}>
                <SingleText
                  className="guide-title"
                  color="white"
                  marginBottom={10}
                >
                  {t('howToPlay.forJackpots')}
                </SingleText>
              </ThemeVisibility>
            </div> */}
          </div>
        </div>
      </Slide>
    </GuideRightContainer>
  );
}
