//export { default as SoundClickButton } from './button_click.mp3';
export { default as SoundOrangeTile } from './SoundOrangeResult.mp3';
export { default as SoundOrangeWobble } from './SoundOrangeWobble.mp3';
export { default as SoundOrangeClick } from './SoundOrangeTile.mp3';
export { default as SoundOrangeTileDelay } from './SoundOrangeTileDelay.wav';
export { default as SoundBackground } from './SoundBackground.mp3';
export { default as SoundRolling } from './SoundRolling.mp3';
export { default as SoundDuplicate } from './SoundDuplicate.wav';
export { default as SoundGreenLine } from './GreenLine.mp3';
export { default as SoundClickButton } from './SoundOrangeClick.mp3';
export { default as SoundBetPlus } from './SoundBetPlus.mp3';
export { default as SoundBetMinus } from './SoundBetMinus.mp3';
export { default as SoundStrike } from './SoundStrike.mp3';
export { default as SoundSuspense777 } from './SuspenseSound777.mp3';
export { default as Strike7 } from './strike-7.mp3';
export { default as GameOver } from './GameOver.mp3';
export { default as CountUpWin } from './CountUpWin.mp3';
export { default as JackpotSound } from './JackpotSound.mp3';
export { default as SwooshSound } from './SwooshSound.mp3';
export { default as TripleSevenLastHit } from './Triple_Seven_Last_Hit.mp3';
export { default as Win6to10Sound } from './Win6to10lines.mp3';
export { default as SideBetWinner } from './SideBetWinner.mp3';

// Cricket
export { default as CricketBackgroundAudio } from './cricket/cricket_crowd_noise.mp3';
export { default as CricketSoundSuspense777 } from './cricket/suspense.mp3';
export { default as CricketBallThrow } from './cricket/cricket_throw.mp3';
export { default as CricketBallLineup } from './cricket/cricket_ball_lineup.mp3';
export { default as CricketBatImpact } from './cricket/cricket_bat_impact.mp3';
export { default as CricketBallsFillingHopper } from './cricket/cricket_balls_filling_hopper.mp3';
export { default as CricketSportRunningSound } from './cricket/cricket_sport_running_sound.mp3';
export { default as CricketStrikeSound } from './cricket/cricket_strike_sound.mp3';
export { default as CricketCrowdCheer } from './cricket/cricket_crowd_cheering.mp3';
export { default as CricketSoundWin } from './cricket/cricket_win.mp3';
export { default as CricketCrowdBoo } from './cricket/crowd_boo.mp3';

// Baseball
export { default as BaseballSoundGreenLine } from './baseball/Organ_charge_fanfare_single.mp3';
export { default as BaseballCannon } from './baseball/cannon_shot_ball_pitched.mp3';
export { default as BaseballBackgroundAudio } from './baseball/stadium_crowd_noise.mp3';
export { default as BaseballOrgan1 } from './baseball/Organ_charge_1.mp3';
export { default as BaseballOrgan2 } from './baseball/Organ_charge_Defense_with_Stadium_clap.mp3';
export { default as BaseballOrgan3 } from './baseball/Organ_charge_Spanish.mp3';
export { default as BaseballSportsCrowd } from './baseball/Sports_Crowd.mp3';
export { default as BaseballAnthem } from './baseball/Baseball_Anthem.mp3';
export { default as BaseballCrowdBoo } from './baseball/Boo_2.mp3';
export { default as BaseballOut } from './baseball/Umpire_OUT.mp3';
export { default as BaseballRollingSuspense } from './baseball/Suspense_for_ball_spinning.mp3';

// Bigwheel
export { default as BigWheelBackgroundSound } from './bigwheel/BigWheelBackgroundLoop.mp3';
export { default as BigWheelGremlinLaunch } from './bigwheel/gremlin_launch.wav';
export { default as BigWheelSpinClick } from './bigwheel/spin_button_click.mp3';
export { default as BigWheelWheelSpin } from './bigwheel/wheel_spin_short.mp3';
export { default as BigWheelLineWin } from './bigwheel/bigwheel_line_win.mp3';
export { default as BigWheelGremlinLaugh } from './bigwheel/gremlin_laugh.mp3';
export { default as BigWheelGremlinLaugh2 } from './bigwheel/gremlin_laugh2.mp3';
export { default as BigWheelFreegame } from './bigwheel/free_game.mp3';