import React, { useContext, useEffect, useRef } from 'react'
import ThemeVisibility from "components/ThemeVisibility";
import SingleText from 'components/SingleText';
import { JackPotContainer, NoOutsLabel } from './style';
import ApngComponent from 'react-apng';
import { CricketNoStrikeAnimated, NoStrikeAnimated, NoStrikeAnimatedPt } from 'assets/images';
import { DataContext } from 'contexts/DataContextContainer';
import { getMultiplier } from 'utils';
import { currencyFormatter } from 'utils/currency-formatter';
import { useTranslation } from "react-i18next";

export default function JackPot({ value }) {
  const { t, i18n } = useTranslation();
  const animatedContainerRef = useRef();
  const playAnimationRef = useRef();
  const { bet, statuses, currencyFormat, gameEngineState } = useContext(DataContext);
  const numberOfUnpicked = statuses.filter((s) => s !== 2 && s !== 1).length;

  const scheduleAnimationPlay = () => {
    playAnimationRef.current = setTimeout(() => {
      if (animatedContainerRef.current) {
        animatedContainerRef.current.play();
      }

      setTimeout(() => {
        if (animatedContainerRef.current) {
          animatedContainerRef.current.stop();
        }
        if (numberOfUnpicked > 5) {
          scheduleAnimationPlay();
        }
      }, 1500);
    }, 15000);
  }

  useEffect(() => {
    if (animatedContainerRef && animatedContainerRef.current && statuses.length) {
      if (numberOfUnpicked <= 5) {
        animatedContainerRef.current.play();
      } else if (numberOfUnpicked > 5) {
        if (!playAnimationRef.current) {
          scheduleAnimationPlay();
        }
      } else {
        animatedContainerRef.current.stop();
      }
    }
  });

  const handleNoStrikeAnimated = (lang) => {
    switch (lang) {
      case 'pt':
      case 'pt-BR':
      case 'pt-br':
        return NoStrikeAnimatedPt
      default:
        return NoStrikeAnimated
    }
  }
  return (
    <JackPotContainer className='jack-bot'>
      {/* <ThemeVisibility themes={['dice']}>
        <ApngComponent className="animated-container" ref={animatedContainerRef} src={handleNoStrikeAnimated(i18n.language)} />
      </ThemeVisibility> */}
      <ThemeVisibility  themes={['cricket', 'baseball', 'dice']}>
        <NoOutsLabel className={`zero-outs-top-${i18n.language}`}>{ t("common.baseball.noOuts") }</NoOutsLabel>
        <ApngComponent className="animated-container" ref={animatedContainerRef} src={CricketNoStrikeAnimated} />
      </ThemeVisibility>
      <SingleText weight={700} size={22} marginBottom={19}>
        <span>{currencyFormatter(bet * getMultiplier(gameEngineState, 'sb.single.full-0-strike'), currencyFormat)}</span>
      </SingleText>
    </JackPotContainer>
  )
}

