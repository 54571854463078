import React, { useContext } from 'react';
import Theme from 'containers/Theme';
import { DataContext } from 'contexts/DataContextContainer';
import SingleButton from "components/SingleButton";
import SingleText from "components/SingleText";
import { BtnRoll } from "assets/images";
import { useTranslation } from "react-i18next";

const DiceLetsRoll = ({ styleModule, onClickRollBtn }) => {
	const { t } = useTranslation();
	const { activeBtn, holeTimes, startedRound } = useContext(DataContext);

	return (
		<styleModule.RollButtonContainer>
			<div className="btn-container">
				{activeBtn && (
					<div>
						<SingleButton
							renderTitle={() => (
								<SingleText
									size={holeTimes > 0 ? 32 : 27 }
									color="#005822"
									weight={700}
									marginBottom={4}
									family="Barlow Semi Condensed Bold"
									filter="drop-shadow(0px 2px 0px rgba(170,234,127,0.8))"
									align="center"
									lineHeight="28px"
								>
									{holeTimes > 0 ? t("common.rollAgain") : t("common.letsRoll")}
								</SingleText>
							)}
							className="roll-btn"
							animate={holeTimes !== 3 && !startedRound ? true : false}
							width="225px"
							height="105px"
							bg={BtnRoll}
							onPress={onClickRollBtn}
						/>
					</div>
				)}
			</div>
		</styleModule.RollButtonContainer>
	)
}

export default Theme(DiceLetsRoll, 'components/LetsRoll');