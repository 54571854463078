import React from 'react'
import PropTypes from 'prop-types';

export default function SingleText({
  children,
  className = '',
  width = 'fit-content',
  size = '',
  color = 'white',
  family = 'Roboto',
  weight = 'normal',
  opacity = 1,
  stretch = 'normal',
  fontStyle = 'normal',
  spacing = 'normal',
  lineHeight = 'normal',
  marginBottom = 0,
  marginLeft = 0,
  marginRight = 0,
  cursor = false,
  align = 'center',
  filter = '',
  textShadow = 'unset',
  uppercase = false
}) {
  return (
    <span
      className={className}
      style={{
        width: width,
        fontSize: size,
        color: color,
        fontFamily: family,
        fontWeight: weight,
        opacity: opacity,
        fontStretch: stretch,
        fontStyle: fontStyle,
        letterSpacing: spacing,
        lineHeight: lineHeight,
        marginBottom: marginBottom,
        marginLeft: marginLeft,
        marginRight: marginRight,
        textAlign: align,
        cursor: cursor ? 'pointer' : 'inherit',
        filter: filter,
        textShadow: textShadow,
        textTransform: uppercase ? 'uppercase' : 'inherit'
        // wordBreak: 'break-all',
      }}
    >
      {children}
    </span>
  )
}

SingleText.propTypes = {
  width: PropTypes.any,
  children: PropTypes.any,
  size: PropTypes.number,
  opacity: PropTypes.number,
  color: PropTypes.string,
  family: PropTypes.string,
  weight: PropTypes.any,
  stretch: PropTypes.string,
  fontStyle: PropTypes.string,
  spacing: PropTypes.string,
  lineHeight: PropTypes.any,
  align: PropTypes.string,
  marginBottom: PropTypes.any,
  marginLeft: PropTypes.any,
  marginRight: PropTypes.any,
  cursor: PropTypes.bool,
};
