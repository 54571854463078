/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useRef } from 'react';
import query from 'query-string';
import Theme from 'containers/Theme';
import { FullCard, FullCardPt, FullCardEs, BigWheelJackpotContainer } from 'assets/images';
import ThemeVisibility from 'components/ThemeVisibility';
import SingleText from 'components/SingleText';
import { DataContext } from 'contexts/DataContextContainer';
import { ThemeContext } from 'contexts/ThemeContext';
import { useHistory } from 'react-router-dom';
import Confetti from 'react-dom-confetti';
import JackPot from './components/JackPot';
import Coin from 'components/Coin';
import Instruction from './components/Instruction';
import GuideLeft from './components/GuideLeft';
import GuideRight from './components/GuideRight';
import HeaderMenu from 'components/HeaderMenu';
import LineWinAnimation from 'components/LineWinAnimation';
import ErrorModal from 'components/ErrorModal';
import SideBet from 'components/SideBet';
import PrizeExplode from 'components/PrizeExplode';
import FreeGameAnimation from 'components/FreeGameAnimation';
import { getPlayerBalance } from 'utils/currency-formatter';
import { useTranslation } from 'react-i18next';
import CommonHeader from './CommonHeader';
import BaseballHeader from './BaseballHeader';

function GameLayout({ children, styleModule, themeSounds, controller }) {
  const { t, i18n } = useTranslation();
  const {
    GameLayoutContainer,
    StrikeAnimationContainer,
    LineWinAnimationContainer,
    JackpotContainer,
    BottomContents,
    SideBetContainer,
    JackpotText,
  } = styleModule;
  const history = useHistory();
  const {
    bonus,
    bet,
    freeCnt,
    freeGameAwarded,
    rollCntRef,
    showFreeCelebration,
    isInstruction,
    showPayTable,
    showGuide,
    show777,
    isPlayingMusic,
    isPlayingSound,
    showLineWinAnimation,
    gameEngineState,
    error,
    currencyFormat,
    sideBetWin,
    setSideBetWin,
    isGameOver,
    isRolling,
    activeBtn,
    waitingForRoll,
    isSideBetLoadingRef,
    isPicking,
    skippingRef,
    sideBetsLt,
    sideBetsGt,
    sideBet,
    rollRef,
    acceptDeclineRollRef,
    acceptedSideBetRef,
    setSideBet,
    rollAllRef,
    sideBetsEnabled,
    setSideBetsEnabled,
    rollButtonEnabled,
    setRollButtonEnabled,
    showSideBetWin,
    showSideBet,
    isAuto,
  } = useContext(DataContext);
  const theme = useContext(ThemeContext);
  const next = query.parse(history.location.search).next;
  const showWin = next || history.location.pathname.indexOf('/rolling') !== -1;
  const showSideBets = history.location.pathname.indexOf('/bet') === -1;
  const showOuts = history.location.pathname.indexOf('/bet') !== -1;
  const { play6to7WinSound, playSoundBackground, stopSoundBackground } =
    themeSounds.useSound(isPlayingSound, isPlayingMusic);
  const elevateJackpotWrapper = rollCntRef.current === 15 && !freeGameAwarded;

  const playerBalance = getPlayerBalance(gameEngineState);
  const mounted = useRef();

  const resetSidebetWin = () => {
    setSideBetWin({
      prize: null,
      type: null,
      label: null,
    });
  };

  const onEndCountUp = () => {};

  const handleSideBetClicked = (newSidebet) => {
    setSideBetsEnabled(true);
    setRollButtonEnabled(true);
    clearTimeout(rollRef.current);
    if (sideBet && sideBet.id === newSidebet.id) {
      setSideBet(undefined);
    } else if (
      acceptedSideBetRef.current === undefined &&
      sideBet === undefined
    ) {
      clearTimeout(acceptDeclineRollRef.current);
      setSideBet(newSidebet);
    }
  };

  useEffect(() => {
    if (showLineWinAnimation) {
      controller.handleStartWinAnimation();
      stopSoundBackground();
      !isPlayingMusic && play6to7WinSound();
    } else {
      stopSoundBackground();
      isPlayingMusic ? playSoundBackground() : stopSoundBackground();
    }
  }, [showLineWinAnimation, isPlayingMusic]);

  useEffect(() => {
    mounted.current = true;
    window.initStarAnimation();
    window.initTripleSevenAnimation();
    const alreadyDisplayed = localStorage.getItem(
      'instrusctionsAlreadyDisplayed'
    );

    if (alreadyDisplayed === null) {
      localStorage.setItem('instrusctionsAlreadyDisplayed', 'no');
    }

    controller.enableSideBets({ setSideBetsEnabled });

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.scaleScreen();
    }, 500);
  });

  let gameSessionId = '';
  if (gameEngineState.gameSession) {
    const gs = gameEngineState.gameSession;
    gameSessionId = `${gs.gameSessionId}-${gs.round}-${gs.gameRound}`;

    if (gs?.gameConfig?.testDeck !== null) {
      gameSessionId += ' (TEST DECK)';
    }
  }

  const handleFullCard = (lang) => {
    switch (lang) {
      case 'pt':
      case 'pt-BR':
      case 'pt-br':
        return FullCardPt;
      case 'es':
        return FullCardEs;
      default:
        return FullCard;
    }
  };

  const getSidebetLabel = (con) => {
    if (con === 'lt') {
      return theme === 'dice'
        ? 'common.nextUnderSeven'
        : 'common.nextUnderSevenCricket';
    } else {
      return theme === 'dice'
        ? 'common.nextOverSeven'
        : 'common.nextOverSevenCricket';
    }
  };

  const showSideBetTheme = () => {
    if (theme === 'dice' || theme === 'bigwheel') {
      return showSideBets && showSideBet;
    }
    return showSideBets && sideBetsEnabled;
  };

  return (
    <GameLayoutContainer
      elevateJackpotWrapper={elevateJackpotWrapper}
      className="layout-wrapper"
    >
      <div className="guide-left-payout">{showGuide && <GuideRight />}</div>
      <div className="guide-right-payout">
        <GuideLeft visible={showPayTable} />
      </div>
      <div
        className={`rolling-container ${history.location.pathname.replace(
          '/',
          ''
        )}`}
      >
        {isInstruction && <Instruction />}
        <StrikeAnimationContainer id="strike-animation" show777={show777} />
        <div className="rolling-header">
          <div className="game-id cursor">
            <SingleText size={15} weight={500} marginBottom={10}>
              {t('common.gameNumber')} {gameSessionId}
            </SingleText>
          </div>
          <HeaderMenu />
          <ThemeVisibility themes={['dice', 'cricket', 'bigwheel']}>
            <CommonHeader
              bet={bet}
              currencyFormat={currencyFormat}
              playerBalance={playerBalance}
            />
          </ThemeVisibility>
          <ThemeVisibility themes={['baseball']}>
            <BaseballHeader
              bet={bet}
              currencyFormat={currencyFormat}
              playerBalance={playerBalance}
            />
          </ThemeVisibility>
        </div>
        <div className="jackpot-wrapper">
          <div className="free-celebration-wrapper">
            <Confetti
              active={showFreeCelebration}
              config={{
                angle: '90',
                spread: '52',
                startVelocity: '13',
                elementCount: 70,
                dragFriction: 0.12,
                duration: '2010',
                stagger: 3,
                width: '10px',
                height: '10px',
                perspective: '633px',
                colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
              }}
            />
          </div>
          {freeCnt > 0 && <FreeGameAnimation />}
          <ThemeVisibility themes={['dice']}>
            <JackpotContainer>
              <img
                className={`full-card full-card-${i18n.language}`}
                src={handleFullCard(i18n.language)}
                alt="Full Card"
              />
              <JackPot value={1000000} />
            </JackpotContainer>
          </ThemeVisibility>
          <ThemeVisibility themes={['bigwheel']}>
            <JackpotContainer>
              <img
                className="full-card"
                src={BigWheelJackpotContainer}
                alt="Jackpot Container"
              />
              <JackPot value={1000000} />
              <JackpotText>{t('common.jackpot')}</JackpotText>
            </JackpotContainer>
          </ThemeVisibility>
          <ThemeVisibility themes={['cricket', 'baseball']}>
            {showOuts && (
              <JackpotContainer>
                <img
                  className="full-card"
                  src={handleFullCard(i18n.language)}
                  alt="Full Card"
                />
                <JackPot value={1000000} />
              </JackpotContainer>
            )}
          </ThemeVisibility>
        </div>
        <ThemeVisibility themes={['dice', 'bigwheel']}>
          {showWin && <Coin onEnd={onEndCountUp} />}
        </ThemeVisibility>
        {children}
        {showSideBetTheme() && (
          <BottomContents className="bottom-contents-sidebet">
            <SideBetContainer>
              {!isAuto &&
              rollButtonEnabled &&
              sideBetWin.type === 'lt' &&
              sideBetWin.prize !== null ? (
                <PrizeExplode
                  onFinish={resetSidebetWin}
                  type={'lt'}
                  prize={sideBetWin.prize}
                />
              ) : null}
              {!isGameOver && (
                <SideBet
                  showSideBetWin={showSideBetWin}
                  sideBetWin={sideBetWin}
                  isRolling={isRolling || activeBtn}
                  activeBtn={activeBtn}
                  waitingForRoll={waitingForRoll.current}
                  onSelect={handleSideBetClicked}
                  onLoadingChanged={(val) => {
                    isSideBetLoadingRef.current = val;
                    if (
                      !isPicking.current &&
                      !val &&
                      !waitingForRoll.current &&
                      !skippingRef.current
                    ) {
                      if (!rollButtonEnabled) {
                        setSideBetsEnabled(false);
                      } else {
                        rollAllRef.current(true);
                      }
                    }
                  }}
                  betList={sideBetsLt}
                  sideBet={sideBet}
                  value={bet}
                  label={t(getSidebetLabel('lt'))}
                  controller={controller}
                />
              )}
            </SideBetContainer>
            <SideBetContainer>
              {!isAuto &&
              rollButtonEnabled &&
              sideBetWin.type === 'gt' &&
              sideBetWin.prize !== null ? (
                <PrizeExplode
                  onFinish={resetSidebetWin}
                  type={'gt'}
                  prize={sideBetWin.prize}
                />
              ) : null}
              {!isGameOver && (
                <SideBet
                  showSideBetWin={showSideBetWin}
                  sideBetWin={sideBetWin}
                  isRolling={isRolling || activeBtn}
                  activeBtn={activeBtn}
                  waitingForRoll={waitingForRoll.current}
                  onSelect={handleSideBetClicked}
                  onLoadingChanged={(val) => {}}
                  betList={sideBetsGt}
                  sideBet={sideBet}
                  value={bet}
                  label={t(getSidebetLabel('gt'))}
                  controller={controller}
                />
              )}
            </SideBetContainer>
          </BottomContents>
        )}
      </div>
      {showLineWinAnimation ? (
        <LineWinAnimation type={showLineWinAnimation} win={bonus} />
      ) : (
        ''
      )}
      {showLineWinAnimation ? <div className="star-explosion-overlay" /> : ''}
      <LineWinAnimationContainer
        show={showLineWinAnimation}
        id="star-explosion-container"
      />
      {error !== '' && <ErrorModal message={error} />}
    </GameLayoutContainer>
  );
}

export default Theme(GameLayout, 'layouts');
