

import React, { useContext, useEffect, useRef, useState } from 'react';
import Theme from 'containers/Theme';
import { DataContext } from 'contexts/DataContextContainer';
import { numbersTemp, cricketNumbersTemp } from "service";

import {
  BigWheelWheel1,
  BigWheelWheel2,
  BigWheelWheel3,
  BigWheelGremlinBlueIdle,
  BigWheelGremlinBlueLaunch,
  BigWheelGremlinPinkIdle,
  BigWheelGremlinYellowIdle,
  BigWheelGremlinPinkLaunch,
  BigWheelGremlinYellowLaunch,
  BigWheelLaunchSmoke,
  BigWheelLandingSmoke,
} from 'assets/images';

const BigWheelNumberReveal = ({ styleModule }) => {
  const {
    gameEvents,
    setStreakStrike,
    setHoleTimes,
    streakStrikeRef,
    holeTimesRef,
    audioRef,
    isTurbo
  } = useContext(DataContext);
  const [rolledNum, setRolledNum] = useState(null);
  const rolledNumRef = useRef();

  const {
    Wrapper,
    Gremlin,
    GremlinConditional,
    GremlinJumper,
    GremlinJumper2,
    GremlinJumper3,
    Smoke,
    LandingSmoke,
  } = styleModule;
  const pitchAnimationDone = useRef(false);
  const wheelRef = useRef()
  const smokeRef = useRef()
  const landingSmokeRef = useRef()
  const idleGremlinRef = useRef()
  const idleGremlinRef2 = useRef()
  const idleGremlinRef3 = useRef()
  const jumperRef = useRef()
  const jumperRef2 = useRef()
  const jumperRef3 = useRef()
  const turboRef = useRef(false);

  const [showNumber, setShowNumber] = useState(false)


  const onApiDone = (number) => {
    setShowNumber(false)
    if (number === 7) {
      setStreakStrike(streakStrikeRef.current + 1);
			setHoleTimes(holeTimesRef.current + 1);
    } else {
      setStreakStrike(0);
    }

    pitchAnimationDone.current = false;
    let wheelClass = 'rotateGremlin';

    switch (number) {
      case 2:
        wheelClass = "rotate1"
        break;
      case 3:
        wheelClass = "rotate29"
        break;
      case 4:
        wheelClass = "rotate33"
        break;
      case 5:
        wheelClass = "rotate49"
        break;
      case 6:
        wheelClass = "rotate55"
        break;
      case 7:
        wheelClass = "rotateGremlin"
        break;
      case 8:
        wheelClass = "rotate50"
        break;
      case 9:
        wheelClass = "rotate44"
        break;
      case 10:
        wheelClass = "rotate30"
        break;
      case 11:
        wheelClass = "rotate22"
        break;
      case 12:
        wheelClass = "rotate10"
        break;
      default:
        wheelClass = "rotateGremlin"
        break;
    }

    if(!turboRef.current) {
      audioRef.current.playWheelSpin();
      wheelRef.current.className = "wheel rotateInfinite";
    }

    setTimeout(() => {
      rolledNumRef.current = number;
      setRolledNum(number);
      setTimeout(() => {
        setShowNumber(true);
      }, turboRef.current ? 0 : 3000);

      wheelRef.current.className = `wheel ${wheelClass}`;

      if (holeTimesRef.current > 1) {
        setTimeout(() => {
          animateJump();
        }, turboRef.current ? 0 : 3500);
      }

      setTimeout(() => {
        gameEvents.emit('pitch-animation-done');
      }, turboRef.current ? 0 : 2000);
    }, turboRef.current ? 0 : 500);
  }

  const getJumper = () => {
    switch (holeTimesRef.current) {
      case 2:
        return jumperRef2;
      case 3:
        return jumperRef3;
      default:
        return jumperRef;
    }
  }

  const setSmokeClass = () => {
    const currentClass = landingSmokeRef.current.className.replace('position', '');
    landingSmokeRef.current.className = `${currentClass} position${holeTimesRef.current}`;
  }

  const hidePreviousGremlin = () => {
    let gremlinToHide = idleGremlinRef;
    let gremlinToShow = idleGremlinRef2;

    switch (holeTimesRef.current) {
      case 1:
        gremlinToHide = idleGremlinRef;
        gremlinToShow = idleGremlinRef2;
        break;
      case 2:
        gremlinToHide = idleGremlinRef2;
        gremlinToShow = idleGremlinRef3;
        break;
      case 3:
        gremlinToHide = idleGremlinRef3;
        gremlinToShow = null;
        break;
    }

    gremlinToHide.current.style.visibility = 'hidden';
    if (gremlinToShow) {
      setTimeout(() => {
        gremlinToShow.current.style.visibility = 'visible';
      }, 1000);
    }
  }

  const animateJump = () => {
    audioRef.current.stopWheelSpin();
    if (rolledNumRef.current === 7) {
      smokeRef.current.style.visibility = 'visible';
      idleGremlinRef.current.style.visibility = 'hidden';
      const jumper = getJumper();
      jumper.current.style.display = 'block';
      audioRef.current.playGremlinLaunch();
      hidePreviousGremlin();
      setTimeout(() => {
        gameEvents.emit('gremlin-strike-anim-done');
        smokeRef.current.style.visibility = 'hidden';
        jumper.current.style.display = 'none';
        setSmokeClass();
        landingSmokeRef.current.style.visibility = 'visible';

        setTimeout(() => {
          if (landingSmokeRef.current) {
            landingSmokeRef.current.style.visibility = 'hidden';
          }
        }, 1000);
      }, 1000);
    }
  }

  const handleStartRoll = () => {
    setShowNumber(false);
  }

  const handleRollButtonClicked = ({ auto }) => {
    if (auto !== false) {
      audioRef.current.playSpinClick();
    }
  }

  const handleGameOver = () => {
    setTimeout(() => {
      if (!idleGremlinRef.current) {
        return;
      }

      idleGremlinRef.current.style.visibility = 'visible';
      idleGremlinRef2.current.style.visibility = 'hidden';
      idleGremlinRef3.current.style.visibility = 'hidden';
      jumperRef.current.style.display = 'none';
      jumperRef2.current.style.display = 'none';
      jumperRef3.current.style.display = 'none';
    }, 3000);
  }

  const getWheel = () => {
    switch (holeTimesRef.current) {
      case 1:
        return BigWheelWheel2;
      case 3:
        return BigWheelWheel1;
      case 2:
        return BigWheelWheel3;
      default:
        return BigWheelWheel2;
    }
  }

	useEffect(() => {
		turboRef.current = isTurbo;
	}, [isTurbo]);

  useEffect(() => {
    gameEvents.on('roll-api-done', onApiDone);
    gameEvents.on('pick-api-call', animateJump);
    gameEvents.on('start-roll', handleStartRoll);
    gameEvents.on('roll-button-clicked', handleRollButtonClicked);
    gameEvents.on('game-over', handleGameOver);

    return () => {
      gameEvents.off('roll-api-done', onApiDone);
      gameEvents.off('pick-api-call', animateJump);
      gameEvents.off('start-roll', handleStartRoll);
      gameEvents.off('roll-button-clicked', handleRollButtonClicked);
      gameEvents.off('game-over', handleGameOver);
    };
  }, []);

  const getCricketNumber = (num) => {
    const index = numbersTemp.findIndex(n => n === num)
    if (index !== -1) {
      return cricketNumbersTemp[index];
    }

    return num;
  }

  return (
    <Wrapper className="cricket-number-reveal" key="cricket-number-reveal" isTurbo={turboRef.current}>
      <div className="bigwheel-container">
        <div className="bigwheel-circle"></div>
        <div className="bigwheel-light"></div>
        <div ref={wheelRef} className="wheel">
          <img className="wheel-img" src={getWheel()} />
          <Gremlin ref={idleGremlinRef} src={BigWheelGremlinBlueIdle} />
          <GremlinConditional ref={idleGremlinRef2} src={BigWheelGremlinYellowIdle} />
          <GremlinConditional ref={idleGremlinRef3} src={BigWheelGremlinPinkIdle} />
          <GremlinJumper className="position1" ref={jumperRef} src={BigWheelGremlinBlueLaunch} />
          <GremlinJumper2 className="position2" ref={jumperRef2} src={BigWheelGremlinYellowLaunch} />
          <GremlinJumper3 className="position3" ref={jumperRef3} src={BigWheelGremlinPinkLaunch} />
          <Smoke ref={smokeRef} src={BigWheelLaunchSmoke} />
        </div>

        <LandingSmoke className="position1" ref={landingSmokeRef} src={BigWheelLandingSmoke} />
      </div>
      {showNumber && (
          <div className={showNumber ? 'number number-show' : 'number' }>{getCricketNumber(rolledNum) == 7 ? 'X' : getCricketNumber(rolledNum) }</div>
      )}
    </Wrapper>
  );
}

export default Theme(React.memo(BigWheelNumberReveal), 'components/BigWheelNumberReveal');