import React, { useEffect } from "react";
import Particles from "react-tsparticles";
import Theme from 'containers/Theme';
import { useTranslation } from "react-i18next";

const BtnGotIt = (props) => {
  const { t } = useTranslation();
  const {
    className = "",
    onClick = () => {},
    styleModule,
  } = props;

  useEffect(() => {
    const canvas = document.querySelector('canvas.d-canvas');

    if (canvas) {
      canvas.style.position = 'absolute';
    }
  })

  return (
    <styleModule.BtnGotItContainer className={className} onClick={onClick}>
      <div id="blockAnim">
        <div id="textOverlay">{t('common.gotIt')}</div>
        <Particles
          className="d"
          canvasClassName="d-canvas"
          params={{
            particles: {
              number: {
                value: 355,
                density: {
                  enable: true,
                  value_area: 789.1476416322727,
                },
              },
              color: {
                value: "#ffffff",
              },
              shape: {
                type: "circle",
                stroke: {
                  width: 0,
                  color: "#000000",
                },
                polygon: {
                  nb_sides: 5,
                },
                image: {
                  src: "img/github.svg",
                  width: 100,
                  height: 100,
                },
              },
              opacity: {
                value: 0.7,
                random: false,
                anim: {
                  enable: true,
                  speed: 0.2,
                  opacity_min: 0.4,
                  sync: false,
                },
              },
              size: {
                value: 6,
                random: true,
                anim: {
                  enable: true,
                  speed: 2,
                  size_min: 2,
                  sync: false,
                },
              },
              line_linked: {
                enable: false,
                distance: 150,
                color: "#ffffff",
                opacity: 0.4,
                width: 1,
              },
              move: {
                enable: true,
                speed: 7,
                direction: "top",
                random: true,
                straight: true,
                out_mode: "out",
                bounce: false,
                attract: {
                  enable: false,
                  rotateX: 600,
                  rotateY: 1200,
                },
              },
            },
            interactivity: {
              detect_on: "canvas",
              events: {
                onhover: {
                  enable: true,
                  mode: "bubble",
                },
                onclick: {
                  enable: true,
                  mode: "push",
                },
                resize: true,
              },
              modes: {
                grab: {
                  distance: 400,
                  line_linked: {
                    opacity: 1,
                  },
                },
                bubble: {
                  distance: 83.91608391608392,
                  size: 1,
                  duration: 3,
                  opacity: 1,
                  speed: 5,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
                push: {
                  particles_nb: 4,
                },
                remove: {
                  particles_nb: 2,
                },
              },
            },
            retina_detect: true,
          }}
        />
      </div>
    </styleModule.BtnGotItContainer>
  );
}

export default Theme(BtnGotIt, 'components/BtnGotIt');