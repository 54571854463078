import React from 'react'
import PropTypes from 'prop-types';
import Theme from 'containers/Theme';

const Box = ({
  children, 
  className, 
  framer, 
  onClick = () => {},
  styleModule,
  themeSounds,
  controller,
  ...otherProps
}) => {
  return (
    <styleModule.BoxContainer 
      className={className} 
      {...framer} 
      style={{...otherProps}}
      onClick={onClick}
    >
      {children}
    </styleModule.BoxContainer>
  )
}

Box.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  framer: PropTypes.object,
  onClick: PropTypes.func,
};

export default Theme(Box, 'components/Box');