import { TILE_STATES } from "constants/tiles";
import { statusColors } from "service";

export const mapGameEngineBoard = (board) => {
	let statuses = Array.from({ length: 25 }, () => 0);

	board.tiles.forEach((item) => {
		const y = (item.y - 1) * 5;
		const index = (item.x - 1) + y;

		switch (item.state) {
			case TILE_STATES.PICKED:
				statuses[index] = statusColors.blue;
				break;
			case TILE_STATES.UNPICKED:
				statuses[index] = statusColors.white;
				break;
			case TILE_STATES.WIN:
				statuses[index] = statusColors.green;
				break;
			case TILE_STATES.SELECTABLE:
				statuses[index] = statusColors.orange;
				break;
			default:
				break;
		}
	})

	return statuses;
};

export const getBoardCoordinate = (index) => {
	let result = {
		x: 0,
		y: 0,
	};

	if (index % 5 === 0) {
		result.y = Math.floor(index / 5);
	} else {
		result.y = Math.floor(index / 5) + 1;
	}

	result.x = index - (Math.floor(index / 5) * 5);
	result.x = result.x ? result.x : 5;

	return result;
}