import React, { useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid';
import { getSideBetsForGroup } from "utils/side-bets";
import { EventEmitter } from 'events';
import moment from "moment";
import { ThemeContext } from "contexts/ThemeContext";

const DataContext = React.createContext(null);
const playMusicByDefaultThemes = ['cricket', 'baseball', 'bigwheel'];

export { DataContext };

export const initialBalance = 1000;

const DataContextContainer = (props) => {
  const gameEventsRef = useRef(new EventEmitter());
  window.gameEvents = gameEventsRef.current;
  const autoRef = useRef(null);
  const timeoutRef = useRef(null);
  const [balance, setBalance] = useState(100);
  const [bet, setBet] = useState(0.5);
  const [bonus, setBonus] = useState(0);
  const [roundBonus, setRoundBonus] = useState(bet);
  const [earn, setEarn] = useState(null);
  const [jackpot, setJackpot] = useState(100000);
  const [startedRound, hookSetStartedRound] = useState(false);
  const startedRoundRef = useRef(false);
  const theme = useContext(ThemeContext);
  const audioRef = useRef();
  const [isTurbo, setIsTurbo] = useState(true);

  const setStartedRound = (val) => {
    startedRoundRef.current = val;
    hookSetStartedRound(val);
  }
  const [isGameOver, setIsGameOverVal] = useState(false)
  const gameOverRef = useRef(false);
  const setIsGameOver = (val) => {
    if (val) {
      gameEventsRef.current.emit('game-over');
    }

    gameOverRef.current = val;
    setIsGameOverVal(val);
  }
  const [winnedLines, setWinnedLines] = useState(5)
  const [startGame, setStartGame] = useState(false);
  const [isAuto, setIsAutoValue] = useState(false);
  const isAutoRef = useRef(false);

  const setIsAuto = (val) => {
    isAutoRef.current = val;
    setIsAutoValue(val);
  }

  const [curIndex, setCurIndex] = useState(1);
  const [holeTimes, setHoleTimesValue] = useState(0);
  const holeTimesRef = useRef(0);
  const setHoleTimes = (val) => {
    holeTimesRef.current = val;
    setHoleTimesValue(val);
  };

  const [bonuses, setBonuses] = useState(Array.from({ length: 15 }, () => null));
  const [streakBonuses, setStreakBonuses] = useState(Array.from({ length: 25 }, () => null));
  const [activeBtn, showRollButtonHook] = useState(true);
  const showRollButton = (val) => {
    if (val) {
      setShowSideBetWin(null);
    }

    showRollButtonHook(val);
  }

  const [skipping, setSkipping] = useState(false);
  const [skippingCount, setSkippingCount] = useState(0);

  const [filled, setFilled] = useState(false);

  const [pochingoPay, setPochingoPay] = useState(100000000);
  const [isFirstTimePlay, setIsFirstTimePlay] = useState(true);
  const [isInstruction, setIsInstruction] = useState(false);
  const [showPochingoStars, setShowPochingoStars] = useState(false);

  const [showPayTable, setShowPayTable] = useState(false);
  const [showGuide, setShowGuide] = useState(false);
  const [instructionStage, setInstructionStage] = useState(1);
  const [freeCnt, setFreeCnt] = useState(0);
  const [showFreeCelebration, setShowFreeCelebration] = useState(false);

  const [sessionId] = useState(uuidv4())
  const [playSound, setPlaySound] = useState(true);

  const [isPlayingMusic, setIsPlayingMusic] = useState(playMusicByDefaultThemes.indexOf(theme) !== -1 ? true : false );
  const [isPlayingSound, setIsPlayingSound] = useState(true);
  const [streakStrike, setStreakStrikeVal] = useState(0);
  const streakStrikeRef = useRef();
  const setStreakStrike = (val) => {
    streakStrikeRef.current = val;
    setStreakStrikeVal(val);
  }
  const [show777, setShow777] = useState(false);
  const [showLineWinAnimation, setShowLineWinAnimation] = useState(false);
  const [statuses, setStatusesValue] = useState([]);
  const statusesRef = useRef([]);
  const setStatuses = (val) => {
    if (typeof val === 'function') {
      statusesRef.current = val();;
      setStatusesValue(val);
    } else {
      statusesRef.current = val;
      setStatusesValue(val);
    }
  }

  const fromBetScreen = useRef(false);
  const secondStrikeRef = useRef(null);
  const diceFaceInterval = useRef(null);
  const isPlayingSoundRef = useRef();
  const gameOverTimeoutRef = useRef();
  const [newWinGroupKeys, setNewWinGroupKeys] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const rolledNumRef = useRef(null);
  const [rolledNum, setRolledNumVal] = useState();

  const setRolledNum = (val) => {
    rolledNumRef.current = val;
    setRolledNumVal(val);
  }

  const [showAnimationStrike, setShowAnimationStrike] = useState(false);
  const [startedFreeGame, setStartedFreeGame] = useState(false);
  const [showWinAnimation, setShowWinAnimation] = useState(false);
  const [showGameOverBanner, setShowGameOverBanner] = useState(false);
  const [lastMoment, setLastMoment] = useState(moment());
  const [freeGameAvailable, setFreeGameAvailable] = useState(true);
  const [freeGameAwarded, setFreeGameAwardedVal] = useState(false);
  const freeGameAwardedRef = useRef(null);
  const setFreeGameAwarded = (val) => {
    freeGameAwardedRef.current = val;
    setFreeGameAwardedVal(val);
  }

  const [gameEngineState, setGameEngineStateData] = useState({});
  const gameEngineStateRef = useRef(gameEngineState);
  const setGameEngineState = (val) => {
    gameEngineStateRef.current = val;
    setGameEngineStateData(val);
  }
  const [rollCnt, setRollCntAcual] = useState(0);
  const rollCntRef = useRef(0);
  const setRollCnt = (val) => {
    rollCntRef.current = val;
    setRollCntAcual(val);
  }

  const [displayOuts, setDisplayOuts] = useState(false)
  const [error, setError] = useState('');
  const [isRolling, setIsRollingVal] = useState(false);
  const isRollingRef = useRef(false);
  const setIsRolling = (val) => {
    isRollingRef.current = val
    setIsRollingVal(val);
  };
  const waitingForRoll = useRef(false);
  const isPicking = useRef(false);
  const skippingRef = useRef(false);
  const isSideBetLoadingRef = useRef(false);
  const [sideBet, setSideBet] = useState(undefined);
  const [acceptedSideBet, setAcceptedSideBet] = useState(undefined);
  const [sideBetWin, setSideBetWin] = useState({
    type: null,
    prize: null,
    label: null,
  });
  const rollRef = useRef(undefined);
  const acceptedSideBetRef = useRef(undefined);
  const acceptDeclineRollRef = useRef(undefined);
  const rollAllRef = useRef(() => {});
  const [sideBetsEnabled, setSideBetsEnabledValue] = useState(false);
  const setSideBetsEnabled = (val) => {
    if (!val) {
      isSideBetLoadingRef.current = false;
    }
    setSideBetsEnabledValue(val);
  }

  const [showSideBet, setShowSideBet] = useState(true);

  const [sideBetsIntro, setSideBetsIntro] = useState(true)

  const [showSideBetPopup, setShowSideBetPopup] = useState(false)

  const [rollButtonEnabled, setRollButtonEnabled] = useState(false);
  const [showSideBetWin, setShowSideBetWin] = useState(null);

  const [menuOpen, setMenuOpen] = useState(false);

  let currencyFormat = {
    thousandSep: ',',
    symbol: '$',
    centsSym: '¢',
    decimalPrec: 2,
    sep: '.',
  }

  if (gameEngineState && gameEngineState.gameSession) {
    currencyFormat = {
      ...gameEngineState.gameSession.gameConfig.currentFormats[gameEngineState.gameSession.gameConfig.gameCurrency]
    }
  }

  let sideBetsLt = [];
  let sideBetsGt = [];

  if (gameEngineState.gameSession && gameEngineState.gameSession.gameConfig) {
    sideBetsLt = getSideBetsForGroup('side-bet.under', gameEngineState.gameSession.gameConfig.sideBets);
    sideBetsGt = getSideBetsForGroup('side-bet.over', gameEngineState.gameSession.gameConfig.sideBets);
  }

  return(
    <DataContext.Provider
      value={{
        audioRef,
        autoRef,
        timeoutRef,
        balance: parseFloat(balance), setBalance,
        bet, setBet,
        bonus, setBonus,
        pochingoPay, setPochingoPay,
        roundBonus, setRoundBonus,
        earn, setEarn,
        jackpot, setJackpot,
        startedRound, setStartedRound, startedRoundRef,
        startGame, setStartGame,
        isGameOver, setIsGameOver, gameOverRef,
        winnedLines, setWinnedLines,
        curIndex, setCurIndex,
        holeTimes, setHoleTimes,
        holeTimesRef,
        bonuses, setBonuses,
        streakBonuses, setStreakBonuses,
        activeBtn, showRollButton,
        skipping, setSkipping,
        skippingCount, setSkippingCount,
        filled, setFilled,
        isInstruction, setIsInstruction,
        isFirstTimePlay, setIsFirstTimePlay,
        showPochingoStars, setShowPochingoStars,
        showPayTable, setShowPayTable,
        showGuide, setShowGuide,
        sessionId,
        instructionStage, setInstructionStage,
        freeCnt, setFreeCnt,
        showFreeCelebration, setShowFreeCelebration,
        playSound, setPlaySound,
        isPlayingMusic, setIsPlayingMusic,
        isPlayingSound, setIsPlayingSound,
        streakStrike, setStreakStrike, streakStrikeRef,
        show777, setShow777,
        isAuto, setIsAuto,
        isAutoRef,
        showLineWinAnimation, setShowLineWinAnimation,
        statuses, setStatuses,
        statusesRef,
        rollCnt, setRollCnt, rollCntRef,
        gameEngineState, setGameEngineState,
        error, setError,
        currencyFormat,
        isRolling, setIsRolling, isRollingRef,
        waitingForRoll,
        isPicking,
        skippingRef,
        sideBetsLt,
        sideBetsGt,
        isSideBetLoadingRef,
        sideBet, setSideBet,
        acceptedSideBet,
        setAcceptedSideBet,
        sideBetWin,
        setSideBetWin,
        rollRef,
        acceptedSideBetRef,
        acceptDeclineRollRef,
        rollAllRef,
        sideBetsEnabled, setSideBetsEnabled,
        rollButtonEnabled, setRollButtonEnabled,
        showSideBetWin, setShowSideBetWin,
        gameEvents: gameEventsRef.current,
        secondStrikeRef,
        diceFaceInterval,
        isPlayingSoundRef,
        gameOverTimeoutRef,
        gameEngineStateRef,
        newWinGroupKeys, setNewWinGroupKeys,
        showConfetti, setShowConfetti,
        rolledNumRef,
        rolledNum, setRolledNumVal,
        setRolledNum,
        showAnimationStrike, setShowAnimationStrike,
        startedFreeGame, setStartedFreeGame,
        showWinAnimation, setShowWinAnimation,
        showGameOverBanner, setShowGameOverBanner,
        lastMoment, setLastMoment,
        freeGameAvailable, setFreeGameAvailable,
        freeGameAwarded, setFreeGameAwarded, freeGameAwardedRef,
        fromBetScreen,
        showSideBetPopup,
        setShowSideBetPopup,
        displayOuts, setDisplayOuts,
        sideBetsIntro, setSideBetsIntro,
        showSideBet, setShowSideBet,
        menuOpen, setMenuOpen,
        isTurbo, setIsTurbo
      }}
    >
      { props.children }
    </DataContext.Provider>
  )
}

DataContextContainer.propTypes = {
  children: PropTypes.node.isRequired,
};


export default DataContextContainer;
