import styled from '@emotion/styled'

export const LogsContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .logs-wrapper {
    flex: 1;
    background: rgba(0, 0, 0, 0.8);
    min-height: 200px;
    .session-container {
      border-bottom: solid 1px rgba(255, 255, 255, 0.4);
      padding: 20px 27px;
      .session-title {
        color: white;
      }
      .session-items-container {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        .session-item {
          margin: 10px;
          max-width: 150px;
          p {
            color: white;
            margin-bottom: 0px;
          }
          .session-item-date {
            color: white;
            margin-top: 10px;
          }
          .session-item-rollCnt {
            color: white;
          }
          .session-item-numbers {
            span {
              margin-right: 5px;
              b {
                color: green;
              }
            }
          }
        }
      }
    }
  }
`;
