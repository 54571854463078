import React from 'react';
import Theme from 'containers/Theme';
import ThemeVisibility from 'components/ThemeVisibility';
import Dice from './dice';
import Bigwheel from './bigwheel';

const FreeGameAnimation = () => {
	return (
		<>
			<ThemeVisibility themes={['dice', 'cricket', 'baseball']}>
				<Dice />
			</ThemeVisibility>
			<ThemeVisibility themes={['bigwheel']}>
				<Bigwheel />
			</ThemeVisibility>
		</>
	)
}

export default Theme(FreeGameAnimation, 'components/FreeGameAnimation');