import React from 'react';
import Theme from 'containers/Theme';
import { FreeStarLeft, FreeStarLeftPt, FreeStarLeftEs } from 'assets/images';
import SingleAntImage from 'components/SingleAntImage';
import { useTranslation } from 'react-i18next';

const FreeStar = ({ styleModule }) => {
  const { i18n } = useTranslation();
  const { Wrapper } = styleModule;
  const freeCnt = 1;

  const handleFreeStarLeft = (lang) => {
    switch (lang) {
      case 'pt':
      case 'pt-BR':
      case 'pt-br':
        return FreeStarLeftPt;
      case 'es':
        return FreeStarLeftEs;
      default:
        return FreeStarLeft;
    }
  };

  return (
    <Wrapper className="free-star">
      <SingleAntImage
        src={handleFreeStarLeft(i18n.language)}
        width={25}
        height="auto"
      />
      <div className="free-left-value">{freeCnt}</div>
    </Wrapper>
  );
};

export default Theme(FreeStar, 'components/FreeStar');
