import { WIN } from 'assets/images';
import { sizes } from 'service';
import { createGlobalStyle } from 'styled-components';
import varsityTeam from "assets/webfonts/VarsityTeam-Bold.otf";
import myriadPro from "assets/webfonts/MYRIADPRO-BOLD.OTF";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Varsity Team Bold';
    src: url(${varsityTeam}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'MyriadPro';
    src: url(${myriadPro}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  body {
    font-family: 'Roboto';
    margin: 0;
    height: 100%;
  }
  #root, .App {
    height: 100%;
  }
  .cursor {
    cursor: pointer;
  }
  .mb-0 {
    margin-bottom: 0px;
  }
  .modal-win {
    .ant-modal {
      width: 400px !important;
      .ant-modal-content {
        background-color: transparent;
        box-shadow: unset;
        .ant-modal-body {
          .win-stars-wrapper {
            width: 100%;
            height: 250px;
            .stars-container {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              z-index: 1;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .win-ray {
              position: absolute;
              top: -100px;
              z-index: 0;
              animation-name: spin;
              animation-duration: 5000ms;
              animation-iteration-count: infinite;
              animation-timing-function: linear;
              @keyframes spin {
                from {
                    transform:rotate(0deg);
                }
                to {
                    transform:rotate(360deg);
                }
              }
            }
            .win-mark {
              /* position: absolute; */
              width: ${sizes.mainWidth - 140}px;
              height: 170px;
              background: url(${WIN});
              background-size: cover;
              top: -88px;
              z-index: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              span {
                filter: drop-shadow(0px 5px 2.5px rgba(255,255,255,0.4));
              }
              .win-money {
                position: relative;
                text-align: center;
                bottom: 32px;
              }
            }
            .option-btns {
              display: flex;
              z-index: 2;
            }
          }
        }
      }
    }
  }
  .modal-first-screen {
    .ant-modal {
      width: 320px !important;
      .ant-modal-content {
        background-color: transparent;
        box-shadow: unset;
        .ant-modal-body {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
  .modal-confirm {
    .ant-modal {
      width: 320px !important;
      .ant-modal-content {
        background-color: rgba(0, 0, 0, 0.7);
        box-shadow: unset;
        border-radius: 10px;
        border: solid dimgray 1px;
        .ant-modal-body {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
  .logs-drawer {
    .ant-drawer-content-wrapper {
      background-color: transparent;
      .ant-drawer-content {
        background-color: transparent;
        .ant-drawer-header {
          background: transparent;
          border-color: transparent;
          .ant-drawer-title {
            color: white;
            font-size: 32px;
            font-weight: 700;
          }
          .ant-drawer-close {
            color: white;
          }
        }
      }
    }
  }
`;

export default GlobalStyle;