import React from 'react';
import ThemeVisibility from 'components/ThemeVisibility';
import Theme from 'containers/Theme';
import Dice from './dice';
import Baseball from './baseball';
import Bigwheel from './Bigwheel';

const LineWinAnimation = ({ type }) => {
	return (
		<>
			<ThemeVisibility themes={['dice', 'cricket']}>
				<Dice type={type} />
			</ThemeVisibility>
			<ThemeVisibility themes={['bigwheel']}>
				<Bigwheel type={type} />
			</ThemeVisibility>
			<ThemeVisibility themes={['baseball']}>
				<Baseball type={type} />
			</ThemeVisibility>
		</>
	)
}

export default Theme(LineWinAnimation, 'components/LineWinAnimation');