/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import SingleText from "components/SingleText";
import {
  numberGroup,
  statusColors,
  numbersTemp,
} from "service";
import { DataContext } from "contexts/DataContextContainer";
import SingleAntImage from "components/SingleAntImage";
import {
  IconCheckNum,
  StarStreak,
} from "assets/images";
import Theme from 'containers/Theme';

const animations = {
  1: "swing",
  2: "heartBeat",
  3: "jello",
  4: "tada",
};

const mapThemeNumbers = (index, numbers) => {
  return numbers[index - 1];
}

const Bigwheel = ({
  order,
  totalPicked,
  status,
  isFreePick = false,
  countRemained,
  newWinGroupKeys = [],
  rolledNum,
  onClick,
  styleModule,
  themeSounds,
  controller,
}) => {
  const numbers = controller.getNumbers();
  const { gameEvents, skipping, isPlayingSound, holeTimesRef } = useContext(DataContext);
  const [date, setDate] = useState(new Date());
  const [_internalHoleTimes, setInternalHoleTimes] = useState(holeTimesRef.current);
  const internalHoleTimesRef = useRef(holeTimesRef.current);
  const setCurrentHoleTimes = (val) => {
    internalHoleTimesRef.current = val;
    setInternalHoleTimes(val);
  }
  const sounds = themeSounds.useSound(isPlayingSound);
  const wasClickedRef = useRef(false);
  let speed = "animate__slower";

  switch (totalPicked) {
    case 23:
      speed = "";
      break;
    case 24:
      speed = "animate__fast";
      break;
    default:
      break;
  }

  let animateClasses = {
    orange: '',
    winGroup: '',
    shakeClass: '',
  }

  if (status === statusColors.white && totalPicked >= 22) {
    animateClasses.shakeClass =  `animate__animated animate__heartBeat animate__infinite ${speed}`;
  }

  if (status == statusColors.orange) {
    animateClasses.orange = `animate__animated animate__${animations[countRemained.toString()]} animate__infinite animate__slow`
  }

  const winGroupHasOrder = newWinGroupKeys.find((_key) =>
    numberGroup[_key].includes(order.toString())
  );

  if (newWinGroupKeys.length > 0 && winGroupHasOrder !== undefined) {
    animateClasses.winGroup = 'animate__animated animate__flash animate__repeat-1';
    animateClasses.orange = '';
  }

  const handleOnClickSeven = ({ order: numberOrder }) => {
    if (numberOrder === 13) {
      gameEvents.off('gremlin-strike-anim-done', handleOnStrikeAnimDone);
      setCurrentHoleTimes(1);
      setDate(new Date());

      setTimeout(() => {
        gameEvents.on('gremlin-strike-anim-done', handleOnStrikeAnimDone);
      }, 3000);
    }
  }

  const handleOnStrikeAnimDone = () => {
    if (internalHoleTimesRef.current >= 1) {
      setCurrentHoleTimes(internalHoleTimesRef.current + 1);
    }
  }

  useEffect(() => {
    if (order === 13) {
      if (holeTimesRef.current > 0) {
        gameEvents.on('gremlin-strike-anim-done', handleOnStrikeAnimDone);
      }

      gameEvents.on('on-click-number', handleOnClickSeven);
    }

		return () => {
      if (order === 13) {
        gameEvents.off('gremlin-strike-anim-done', handleOnStrikeAnimDone);
        gameEvents.off('on-click-number', handleOnClickSeven);
      }
		}
  }, []);

  useEffect(() => {
    const winGroupHasOrder = newWinGroupKeys.find((_key) =>
      numberGroup[_key].includes(order.toString())
    );

    if (winGroupHasOrder !== undefined) {
      let timeout = 0;

      if (wasClickedRef.current) {
        timeout = 1500;
      }

      setTimeout(() => {
        setDate(new Date());
      }, timeout);
    }
  }, [newWinGroupKeys]);

  useEffect(() => {
    if (!wasClickedRef.current && status != 2) {
      return;
    }

    wasClickedRef.current = false;

    setTimeout(() => {
      setDate(new Date());
    }, 1100);
  }, [status]);

  useEffect(() => {
    if (
      holeTimesRef.current === 0 &&
      internalHoleTimesRef.current > 0 &&
      order === 13
    ) {
      setCurrentHoleTimes(0);
      setDate(new Date());
			gameEvents.off('gremlin-strike-anim-done', handleOnStrikeAnimDone);
    }
  }, [holeTimesRef.current]);

  return (
    <styleModule.NumberContainer
      key={`number-container-${order}-${date.getTime()}`}
      className={`number ${order === 13 && "number7"} ${animateClasses.orange} ${animateClasses.winGroup} ${animateClasses.shakeClass}`}
      status={parseInt(status)}
      onClick={() => {
        window.ga_log_user_click('number-tile');
        if (status == statusColors.orange) {
          sounds.playSoundOrangeClick();

          onClick();
          wasClickedRef.current = true;
        }
      }}
    >
      {order === 13 && <img className="umpire" src={controller.getCenterTileImage(internalHoleTimesRef.current)} />}
      <SingleText
        size={53}
        weight={700}
        color={`${status == 0 && order != 13 ? "#242e42" : "white"}`}
      >
        {mapThemeNumbers(order, numbers)}
      </SingleText>
      {rolledNum === numbersTemp[order - 1] && skipping && (
        <SingleAntImage
          className="icon-check-num"
          src={IconCheckNum}
          width="100%"
          height="auto"
        />
      )}
      {isFreePick && status == statusColors.white && (
        <SingleAntImage
          className="icon-free-num"
          src={StarStreak}
          width="100%"
          height="auto"
          onClick={onClick}
        />
      )}
    </styleModule.NumberContainer>
  );
};

export default React.memo(Theme(Bigwheel, 'components/Number'));