import {
  Logo,
  CricketLogo,
  NewGuide1,
  CricketGuide1,
  BaseballGuide1,
  NewGuide2,
  NewGuide4,
  NewGuide5,
  CricketGuide2,
  CricketGuide3,
  CricketGuide4,
  CricketGuide5,
  BaseballGuide2,
  BaseballGuide4,
  BaseballGuide5,
  BaseballLogo,
  BigWheelLogo,
  BigWheelGuide1,
  BigWheelGuide2,
  BigWheelGuide4,
  BigWheelGuide5,
} from "assets/images";
import useSound from "use-sound";
import { soundSetting } from "service";
import { SoundClickButton } from "assets/audio";
import BtnGotIt from "components/BtnGotIt";
import SingleAntImage from "components/SingleAntImage";
import { DataContext } from "contexts/DataContextContainer";
import React, { useContext } from "react";
import GuideLeft from "../GuideLeft";
import { Fade } from "react-awesome-reveal";
import {
  InstructionContainer,
  CloseButton,
  InstructionOne,
  InstructionTwo,
  InstructionThree,
  InstructionFour,
  InstructionFive,
  BoldText,
  BatOnBall,
} from "./style";
import ThemeVisibility from "components/ThemeVisibility";
import { Trans, useTranslation } from "react-i18next";

export default function Instruction({ value }) {
  const { t, i18n } = useTranslation();
  const {
    setIsInstruction,
    instructionStage,
    setInstructionStage: setStage,
    isPlayingSound,
  } = useContext(DataContext);
  const [playSoundClickButton] = useSound(SoundClickButton, soundSetting);

  const setInstructionStage = (stage) => {
    isPlayingSound && playSoundClickButton();
    setStage(stage);
  };

  const handleClose = () => {
    isPlayingSound && playSoundClickButton();
    setInstructionStage(1);
    setIsInstruction(false);
  };

  const displayHitStreak = (str) => {
    if (str.length == 10) {
      return (
         <span class="hit-streak-small">{str}</span>
      )
    } else if (str.length > 10) {
      return <span class="hit-streak-medium">{str}</span>
    }
    return <span>{str}</span>
  }

  const textTranslation = (page) => {
    switch (page) {
      case 1:
        return (
          <InstructionOne className="instruction-one">
            <ThemeVisibility themes={["dice"]}>
              <BoldText>
                &ldquo;<Trans t={t}>instruction.dontRoll</Trans>&rdquo;
              </BoldText>
              <div>
                &ldquo;<Trans t={t}>instruction.itsAStrike</Trans>&rdquo;
              </div>
            </ThemeVisibility>
            <ThemeVisibility themes={["cricket"]}>
              <div>
                &ldquo;<Trans t={t}>instruction.cricket.avoidOuts</Trans>&rdquo;
              </div>
            </ThemeVisibility>
            <ThemeVisibility themes={["baseball"]}>
              <div>
                &ldquo;<Trans t={t}>instruction.baseball.avoidOuts</Trans>
                &rdquo;
              </div>
            </ThemeVisibility>
            <ThemeVisibility themes={["bigwheel"]}>
              <div>
                &ldquo;<Trans t={t}>instruction.bigwheel.avoidOuts</Trans>
                &rdquo;
              </div>
            </ThemeVisibility>
          </InstructionOne>
        );
      case 2:
        return (
          <InstructionTwo>
            <BoldText>
              &ldquo;<Trans t={t}>instruction.chooseWisely</Trans>&rdquo;
            </BoldText>
            <div>
              &ldquo;<Trans t={t}>instruction.anyRowColumn</Trans>&rdquo;
            </div>
          </InstructionTwo>
        );
      case 3:
        return (
          <InstructionThree>
            <BoldText>
              &ldquo;<Trans t={t}>instruction.manyWaysToWin</Trans>&rdquo;
            </BoldText>
            <div>
              &ldquo;<Trans t={t}>instruction.heresThePayTable</Trans>&rdquo;
            </div>
            <ThemeVisibility
              themes={["cricket", "baseball", "dice", "bigwheel"]}
            >
              <div className="guide-screen-left">
                <GuideLeft visible={true} />
              </div>
            </ThemeVisibility>
          </InstructionThree>
        );
      case 4:
        return (
          <>
            <ThemeVisibility themes={["baseball"]}>
              <BatOnBall>
                <Trans t={t}>instruction.baseball.batOnBall</Trans>
              </BatOnBall>
            </ThemeVisibility>
            <InstructionFour>
              <ThemeVisibility themes={["dice"]}>
                <BoldText>
                  &ldquo;<Trans t={t}>instruction.rollAgain</Trans>&rdquo;
                </BoldText>
                <div>
                  &ldquo;<Trans t={t}>instruction.usedUpNumbers</Trans>&rdquo;
                </div>
              </ThemeVisibility>
              <ThemeVisibility themes={["cricket"]}>
                <BoldText>
                  &ldquo;<Trans t={t}>instruction.cricket.rollAgain</Trans>
                  &rdquo;
                </BoldText>
                <div>
                  &ldquo;<Trans t={t}>instruction.cricket.usedUpNumbers</Trans>
                  &rdquo;
                </div>
              </ThemeVisibility>
              <ThemeVisibility themes={["baseball"]}>
                <BoldText>
                  &ldquo;<Trans t={t}>instruction.baseball.rollAgain</Trans>
                  &rdquo;
                </BoldText>
                <div>
                  &ldquo;<Trans t={t}>instruction.baseball.usedUpNumbers</Trans>
                  &rdquo;
                </div>
              </ThemeVisibility>
              <ThemeVisibility themes={["bigwheel"]}>
                <div className="instruction-message">
                  <BoldText>
                    &ldquo;<Trans t={t}>instruction.bigwheel.rollAgain</Trans>
                    &rdquo;
                  </BoldText>
                  <div>
                    &ldquo;
                    <Trans t={t}>instruction.bigwheel.usedUpNumbers</Trans>
                    &rdquo;
                  </div>
                </div>
              </ThemeVisibility>
            </InstructionFour>
          </>
        );
      case 5:
        return (
          <InstructionFive>
            <ThemeVisibility themes={["dice", "baseball"]}>
              <BoldText>
                &ldquo;<Trans t={t}>instruction.fullCardsWinBig</Trans>&rdquo;
              </BoldText>
              <div>
                &ldquo;<Trans t={t}>instruction.theFewerTheStrikes</Trans>
                &rdquo;
              </div>
            </ThemeVisibility>
            <ThemeVisibility themes={["cricket"]}>
              <BoldText>
                &ldquo;<Trans t={t}>instruction.fullCardsWinBig</Trans>&rdquo;
              </BoldText>
              <div>
                &ldquo;<Trans t={t}>instruction.cricket.theFewerTheStrikes</Trans>
                &rdquo;
              </div>
            </ThemeVisibility>
            <ThemeVisibility themes={["bigwheel"]}>
              <BoldText>
                &ldquo;<Trans t={t}>instruction.fullCardsWinBig</Trans>&rdquo;
              </BoldText>
              <div>
                &ldquo;
                <Trans t={t}>instruction.bigwheel.theFewerTheStrikes</Trans>
                &rdquo;
              </div>
            </ThemeVisibility>
          </InstructionFive>
        );
      default:
        return <></>;
    }
  };

  return (
    <InstructionContainer className="ins-container">
      <CloseButton
        onClick={handleClose}
        className="far fa-times-circle"
      ></CloseButton>
      {instructionStage === 1 && (
        <Fade
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="ins-wrapper">
            <ThemeVisibility themes={["dice"]}>
              <SingleAntImage
                src={Logo}
                className="pochingo-logo"
                width="327px"
                height="250px"
              />
            </ThemeVisibility>
            <ThemeVisibility themes={["cricket"]}>
              <SingleAntImage
                src={CricketLogo}
                className="pochingo-logo"
                width="327px"
                height="250px"
              />
            </ThemeVisibility>
            <ThemeVisibility themes={["baseball"]}>
              <SingleAntImage
                src={BaseballLogo}
                className="pochingo-logo"
                width="327px"
                height="250px"
              />
            </ThemeVisibility>
            <ThemeVisibility themes={["bigwheel"]}>
              <SingleAntImage
                src={BigWheelLogo}
                className="pochingo-logo"
                width="327px"
                height="250px"
              />
            </ThemeVisibility>

            {textTranslation(1)}

            <ThemeVisibility themes={["dice"]}>
              <SingleAntImage
                src={NewGuide1}
                className="img-guide1"
                width="100%"
                height="auto"
              />
            </ThemeVisibility>
            <ThemeVisibility themes={["cricket"]}>
              <SingleAntImage
                src={CricketGuide1}
                className="img-guide1"
                width="100%"
                height="auto"
              />
            </ThemeVisibility>
            <ThemeVisibility themes={["baseball"]}>
              <SingleAntImage
                src={BaseballGuide1}
                className="img-guide1"
                width="100%"
                height="auto"
              />
            </ThemeVisibility>
            <ThemeVisibility themes={["bigwheel"]}>
              <SingleAntImage
                src={BigWheelGuide1}
                className="img-guide1"
                width="100%"
                height="auto"
              />
            </ThemeVisibility>
            <BtnGotIt
              className="btn-guide guide1"
              onClick={() => {
                setInstructionStage(2);
              }}
            />
          </div>
        </Fade>
      )}
      {instructionStage === 2 && (
        <Fade
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="ins-wrapper">
            {textTranslation(2)}
            <ThemeVisibility themes={["dice"]}>
              <SingleAntImage
                src={NewGuide2}
                className="img-guide2"
                width="100%"
                height="auto"
              />
            </ThemeVisibility>
            <ThemeVisibility themes={["cricket"]}>
              <SingleAntImage
                src={CricketGuide2}
                className="img-guide2"
                width="100%"
                height="auto"
              />
            </ThemeVisibility>
            <ThemeVisibility themes={["baseball"]}>
              <SingleAntImage
                src={BaseballGuide2}
                className="img-guide2"
                width="100%"
                height="auto"
              />
            </ThemeVisibility>
            <ThemeVisibility themes={["bigwheel"]}>
              <SingleAntImage
                src={BigWheelGuide2}
                className="img-guide2"
                width="100%"
                height="auto"
              />
            </ThemeVisibility>
            <BtnGotIt
              className="btn-guide guide2"
              onClick={() => {
                setInstructionStage(3);
              }}
            />
          </div>
        </Fade>
      )}
      {instructionStage === 3 && (
        <Fade
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="ins-wrapper">
            {textTranslation(3)}
            <ThemeVisibility themes={["dice"]}>
              <SingleAntImage
                src={CricketGuide3}
                className="img-guide3"
                width="100%"
                height="auto"
              />
            </ThemeVisibility>
            <ThemeVisibility themes={["cricket"]}>
              <SingleAntImage
                src={CricketGuide3}
                className="img-guide3"
                width="100%"
                height="auto"
              />
            </ThemeVisibility>
            <ThemeVisibility themes={["baseball"]}>
              <SingleAntImage
                src={CricketGuide3}
                className="img-guide3"
                width="100%"
                height="auto"
              />
            </ThemeVisibility>
            <ThemeVisibility themes={["bigwheel"]}>
              <SingleAntImage
                src={CricketGuide3}
                className="img-guide3"
                width="100%"
                height="auto"
              />
            </ThemeVisibility>

            {/* { (i18n.language === 'pt' ||  i18n.language === 'pt-BR' || i18n.language === 'pt-br' ) &&
              <InstructionImageContainer>
                <SingleAntImage
                  src={GuidePayoutPt}
                  className=""
                />
              </InstructionImageContainer>
            } */}
            <BtnGotIt
              className={`btn-guide ${
                i18n.language === "pt" ||
                i18n.language === "pt-BR" ||
                i18n.language === "pt-br"
                  ? "guide3Pt"
                  : "guide3"
              }`}
              onClick={() => {
                setInstructionStage(4);
              }}
            />
          </div>
        </Fade>
      )}
      {instructionStage === 4 && (
        <Fade
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="ins-wrapper no-justify">
            {textTranslation(4)}
            <ThemeVisibility themes={["dice"]}>
              <SingleAntImage
                src={NewGuide4}
                className="img-guide4"
                width="100%"
                height="auto"
              />
            </ThemeVisibility>
            <ThemeVisibility themes={["cricket"]}>
              <div class="hit-streak-text">
                {displayHitStreak(t('instruction.cricket.hitStreak'))}
                {/* <Trans t={t}>instruction.cricket.hitStreak</Trans></div> */}
              </div>
              <SingleAntImage
                src={CricketGuide4}
                className="img-guide4"
                width="100%"
                height="auto"
              />
            </ThemeVisibility>
            <ThemeVisibility themes={["baseball"]}>
              <SingleAntImage
                src={BaseballGuide4}
                className="img-guide4"
                width="100%"
                height="auto"
              />
            </ThemeVisibility>
            <ThemeVisibility themes={["bigwheel"]}>
              <SingleAntImage
                src={BigWheelGuide4}
                className="img-guide4"
                width="100%"
                height="auto"
              />
            </ThemeVisibility>

            <BtnGotIt
              className="btn-guide guide4"
              onClick={() => {
                setInstructionStage(5);
              }}
            />
          </div>
        </Fade>
      )}
      {instructionStage === 5 && (
        <Fade
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="ins-wrapper">
            {textTranslation(5)}
            <ThemeVisibility themes={["dice"]}>
              <SingleAntImage
                src={NewGuide5}
                className="img-guide5"
                width="100%"
                height="auto"
              />
            </ThemeVisibility>
            <ThemeVisibility themes={["cricket"]}>
              <SingleAntImage
                src={CricketGuide5}
                className="img-guide5"
                width="100%"
                height="auto"
              />
            </ThemeVisibility>
            <ThemeVisibility themes={["baseball"]}>
              <SingleAntImage
                src={BaseballGuide5}
                className="img-guide5"
                width="100%"
                height="auto"
              />
            </ThemeVisibility>
            <ThemeVisibility themes={["bigwheel"]}>
              <SingleAntImage
                src={BigWheelGuide5}
                className="img-guide5"
                width="100%"
                height="auto"
              />
            </ThemeVisibility>

            <BtnGotIt
              className="btn-guide guide5"
              onClick={() => {
                setInstructionStage(1);
                setIsInstruction(false);
              }}
            />
          </div>
        </Fade>
      )}
    </InstructionContainer>
  );
}
