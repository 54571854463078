import React from 'react'
import Theme from 'containers/Theme';

const SingleButton = ({
  bg,
  animate = false,
  className = '',
  width = '200px',
  height = '80px',
  renderTitle = () => { },
  onPress = () => { },
  styleModule,
}) => (
  // <Reveal keyframes={animate ? customAnimation : null} style={{width: width}}>
    <styleModule.SingleButtonContainer
      className={className}
      height={height}
      bg={bg}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      style={{width: width}}
      animate={animate}
      onClick={onPress}
    >
      {renderTitle()}
    </styleModule.SingleButtonContainer>
    // </Reveal>
  );

export default Theme(SingleButton, 'components/SingleButton');