import React, { useContext } from 'react';
import Theme from 'containers/Theme';
import ThemeVisibility from 'components/ThemeVisibility';
import DiceStrikes from './dice'
import BigWheel from './bigwheel'
import BetOrRollPopup from "components/BetOrRollPopup";
import { DataContext } from "contexts/DataContextContainer";

const Strikes = () => {
  const {
    activeBtn,
    sideBet,
  } = useContext(DataContext);
	return (
    <>
      <ThemeVisibility themes={['dice']}>
        <DiceStrikes />
      </ThemeVisibility>
      <ThemeVisibility themes={['bigwheel']}>
        <BigWheel />
      </ThemeVisibility>
      <div style={{position: "relative", display: "flex", justifyContent: "center", top: "-70px" }}>
        {(sideBet !== undefined && activeBtn) && <BetOrRollPopup />}
      </div>
    </>
	);
}

export default Theme(Strikes, 'components/Strikes');