import Theme from 'containers/Theme';
import React from 'react';
import { BaseballRollButton } from 'assets/images';

const BaseballRoll = ({ styleModule, onClickRollBtn }) => {
	const { Wrapper } = styleModule;

	return (
		<Wrapper onClick={onClickRollBtn}>
			<img src={BaseballRollButton} />
		</Wrapper>
	)
}

export default Theme(BaseballRoll, 'components/LetsRoll');