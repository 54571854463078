import Theme from 'containers/Theme';
import React from 'react';
import ThemeVisibility from 'components/ThemeVisibility';
import { Trans, useTranslation } from "react-i18next";

const BetOrRollPopup = ({ styleModule }) => {
	const { t } = useTranslation();
	const { Container } = styleModule;
	const handleFontSize = (string) => {
		if (string.length >= 37) {
			return 'medium-font-size'
		}
	}
	return <Container className="bet-or-roll-popup">
		<div className={ `green ${handleFontSize(t("rollPopup.clickRollToPlaySideBet"))}`}>
			<ThemeVisibility themes={['dice']}>
				<Trans t={t}>rollPopup.clickRollToPlaySideBet</Trans>
			</ThemeVisibility>
			<ThemeVisibility themes={['cricket']}>
				<Trans t={t}>rollPopup.cricket.clickRollToPlaySideBet</Trans>
			</ThemeVisibility>
			<ThemeVisibility themes={['baseball']}>
				<Trans t={t}>rollPopup.baseball.clickRollToPlaySideBet</Trans>
			</ThemeVisibility>
			<ThemeVisibility themes={['bigwheel']}>
				<Trans t={t}>rollPopup.bigwheel.clickRollToPlaySideBet</Trans>
			</ThemeVisibility>
		</div>
		<div className={ `red medium-font-size`}>
			<ThemeVisibility themes={['dice']}>
				<Trans t={t}>rollPopup.clickBetCoinToCancel</Trans>
			</ThemeVisibility>
			<ThemeVisibility themes={['cricket']}>
				<Trans t={t}>rollPopup.cricket.clickBetCoinToCancel</Trans>
			</ThemeVisibility>
			<ThemeVisibility themes={['baseball']}>
				<Trans t={t}>rollPopup.baseball.clickBetCoinToCancel</Trans>
			</ThemeVisibility>
			<ThemeVisibility themes={['bigwheel']}>
				<Trans t={t}>rollPopup.bigwheel.clickBetCoinToCancel</Trans>
			</ThemeVisibility>
		</div>
	</Container>
};

export default Theme(BetOrRollPopup, 'components/BetOrRollPopup');