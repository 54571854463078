import React, { useContext } from 'react';
import useSound from "use-sound";
import { SoundClickButton } from "assets/audio";
import { soundSetting } from "service";
import { Popover } from "antd";
import SingleAntImage from "components/SingleAntImage";
import { MenuButton } from "assets/images";
import { DataContext } from "contexts/DataContextContainer";
import Theme from 'containers/Theme';
import { useTranslation } from "react-i18next";

const HeaderMenu = ({ styleModule }) => {
  const {
    showPayTable,
    setShowPayTable,
    showGuide,
    setShowGuide,
    isPlayingMusic,
    setIsPlayingMusic,
    isPlayingSound,
    setIsPlayingSound,
    setIsInstruction,
    menuOpen,
    setMenuOpen,
  } = useContext(DataContext);
  const [playSoundClickButton] = useSound(SoundClickButton, soundSetting);
  const { t } = useTranslation();

  const turnSound = (isPlaying) => {
    if (isPlaying === true) {
      return t('gameMenu.turnSoundOff');
    }
    return t('gameMenu.turnSoundOn');
  }

  const turnMusic = (isPlaying) => {
    if (isPlaying === true) {
      return t('gameMenu.turnMusicOff');
    }
    return t('gameMenu.turnMusicOn');
  }

  const handleSoundToggleClicked = () => {
    window.ga_log_user_click(`sound-toggle-${!isPlayingSound}`);
    setIsPlayingSound(!isPlayingSound);
  }

  const handleMusicToggleClicked = () => {
    window.ga_log_user_click(`music-toggle-${!isPlayingMusic}`);
    setIsPlayingMusic(!isPlayingMusic);
  }

  const handleToggleClicked = () => {
    window.ga_log_user_click('menu');
    setMenuOpen(true);
  }

  const content = (
    <styleModule.Contents>
      <styleModule.Option className="mb0" onClick={handleSoundToggleClicked}>
        {turnSound(isPlayingSound)}
      </styleModule.Option>
      <styleModule.Option className="mb0" onClick={handleMusicToggleClicked}>
        {turnMusic(isPlayingMusic)}
      </styleModule.Option>
      <styleModule.Option className="mb0" onClick={() => {
        window.ga_log_user_click('instruction');
        setMenuOpen(false);
        setIsInstruction(true);
      }}>
        {t('gameMenu.instructions')}
      </styleModule.Option>
      <styleModule.Option className="mb0" onClick={() => {
        window.ga_log_user_click('guide');
        setMenuOpen(false);
        setShowGuide(!showGuide);
      }}>
        {t('gameMenu.guide')}
      </styleModule.Option>
      <styleModule.Option className="mb0" onClick={() => {
        window.ga_log_user_click('paytable');
        setMenuOpen(false);
        setShowPayTable(!showPayTable)
      }}>
        {t('common.payoutsSm')}
      </styleModule.Option>
    </styleModule.Contents>
  );

	return (
		<div className="header-menu" onClick={() => isPlayingSound && playSoundClickButton()}>
      {menuOpen && <styleModule.Overlay onClick={() => setMenuOpen(false)} />}
			<Popover
				content={content}
				title={null}
				placement="bottomLeft"
				trigger="click"
        visible={menuOpen}
			>
				<SingleAntImage onClick={handleToggleClicked} src={MenuButton} width={50} height={30} style={{ zIndex: 1 }} />
			</Popover>
		</div>
	)
}

export default Theme(HeaderMenu, 'components/HeaderMenu');