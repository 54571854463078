import axios from 'axios';
import { delay } from './axios';

const handleError = (error) => {
	let message = 'A network error occurred.';

	if (error.response && error.response.data) {
		message = error.response.data.message;
	}

	throw new Error(message);
}

export const wager = async (wager) => {
	const gameId = sessionStorage.getItem('gameId');

	try {
		const request = await axios.put(`/api/game/${gameId}/wager/${wager}`);

		return request.data;
	} catch (error) {
		handleError(error);
	}
}


export const pick = async (coords, isTurbo) => {
	const gameId = sessionStorage.getItem('gameId');

	try {
		const request = await delay(axios.put(`/api/game/${gameId}/pick/${coords.x}/${coords.y}`), isTurbo ? 0 : 1500);

		return request.data;
	} catch (error) {
		handleError(error);
	}
}

export const roll = async (sideBet) => {
	const gameId = sessionStorage.getItem('gameId');

	try {
		let url = `/api/game/${gameId}/roll`;

		if (sideBet) {
			url = `${url}?sideBet=${sideBet}`
		}

		const request = await axios.put(url);

		return request.data;
	} catch (error) {
		handleError(error);
	}
}

export const getGameState = async () => {
	const gameId = sessionStorage.getItem('gameId');

	try {
		const request = await axios.get(`/api/game/${gameId}`)

		return request.data;
	} catch (error) {
		handleError(error);
	}
}