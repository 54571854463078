import React from 'react'
import SingleText from 'components/SingleText'
import Theme from 'containers/Theme';

const RollBtn = ({
  bg,
  title = '',
  width = '200px',
  height = '80px',
  disabled = false,
  onPress = () => { },
  styleModule,
}) => (
    <styleModule.RollBtnContainer
      type={null}
      width={width}
      height={height}
      bg={bg}
      disabled={disabled}
      onPress={onPress}
    >
      <div className='btn-content'>
        {title &&
          <SingleText
            size={30}
            color='#005822'
            spacing='-3px'
            weight={700}
            filter='drop-shadow(0px 2px 0px rgba(170,234,127,0.8))'
          >
            {title}
          </SingleText>
        }
      </div>
    </styleModule.RollBtnContainer>
  );

export default Theme(RollBtn, 'components/RollBtn');