/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import SingleText from "components/SingleText";
import { Umpire, BaseballUmpire } from "assets/images";
import {
  numberGroup,
  statusColors,
  numbersTemp,
} from "service";
import { DataContext } from "contexts/DataContextContainer";
import SingleAntImage from "components/SingleAntImage";
import ThemeVisibility from "components/ThemeVisibility";
import {
  IconCheckNum,
  StarStreak,
} from "assets/images";
import Theme from 'containers/Theme';
import Bigwheel from './bigwheel';

const animations = {
  1: "swing",
  2: "heartBeat",
  3: "jello",
  4: "tada",
};

const mapThemeNumbers = (index, numbers) => {
  return numbers[index - 1];
}

const Number = (props) => {
  const {
    order,
    totalPicked,
    status,
    isFreePick = false,
    countRemained,
    newWinGroupKeys = [],
    rolledNum,
    onClick,
    styleModule,
    themeSounds,
    controller,
  } = props;
  const numbers = controller.getNumbers();
  const { skipping, isPlayingSound } = useContext(DataContext);
  const [date, setDate] = useState(new Date());
  const sounds = themeSounds.useSound(isPlayingSound);
  const wasClickedRef = useRef(false);
  let speed = "animate__slower";

  switch (totalPicked) {
    case 23:
      speed = "";
      break;
    case 24:
      speed = "animate__fast";
      break;
    default:
      break;
  }

  let animateClasses = {
    orange: '',
    winGroup: '',
    shakeClass: '',
  }

  if (status === statusColors.white && totalPicked >= 22) {
    animateClasses.shakeClass =  `animate__animated animate__heartBeat animate__infinite ${speed}`;
  }

  if (status == statusColors.orange) {
    animateClasses.orange = `animate__animated animate__${animations[countRemained.toString()]} animate__infinite animate__slow`
  }

  const winGroupHasOrder = newWinGroupKeys.find((_key) =>
    numberGroup[_key].includes(order.toString())
  );

  if (newWinGroupKeys.length > 0 && winGroupHasOrder !== undefined) {
    animateClasses.winGroup = 'animate__animated animate__flash animate__repeat-1';
    animateClasses.orange = '';
  }

  useEffect(() => {
    const winGroupHasOrder = newWinGroupKeys.find((_key) =>
      numberGroup[_key].includes(order.toString())
    );

    if (winGroupHasOrder !== undefined) {
      let timeout = 0;

      if (wasClickedRef.current) {
        timeout = 1500;
      }

      setTimeout(() => {
        setDate(new Date());
      }, timeout);
    }
  }, [newWinGroupKeys]);

  useEffect(() => {
    if (!wasClickedRef.current && status != 2) {
      return;
    }

    wasClickedRef.current = false;

    setTimeout(() => {
      setDate(new Date());
    }, 1100);
  }, [status]);

  return (
    <>
      <ThemeVisibility themes={['bigwheel']}>
        <Bigwheel {...props} />
      </ThemeVisibility>
      <ThemeVisibility themes={['dice', 'cricket', 'baseball']}>
        <styleModule.NumberContainer
          key={`number-container-${order}-${date.getTime()}`}
          className={`number ${order === 13 && "number7"} ${animateClasses.orange} ${animateClasses.winGroup} ${animateClasses.shakeClass}`}
          status={parseInt(status)}
          onClick={() => {
            window.ga_log_user_click('number-tile');
            if (status == statusColors.orange) {
              sounds.playSoundOrangeClick();

              onClick();
              wasClickedRef.current = true;
            }
          }}
        >
          <ThemeVisibility themes={['cricket']}>
            {order === 13 && <img className="umpire" src={Umpire} />}
          </ThemeVisibility>
          <ThemeVisibility themes={['baseball']}>
            {order === 13 && <img className="umpire" src={BaseballUmpire} />}
          </ThemeVisibility>
          <SingleText
            size={53}
            weight={700}
            color={`${status == 0 && order != 13 ? "#242e42" : "white"}`}
          >
            {mapThemeNumbers(order, numbers)}
          </SingleText>
          {rolledNum === numbersTemp[order - 1] && skipping && (
            <SingleAntImage
              className="icon-check-num"
              src={IconCheckNum}
              width="100%"
              height="auto"
            />
          )}
          {isFreePick && status == statusColors.white && (
            <SingleAntImage
              className="icon-free-num"
              src={StarStreak}
              width="100%"
              height="auto"
              onClick={onClick}
            />
          )}
        </styleModule.NumberContainer>
      </ThemeVisibility>
    </>
  );
};

export default React.memo(Theme(Number, 'components/Number'));