import Theme from 'containers/Theme';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DataContext } from "contexts/DataContextContainer";
import { currencyFormatter } from 'utils/currency-formatter';
import { Trans, useTranslation } from "react-i18next";

const time = () => {
	const d = new Date();

	return d.getTime();
}

const SideBet = ({
	activeBtn,
	betList,
	label,
	onSelect,
	isRolling,
	styleModule,
	value,
	waitingForRoll,
	sideBet,
	sideBetWin,
	controller
}) => {
	const { t } = useTranslation();
	const {
		Container,
		Condition,
		Coin,
		Inner,
		Sub,
		MarkContainer,
	} = styleModule;
	const {
		currencyFormat,
		showSideBetWin,
		setSideBetsEnabled,
		setSideBet,
		rollButtonEnabled,
		setRollButtonEnabled,
		isAuto,
		isRollingRef,
		setIsAuto,
	} = useContext(DataContext);
	const [rerender, toggleRender] = useState(time());
	const showLoading = useRef(false);
	const setShowLoadingRef = useRef(null);

	const isVisible = (item) => {
		if (isAuto) {
			return false;
		}
		if (activeBtn && sideBet === undefined) {
			return true;
		} else if (activeBtn && sideBet !== undefined && sideBet.label === item.label) {
			return true;
		} else if (waitingForRoll && sideBet !== undefined && sideBet.label === item.label) {
			return true;
		} else if (sideBet !== undefined && item.label === sideBet.label) {
			return true;
		} else if (!waitingForRoll && sideBet === undefined) {
			return true;
		} else if (waitingForRoll && sideBet !== undefined && item.label === sideBet.label) {
			return true;
		} else if (!waitingForRoll && sideBet === undefined && showLoading.current) {
			return true;
		} else if (isRolling && !showLoading.current) {
			return false;
		}

		return false;
	}

	const handleOnSelect = (item) => {
    if (isRollingRef.current) {
      return
    }

    window.ga_log_user_click('coin');
		if (isAuto) {
			setSideBet(undefined)
			setIsAuto(false)
			return;
		}
		controller.handleOnSelectBet({ setSideBet, item })

		setSideBetsEnabled(true)
    	setRollButtonEnabled(true)

		if (showLoading.current || activeBtn) {
			onSelect(item);
		}

		showLoading.current = false;
		toggleRender(time());
	}

	const getWinMarker = (item) => {
		if (!isAuto && rollButtonEnabled && showSideBetWin && item.id === showSideBetWin) {
			if (sideBetWin && sideBetWin.label !== null) {
				return <MarkContainer win={true} />;
			} else {
				return <MarkContainer />;
			}
		}
	}

	useEffect(() => {
		clearTimeout(setShowLoadingRef.current);
		let shouldSchedule = false;

		if (activeBtn && sideBet === undefined) {
			showLoading.current = true;
			shouldSchedule = true;
		} else if (sideBet !== undefined || !activeBtn) {
			showLoading.current = false;
		}
		toggleRender(time());

		if (shouldSchedule) {
			setShowLoadingRef.current = setTimeout(() => {
				showLoading.current = false;
				toggleRender(time());
			}, 8000);
		}
	}, [isRolling, sideBet, waitingForRoll, activeBtn]);

	return <Container visible={true}>
		<Condition>
			{label}
			<Sub>
				<Trans t={t}>common.paysTwoOne</Trans>
			</Sub>
		</Condition>
		{betList.map(item => (
			<Coin
				key={item.label}
				visible={isVisible(item)}
				onClick={() => handleOnSelect(item)}
			>
				{getWinMarker(item)}
				<Inner>
					<span>{currencyFormatter(item.wagerMultiplier * value, currencyFormat)}</span>
				</Inner>
			</Coin>
		))}
	</Container>
};

export default Theme(SideBet, 'components/SideBet');