import React from 'react';
import Theme from 'containers/Theme';
import { ToggleButton } from 'assets/images';
import { useTranslation } from "react-i18next";

const Toggle = ({ title, styleModule, className, state, onChange, reference }) => {
	const { t } = useTranslation();
	return (
		<styleModule.Container ref={reference} className={className}>
			{title}
			<styleModule.ButtonContainer onClick={() => onChange(!state)} state={state}>
				<styleModule.ToggleClickable src={ToggleButton} />
				<styleModule.CustomLabel state={state}>{state ? t("common.on") : t("common.off")}</styleModule.CustomLabel>
			</styleModule.ButtonContainer>
		</styleModule.Container>
	)
}

export default Theme(Toggle, 'components/Toggle');