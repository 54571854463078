import React, { useContext } from 'react';
import { Space } from "antd";
import { DataContext } from "contexts/DataContextContainer";
import Hole from "components/Hole";
import Theme from 'containers/Theme';
import { useTranslation } from "react-i18next";

const DiceStrikes = ({ styleModule }) => {
  const { t } = useTranslation();
  const {
    holeTimesRef,
    isGameOver,
    streakStrike,
    winnedLines,
  } = useContext(DataContext);

	return (
    <styleModule.HolesContainer
      className="holes-container"
      animate={isGameOver && winnedLines === 0}
    >
			<div className={`holes`}>
				<span className="holes-text">
					{isGameOver ? t("common.gameOver") : t("common.anySeven")}
				</span>
				<Space direction="horizontal" size={5}>
					{Array.from({ length: 3 }, (v, i) => i + 1).map((_hole, _i) => (
						<Hole animate={streakStrike >= 2} key={_i} hole={_hole} holeTimes={holeTimesRef.current} />
					))}
				</Space>
			</div>
    </styleModule.HolesContainer>
	);
}

export default Theme(DiceStrikes, 'components/Strikes');
