import React from 'react';
import { MemoryRouter as Router, Route, Switch } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import GlobalStyle from 'theme/globalStyle';
import 'assets/webfonts/PinkSlab-130.ttf'
import 'assets/webfonts/barlow-semi-condensed/BarlowSemiCondensed-Regular.ttf'
import 'assets/webfonts/5by7.ttf'
import 'assets/webfonts/Carnevalee Freakshow.ttf'
import 'assets/css/fontawesome.min.css';
import "react-awesome-button/dist/styles.css";
import 'antd/dist/antd.css';
import DataContextContainer from 'contexts/DataContextContainer';
import { ThemeContext } from 'contexts/ThemeContext';
import Landing from 'containers/Landing';
import Logs from 'containers/Logs';
import Test from 'containers/Test';
import PageLayout from 'layouts';
import BackgroundLayout from 'layouts/BackgroundLayout';
import BasePage from 'BasePage';
import i18n from './i18n';

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  let currentTheme = urlParams.get('theme');
  let currentLanguage = urlParams.get('language');
  const storedTheme = sessionStorage.getItem('theme');

  if (currentTheme) {
    sessionStorage.setItem('theme', currentTheme.toLowerCase());
    currentTheme = currentTheme.toLowerCase();
  } else if (storedTheme) {
    currentTheme = storedTheme.toLowerCase()
  } else {
    currentTheme = 'cricket';
  }

  if (currentLanguage) {
    i18n.changeLanguage(currentLanguage);
  }

  return (
    <div className="App">
      <ThemeContext.Provider value={currentTheme}>
        <Scrollbars className={`scrollbar-${currentTheme}`} autoHide style={{ flex: 1 }}>
          <GlobalStyle />
          <Router>
            <Switch>
              <DataContextContainer>
                <BackgroundLayout>
                  <Route exact path="/" component={Landing}/>
                  <Route exact path="/logs" component={Logs} />
                  <Route path="/game">
                    <PageLayout>
                      <BasePage />
                    </PageLayout>
                  </Route>
                  <Route exact path="/test" component={Test} />
                </BackgroundLayout>
              </DataContextContainer>
            </Switch>
          </Router>
        </Scrollbars>
      </ThemeContext.Provider>
    </div>
  );
}

export default App;
