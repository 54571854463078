import React from 'react';
import Theme from 'containers/Theme';
import { useTranslation } from "react-i18next";
import { currencyFormatter } from 'utils/currency-formatter';
import { BaseballMoneyContainer } from "assets/images";

const CommonHeader = ({
	bet,
	currencyFormat,
	playerBalance,
	styleModule,
}) => {
  const { t, i18n } = useTranslation();
	const { HeaderWrapper } = styleModule;

	return (
		<HeaderWrapper>
			<div className="balance-value">
				<img className="background-container" src={BaseballMoneyContainer} />
				<b className="balance-value-title">
				{t("common.balance")}
				</b>
				<span
					className="container-span"
					style={{
					fontWeight: 700,
					fontSize: 14,
					color: "white",
					fontFamily: "Roboto",
				}}>
					<div>
						{currencyFormatter(playerBalance, currencyFormat)}
					</div>
				</span>
			</div>
			<div className="bet-value">
				<img className="background-container" src={BaseballMoneyContainer} />
				<b className={`bet-value-title bet-value-${i18n.language}`}>
					{t("common.bet")}
				</b>
				<span style={{
					fontWeight: 700,
					fontSize: 14,
					color: "white",
					fontFamily: "Roboto",
				}}>
					<div>
						{currencyFormatter(bet, currencyFormat)}
					</div>
				</span>
			</div>
		</HeaderWrapper>
	)
}

export default Theme(CommonHeader, 'layouts');