import React from 'react'
import PropTypes from 'prop-types';
import Theme from 'containers/Theme';

const SingleAntImage = (props) => {
  const { 
    className = '',
    children,
    src,
    height = '100%',
    width = 'auto',
    style,
    borderradius = '0px',
    preview = false,
    onClick = () => {},
    styleModule,
  } = props;
  
  return (
    <styleModule.Wrapper className={`${className}-wrapper`}>
      <styleModule.SingleAntImageContainer
        className={className}
        width={width}
        height={height}
        borderradius={borderradius}
        src={src}
        style={style}
        preview={preview}
        onClick={onClick}
      />
      {children}
    </styleModule.Wrapper>
  )
}

SingleAntImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
  style: PropTypes.object,
  borderradius: PropTypes.string,
  preview: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Theme(SingleAntImage, 'components/SingleAntImage');