import React from "react";
import Rolling from "containers/Rolling";
import Bet from "containers/Bet";
import GameOver from "containers/GameOver";
import { Route } from "react-router";
import { Suspense } from "react";

export default function BasePage() {
  return (
    <Suspense>
      <Route exact path="/game/rolling" component={Rolling} />
      <Route exact path="/game/bet" component={Bet} />
      <Route exact path="/game/game-over" component={GameOver} />
    </Suspense>
  );
}
