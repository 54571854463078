import React, { useContext, useEffect, useRef, useState } from 'react';
import Theme from 'containers/Theme';
import ApngComponent from 'react-apng';
import { DataContext } from 'contexts/DataContextContainer';
import { numbersTemp, cricketNumbersTemp } from "service";
import {
  BaseballPitchOut,
  BaseballRollingRedBall,
  BaseballRollingRedWhiteBall,
  BaseballHit,
  BaseballRedBall,
  BaseballWhiteBall,
} from 'assets/images';

const BaseballNumberReveal = ({ styleModule, controller }) => {
  const {
    gameEvents,
    streakStrikeRef,
    audioRef,
    showGameOverBanner,
    isPlayingSound,
    isTurbo
  } = useContext(DataContext);
  const { Wrapper, Number } = styleModule;
  const [rolledNum, setRolledNum] = useState(null);
  const wrapperRef = useRef();
  const rolledNumRef = useRef();
  const pitchRef = useRef();
  const pitchOutRef = useRef();
  const pitchOutroRef = useRef();
  const rollingBallRef = useRef();
  const redBallRef = useRef();
  const numberRef = useRef();
  const pitchAnimationDone = useRef(false);
  const pitchFromIntro = useRef(false);
  const turboRef = useRef(false);
  const ballImage = rolledNumRef.current === 7 ? BaseballRedBall : BaseballWhiteBall;

  const toggleNumberRef = (opacity) => {
    try {
      numberRef.current.style.opacity = opacity;
    } catch (ex) {}
  }

  const makeWhiteBallFly = (attrs) => {
    try {
      const box1 = pitchRef.current.refs.canvasBox
      const box2 = redBallRef.current;

      box1.setAttribute('class', `pitch-intro-ball ${attrs}`);
      box2.setAttribute('class', `stopped-ball ${attrs}static`);
    } catch (ex) {}
  }

  const toggleWrapperRef = (field, value) => {
    try {
      wrapperRef.current.style[field] = value;
    } catch (ex) {}
  }

  const onFrameChanged = (apngRef) => (f) => {
    if (!apngRef) {
      return;
    }
    const isEnd = f >= apngRef.apng.frames.length - 1;

    if (f === 1 && !turboRef.current) {
      audioRef.current.playBallThrow();
    } else if(isEnd) {
      if(streakStrikeRef.current === 2){
        showRedOrWhiteBall();
      }else{
        toggleWrapperRef('z-index', 1);
        if(rolledNumRef.current !== 7){
          toggleNumberRef(1);
        }else{
          if(streakStrikeRef.current < 3 && isPlayingSound) audioRef.current.playSoundBoo();
        }
        pitchAnimationDone.current = true;
        audioRef.current.stopRollingBallSuspense();
        //placed a timeout here to fix the slight halt in animation
        setTimeout(() => {
          gameEvents.emit('pitch-animation-done');
        }, 1);
      }
    }
  }

  const showRedOrWhiteBall = () => {
    rollingBallRef.current.refs.canvasBox.style.visibility = 'hidden';
    redBallRef.current.style.visibility = 'visible';
    toggleWrapperRef('z-index', 1);

    pitchAnimationDone.current = true;
    if (streakStrikeRef.current === 2 && rolledNumRef.current === 7) {
      gameEvents.emit('cricket-show-price');
      toggleWrapperRef('z-index', 3);
    }
    audioRef.current.stopRollingBallSuspense();
    //placed a timeout here to fix the slight halt in animation
    setTimeout(() => {
      gameEvents.emit('pitch-animation-done');
    }, 1);
  }

  const animate = (apngRef) => {
    toggleWrapperRef('opacity', 1);
    toggleWrapperRef('z-index', 3);
    redBallRef.current.style.visibility = 'hidden';

    gameEvents.emit('pitch-animation-begin');
    if (apngRef && apngRef.player) {
      apngRef.refs.canvasBox.style.opacity = 1;
      toggleNumberRef(0);

      apngRef.player._events.frame = undefined;
      apngRef.player.on('frame', onFrameChanged(apngRef));

      if(turboRef.current){
        apngRef.stop();
        while(apngRef.player._currentFrameNumber < apngRef.apng.frames.length - 1){
          apngRef.player.renderNextFrame();
        }
      }else{
        if (pitchFromIntro.current) {
          pitchFromIntro.current = false;
          apngRef.continue();
        } else {
          apngRef.one();
        }
      }
    }
  }

  const onApiDone = (number) => {
    pitchOutroRef.current.refs.canvasBox.style.opacity = 0;
    makeWhiteBallFly('');
    pitchAnimationDone.current = false;
    let refToAnimate = pitchRef;

    if (streakStrikeRef.current === 2) {
      controller.stopOrganSound(audioRef);
      pitchRef.current.refs.canvasBox.style.opacity = 0;
      pitchOutRef.current.refs.canvasBox.style.opacity = 0;
      rollingBallRef.current.refs.canvasBox.style.visibility = 'visible';
      refToAnimate = rollingBallRef;
    } else if (number !== 7) {
      pitchOutRef.current.refs.canvasBox.style.opacity = 0;
    } else {
      pitchRef.current.refs.canvasBox.style.opacity = 0;
      refToAnimate = pitchOutRef;
    }

    rolledNumRef.current = number;
    setRolledNum(number);
    const pitchTime = setTimeout(() => {
      animate(refToAnimate.current);
      clearTimeout(pitchTime);
    }, 50);
  }

  const handlePickAnimation = (f) => {
    if (f >= pitchOutroRef.current.apng.frames.length - 1) {
      toggleWrapperRef('z-index', 1);
    }
  }

  const onAnimatePick = () => {
    if (rolledNumRef.current === 7) {
      return;
    }

    try {
      toggleWrapperRef('z-index', 3);
      audioRef.current.playBatImpact();
      audioRef.current.playCrowdCheer();
      pitchOutRef.current.refs.canvasBox.style.opacity = 0;

      if (pitchOutroRef.current.player._events.frame === undefined) {
        pitchOutroRef.current.player.on('frame', handlePickAnimation);
      }

      pitchOutroRef.current.refs.canvasBox.style.opacity = 1;
      pitchOutroRef.current.one();

      makeWhiteBallFly('fly');
      toggleNumberRef(0);
      if(!turboRef.current){
        setTimeout(() => {
          pitchRef.current.refs.canvasBox.style.opacity = 0;
        }, 1000);
      }
    } catch (ex) {
      console.log('An error occurred while trying to animate pick');
    }
  }

  const handleCheckFrameBeforePitch = (f) => {
    if (f === 1) {
      audioRef.current.playBallsFillingHopper();
    }else if (f === 33) {
      audioRef.current.stopPlayBallsFillingHopper();
      pitchRef.current.pause();
      pitchRef.current.player.off('frame', handleCheckFrameBeforePitch);
    }
  }

  const handleAnimateBeforePitch = () => {
    pitchFromIntro.current = true;
    pitchOutroRef.current.refs.canvasBox.style.opacity = 0;
    makeWhiteBallFly('');
    pitchRef.current.player.on('frame', handleCheckFrameBeforePitch);
    pitchRef.current.one();
  }

  const getCricketNumber = (num) => {
    const index = numbersTemp.findIndex(n => n === num)
    if (index !== -1) {
      return cricketNumbersTemp[index];
    }
    return num;
  }

  useEffect(() => {
    if (showGameOverBanner) {
      redBallRef.current.style.visibility = 'hidden';
      wrapperRef.current.style['z-index'] = 1;
      rollingBallRef.current.refs.canvasBox.style.visibility = 'hidden';
    }
  }, [showGameOverBanner]);

	useEffect(() => {
		turboRef.current = isTurbo;
	}, [isTurbo]);

  useEffect(() => {
    toggleNumberRef(0);
    gameEvents.on('animate-before-pitch', handleAnimateBeforePitch);
    gameEvents.on('baseball-number-reveal-start', onApiDone);
    gameEvents.on('on-click-number', onAnimatePick);

    return () => {
      if (pitchRef.current) {
        pitchRef.current.player.off('frame', onFrameChanged(pitchRef.current));
      }

      if (pitchOutRef.current) {
        pitchOutRef.current.player.off('frame', onFrameChanged(pitchOutRef.current));
      }

      if (pitchOutroRef.current) {
        pitchOutroRef.current.player.off('frame', handlePickAnimation);
      }

      gameEvents.off('animate-before-pitch', handleAnimateBeforePitch);
      gameEvents.off('baseball-number-reveal-start', onApiDone);
      gameEvents.off('on-click-number', onAnimatePick);
    };
  }, []);

  return (
    <Wrapper ref={wrapperRef} className="baseball-number-reveal" key="baseball-number-reveal">
			<ApngComponent className="pitch-intro-ball" ref={pitchRef} rate={1.1} src={BaseballPitchOut} />
		  <ApngComponent className="pitch-out" ref={pitchOutRef} rate={1.3} src={BaseballRollingRedBall} />
			<ApngComponent className="pitch-outro" ref={pitchOutroRef} rate={1} src={BaseballHit} />
			<ApngComponent className="rolling-red-ball" ref={rollingBallRef} rate={0.7} src={BaseballRollingRedWhiteBall} />

      <img ref={redBallRef} src={ballImage} className="stopped-ball" />
      {rolledNum && (
        <Number key={`rolled-num-${rolledNum}`} ref={numberRef}>{getCricketNumber(rolledNum)}</Number>
      )}
    </Wrapper>
  );
}

export default Theme(React.memo(BaseballNumberReveal), 'components/BaseballNumberReveal');