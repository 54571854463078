import React, { useContext, useEffect, useRef, useState } from 'react';
import Theme from 'containers/Theme';
import ApngComponent from 'react-apng';
import { CricketRollBtnIntro, CricketRollBtnLoop, CricketRollBtnOutro } from 'assets/images';
import { DataContext } from "contexts/DataContextContainer";

const CricketLetsRoll = ({ styleModule, onClickRollBtn }) => {
	const { setIsRolling } = useContext(DataContext);
	const { CricketWrapper } = styleModule;
	const introRef = useRef();
	const loopRef = useRef();
	const outroRef = useRef();
	const [introClass, setIntroClass] = useState('roll-intro');
	const [loopClass, setLoopClass] = useState('roll-loop');
	const [outroClass, setOutroClass] = useState('roll-outro hidden');

	useEffect(() => {
		setTimeout(() => {
			if (introRef.current && introRef.current.player) {
				introRef.current.one();
			}
		}, 100)

		setTimeout(() => {
			setIntroClass('roll-intro hidden');
			if (loopRef.current && loopRef.current.player) {
				loopRef.current.play();
			}
		}, 1000)
	}, [])

	const handleClick = () => {
		setIntroClass('roll-intro hidden');
		setLoopClass('roll-loop hidden');
		if (introRef.current) {
			introRef.current.stop();
		}
		if (loopRef.current) {
			loopRef.current.stop();
		}
		if (outroRef.current && !outroRef.current.isPlay) {
			setOutroClass('roll-outro');
			setIsRolling(true);
			outroRef.current.one();

			setTimeout(() => {
				setOutroClass('roll-outro hidden');
				onClickRollBtn();
			}, 1500);
		}
	}

	return (
		<CricketWrapper onClick={handleClick}>
			<ApngComponent
				ref={introRef}
				className={introClass}
				rate={1}
				src={CricketRollBtnIntro}
			/>
			<ApngComponent
				ref={loopRef}
				className={loopClass}
				rate={1}
				src={CricketRollBtnLoop}
			/>
			<ApngComponent
				ref={outroRef}
				className={outroClass}
				rate={1}
				src={CricketRollBtnOutro}
			/>
		</CricketWrapper>
	)
}

export default Theme(CricketLetsRoll, 'components/LetsRoll');