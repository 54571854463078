import React, { useContext } from 'react';
import { DataContext } from "contexts/DataContextContainer";
import Theme from 'containers/Theme';
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import {
  FreeStarLeft,
  FreeStarLeftPt,
	FreeStarLeftEs
} from "assets/images";

const Dice = () => {
  const { i18n } = useTranslation();
	const { freeCnt } = useContext(DataContext);

  const handleFreeStarLeft = (lang) => {
		switch (lang) {
			case 'pt':
			case 'pt-BR':
      case 'pt-br':
				return FreeStarLeftPt
			case 'es':
				return FreeStarLeftEs
			default:
				return FreeStarLeft
		}
	}

	return (
		<div className="free-star-left-wrapper">
			<Fade>
				<div className="free-star-left">
					<div className="free-star-image" style={{ backgroundImage: `url(${handleFreeStarLeft(i18n.language)})` }}></div>
					<div className="free-left-value">{freeCnt}</div>
				</div>
			</Fade>
		</div>
	)
}

export default Theme(Dice, 'components/FreeGameAnimation');