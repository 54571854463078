import React, { useContext, useEffect, useRef } from 'react';
import { DataContext } from 'contexts/DataContextContainer';
import Theme from 'containers/Theme';
import ApngComponent from 'react-apng';
import {
	IntroBallAlign,
	IntroBallContainer,
} from 'assets/images';

const CricketPlayballIntroOutro = ({ styleModule }) => {
	const { showRollButton, gameEngineState, gameEvents, audioRef, setSideBetsEnabled, sideBetsIntro } = useContext(DataContext);
	const { Wrapper } = styleModule;
	const playIntroRef = useRef();
	const playOutroRef = useRef();
	const wrapperRef = useRef();
	const waitForPlayer = useRef(false);

	const handleComplete = () => {
		if (sideBetsIntro) {
			setSideBetsEnabled(true)
		}
		if (gameEngineState.gameSession.gameState === 'needs-to-roll') {
			showRollButton(true);
		}
	};

	const handleOutroAnimationCompleted = (f) => {
    if (f >= playOutroRef.current.apng.frames.length - 1) {
			wrapperRef.current.style.opacity = 0;
			handleComplete();
		}
	}

	const handleIntroAnimationCompleted = (f) => {
    if (f >= playIntroRef.current.apng.frames.length - 1) {
			setTimeout(() => {
				gameEvents.emit('animate-before-pitch');
				playIntroRef.current.refs.canvasBox.style.opacity = 0;
				playOutroRef.current.refs.canvasBox.style.opacity = 1;
				playOutroRef.current.player.on('frame', handleOutroAnimationCompleted);
				playOutroRef.current.one();
			}, 1000);
		}
	}

	const handleBeginAnimation = () => {
		console.log("Handling animate-ball-intro");
		waitForPlayer.current = setInterval(() => {
			if (playIntroRef.current && playIntroRef.current.player) {
				clearInterval(waitForPlayer.current);
				audioRef.current.playBallIntroSound();
				wrapperRef.current.style.opacity = 1;
				playIntroRef.current.refs.canvasBox.style.opacity = 1;
				playIntroRef.current.player.on('frame', handleIntroAnimationCompleted);
				playIntroRef.current.one();
			}
		}, 50)
	}

	useEffect(() => {
		gameEvents.on('animate-ball-intro', handleBeginAnimation);

		return () => {
			gameEvents.off('animate-ball-intro', handleBeginAnimation);

			if (playIntroRef.current && playIntroRef.current.player) {
				playIntroRef.current.player.off('frame', handleIntroAnimationCompleted);
			}

			if (playOutroRef.current && playOutroRef.current.player) {
				playOutroRef.current.player.off('frame', handleOutroAnimationCompleted);
			}
		};
	}, []);

	const IntroBallContainerCached = window.sb_preload_memory[IntroBallContainer];
	const IntroBallAlignCached = window.sb_preload_memory[IntroBallAlign];

	return (
		<Wrapper ref={wrapperRef} className="cricket-playball-outro-intro">
			<ApngComponent className="playball-outro" ref={playOutroRef} rate={1} src={IntroBallContainerCached.src} />
			<ApngComponent className="playball-intro" ref={playIntroRef} rate={1} src={IntroBallAlignCached.src} />
		</Wrapper>
	)
}

export default Theme(CricketPlayballIntroOutro, 'components/CricketPlayballIntroOutro');
