export const fonts = {
  roboto: 'Roboto',
  mono: 'Menlo, Monaco, monospace',
  main: 'Montserrat'
}

export const colors = {
  green: '#7CF74B',
  blue: '#2C53E0',
  redColor: '#EB3223',
  mainColor: '#3C3D3C',
  border: '#525558',
  gray: '#60646c',
  grayDark: '#333437',
  grayMedium: '#3f4246',
  grayLight: '#515457',
  orange: '#ffa55a',
  yellow: '#ffca5b',
  whiteDark: 'rgba(255, 255, 255, 0.3)',
  yellow1: '#fbe741'
}

export const mixins = {
  drawerWidth: '395px',
  mobileWidth: 450,
  mobileHeight: 1000,
  ipadHeight: 1100,
}