import React, { useContext, useCallback } from 'react'
import { DataContext } from 'contexts/DataContextContainer';
import CountUp from 'react-countup';
import SingleText from 'components/SingleText';
import { currencyFormatter } from 'utils/currency-formatter';
import Theme from 'containers/Theme';
import { useTranslation } from "react-i18next";

const Coin = ({
  onEnd = () => { },
  styleModule,
}) => {
  const { i18n } = useTranslation();

  const { bonus, currencyFormat } = useContext(DataContext);

  const formatValueCallback = useCallback((val) => {
    return currencyFormatter(val, currencyFormat);
  })

  const winContainer = () => {
    switch (i18n.language) {
      case 'pt':
      case 'pt-BR':
      case 'pt-Br':
      case 'pt-br':
        return 'win-pt';
      case 'es':
        return 'win-es';
      default:
        return 'win-en';
    }
  }

  return (
    <styleModule.CoinContainer className={`coin ${winContainer()}`}>
      <div className='total-earning'>
        {bonus !== 0
          ? <CountUp formattingFn={formatValueCallback} end={bonus} duration={2} decimals={2} delay={0} onEnd={onEnd}>
            {({ countUpRef }) => (
              <div>
                <span
                  ref={countUpRef}
                  style={{
                    color: 'white',
                    fontWeight: 700,
                    fontSize: 20,
                    fontFamily: 'Roboto',
                  }}
                />
              </div>
            )}
          </CountUp>
          : <SingleText weight={700} size={20}>
            <span>{currencyFormatter(bonus, currencyFormat)}</span>
          </SingleText>
        }
      </div>
    </styleModule.CoinContainer>
  )
};

export default Theme(Coin, 'components/Coin');