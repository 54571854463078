import {
  BgCoinContainer,
  BgHolesContainer,
  BgHole,
  Pane,
  BtnRoll,
  NumBlue,
  NumGreen,
  NumOrange,
  NumWhite,
  Num7,
  JackPot,
  JackPotAnimated,
  AnimatedBarWinNoText,
  Handler,
  WIN,
  Ray,
  Star1,
  Star2,
  Star3,
  Star4,
  Star5,
  Star6,
  IconCheck,
  StarSilver,
  IconCheckNum,
  BetPane,
  OneStrike,
  TwoStrike,
  NoStrike,
  BalanceValue,
  Border,
  StarStreak,
  FreeStarLeft,
  FreeStarPane,
  FreeStarLeftPt,
  FreeStarPanePt,
  FreeStarRightPt,
  FreeStarLeftEs,
  FreeStarPaneEs,
  FreeStarRightEs,
  FakeDice,
  FakeDice12,
  WhiteFinger,
  FakePane,
  BtnGotit,
  NewGuide1,
  NewGuide2,
  NewGuide3,
  NewGuide4,
  NewGuide5,
  GuidePayoutPt,
  RollAgainDialog,
  RollAgainFemale,
  Logo,
  MessageContainer,
  JackpotContainer,
  BetBackground,
  BetMore,
  BetMorePt,
  BetMoreEs,
  FullCard,
  FullCardPt,
  FullCardEs,
  OneStrikeContainer,
  TwoStrikeContainer,
  NoStrikeContainer,
  OneStrikeContainerPt,
  TwoStrikeContainerPt,
  NoStrikeContainerPt,
  BetButtonContainer,
  ExtraActionsContainer,
  GreenMinusContainer,
  OneMoreSevenPt,
  OneMoreSevenEs,
  GreenPlusContainer,
  MenuButton,
  ToggleOnContainer,
  ToggleContainer,
  ToggleButton,
  TripleBonusRay,
  TripleWordAnimated,
  TripleWordAnimatedPt,
  TripleWordAnimatedEs,
  TripleWordCover,
  TripleWordCoverDefault,
  XMarkRotationAnimated,
  BonusMeterContainer,
  Trophy,
  JackpotCountupContainer,
  PayTableContainer,
  RedDice7,
  CoinVector,
  NextConditionContainer,
  Particle,
  SideBetAccept,
  SideBetDecline,
  NoStrikeAnimated,
  NoStrikeAnimatedPt,
  CheckMark,
  XMark,
  OneMoreSeven,
  CricketPaneContainer,
  Umpire,
  UmpireRed,
  UmpireBlue,
  UmpireOrange,
  CricketBackground,
  CricketScoreBoardBackground,
  CricketOutRedBall,
  ScoreBoardBraces,
  MetalBeamBackground,
  PlayballIntro,
  PlayballOutro,
  CricketRollBtnIntro,
  CricketRollBtnLoop,
  CricketRollBtnOutro,
  ParticleTileClick,
  SmallContainer,
  BigContainer,
  CricketNoStrikeAnimated,
  CricketLogo,
  CricketGuide1,
  CricketPitchIntro,
  CricketPitchOut,
  CricketPitchOutro,
  RolingRedBall,
  IntroBallAlign,
  IntroBallContainer,
  OutBar,
  CricketOutPerson,
  CricketFakePane,
  CricketGuide3,
  CricketGuide2,
  CricketGuide4,
  CricketGuide5,
  BaseballBackground,
  BaseballUmpire,
  BaseballBallIntro,
  BaseballBallOutro,
  BaseballLauncher,
  BaseballRollButton,
  BaseballPitchIntro,
  BaseballPitchOut,
  BaseballHit,
  BaseballRollingRedBall,
  BaseballRollingRedWhiteBall,
  BaseballMoneyContainer,
  BaseballGuide1,
  BaseballGuide2,
  BaseballGuide3,
  BaseballGuide4,
  BaseballGuide5,
  BaseballOutPerson,
  BaseballFoulBall,
  BaseballBallWhite,
  BaseballFakePane,
  BaseballTrophyWin,
  BaseballHomeRun,
  BaseballWinLabelContainer,
  BaseballWinLight1,
  BaseballWinLight2,
  BaseballJackpotRedContainer,
  BaseballCountupContainer,
  BaseballGlitter,
  BaseballScoreBoardBackground,
  BaseballScoreBoardBraces,
  BaseballMetalBeamBackground,
  BaseballSmallBball,
  BaseballOutsRedBall,
  BaseballLogo,
  BaseballRedBall,
  BaseballWhiteBall,
  BigWheelLogo,
  BigWheelGuide1,
  BigWheelGuide2,
  BigWheelGuide3,
  BigWheelGuide4,
  BigWheelGuide5,
  BigWheelFakePane,
  BigWheelGremlin,
  BigWheelNextConditionContainer,
  BigWheelSpinBtn,
  BigWheelSpinWheel,
  BigWheelBarberPole,
  BigWheelStripe,
  BigWheelWheel,
  BigWheelWheel1,
  BigWheelWheel2,
  BigWheelWheel3,
  BigWheelWheelCircle,
  BigWheelLight1,
  BigWheelLight2,
  BigWheelGremlinBlue,
  BigWheelGremlinBlueIdle,
  BigWheelGremlinBlueLaunch,
  BigWheelGremlinPink,
  BigWheelGremlinPinkIdle,
  BigWheelGremlinPinkLaunch,
  BigWheelGremlinYellow,
  BigWheelGremlinYellowIdle,
  BigWheelGremlinYellowLaunch,
  BigWheelLaunchSmoke,
  BigWheelLandingSmoke,
  BigWheelLineWinContainer,
  BigWheelLineWinMessage,
  BigWheelLineMessageContainer,
  BigWheelLineRay,
  BigWheelStarIntro,
  BigWheelStarLoop,
  BigWheelStarIntroEs,
  BigWheelStarLoopEs,
  BigWheelStarIntroPt,
  BigWheelStarLoopPt,
  BigWheelBannerBonus,
  BigWheelRedCircleBonus,
  BigWheelTripleGremlin,
  WinBgContainerEn,
  WinBgContainerPt,
  WinBgContainerEs,
  BigWheelSpinBtnOthers,
  BigWheelWinContainer,
  BigWheelBoardContainer,
  BigWheelGreenTileBg,
  BigWheelPurpleTileBg,
  BigWheelFreeRoll,
  BigWheelRoundedRectangle,
  BigWheelStar,
  BigWheelStrikesContainer,
} from './index';

const diceImages = {
  default: [
    BgCoinContainer,
    BgHolesContainer,
    BgHole,
    Pane,
    BtnRoll,
    NumBlue,
    NumGreen,
    NumOrange,
    NumWhite,
    Num7,
    JackPot,
    JackPotAnimated,
    AnimatedBarWinNoText,
    Handler,
    WIN,
    Ray,
    Star1,
    Star2,
    Star3,
    Star4,
    Star5,
    Star6,
    IconCheck,
    StarSilver,
    IconCheckNum,
    BetPane,
    OneStrike,
    TwoStrike,
    NoStrike,
    BalanceValue,
    Border,
    StarStreak,
    FreeStarLeft,
    FreeStarPane,
    FakeDice,
    FakeDice12,
    WhiteFinger,
    FakePane,
    BtnGotit,
    NewGuide1,
    NewGuide2,
    NewGuide3,
    NewGuide4,
    NewGuide5,
    RollAgainDialog,
    RollAgainFemale,
    Logo,
    MessageContainer,
    JackpotContainer,
    BetBackground,
    BetMore,
    FullCard,
    OneStrikeContainer,
    TwoStrikeContainer,
    NoStrikeContainer,
    BetButtonContainer,
    ExtraActionsContainer,
    GreenMinusContainer,
    GreenPlusContainer,
    MenuButton,
    ToggleOnContainer,
    ToggleContainer,
    ToggleButton,
    TripleBonusRay,
    TripleWordAnimated,
    TripleWordCover,
    TripleWordCoverDefault,
    XMarkRotationAnimated,
    BonusMeterContainer,
    Trophy,
    JackpotCountupContainer,
    PayTableContainer,
    RedDice7,
    CoinVector,
    NextConditionContainer,
    Particle,
    SideBetAccept,
    SideBetDecline,
    NoStrikeAnimated,
    CheckMark,
    XMark,
    OneMoreSeven,
    WinBgContainerEn,
    WinBgContainerPt,
    WinBgContainerEs,
  ],
  es: [BetMoreEs, FullCardEs, FreeStarLeftEs, FreeStarPaneEs, FreeStarRightEs],
  pt: [
    BetMorePt,
    GuidePayoutPt,
    FullCardPt,
    OneStrikeContainerPt,
    TwoStrikeContainerPt,
    NoStrikeContainerPt,
    OneMoreSevenPt,
    OneMoreSevenEs,
    FreeStarLeftPt,
    FreeStarPanePt,
    FreeStarRightPt,
    NoStrikeAnimatedPt,
    TripleWordAnimatedPt,
    TripleWordAnimatedEs,
  ],
};

const cricketImages = {
  default: [
    CricketPaneContainer,
    Umpire,
    UmpireRed,
    UmpireBlue,
    UmpireOrange,
    CricketBackground,
    CricketScoreBoardBackground,
    CricketOutRedBall,
    ScoreBoardBraces,
    MetalBeamBackground,
    PlayballIntro,
    PlayballOutro,
    CricketRollBtnIntro,
    CricketRollBtnLoop,
    CricketRollBtnOutro,
    ParticleTileClick,
    SmallContainer,
    BigContainer,
    CricketNoStrikeAnimated,
    CricketLogo,
    CricketGuide1,
    CricketPitchIntro,
    CricketPitchOut,
    CricketPitchOutro,
    RolingRedBall,
    IntroBallAlign,
    IntroBallContainer,
    OutBar,
    CricketOutPerson,
    CricketFakePane,
    CricketGuide3,
    CricketGuide2,
    CricketGuide4,
    CricketGuide5,
  ],
  pt: [],
};

const baseballImages = {
  default: [
    BaseballBackground,
    BaseballUmpire,
    BaseballBallIntro,
    BaseballBallOutro,
    BaseballLauncher,
    BaseballRollButton,
    BaseballPitchIntro,
    BaseballPitchOut,
    BaseballHit,
    BaseballRollingRedBall,
    BaseballRollingRedWhiteBall,
    BaseballMoneyContainer,
    BaseballGuide1,
    BaseballGuide2,
    BaseballGuide3,
    BaseballGuide4,
    BaseballGuide5,
    BaseballOutPerson,
    BaseballFoulBall,
    BaseballBallWhite,
    BaseballFakePane,
    BaseballTrophyWin,
    BaseballHomeRun,
    BaseballWinLabelContainer,
    BaseballWinLight1,
    BaseballWinLight2,
    BaseballJackpotRedContainer,
    BaseballCountupContainer,
    BaseballGlitter,
    BaseballScoreBoardBackground,
    BaseballScoreBoardBraces,
    BaseballMetalBeamBackground,
    BaseballSmallBball,
    BaseballOutsRedBall,
    BaseballLogo,
    BaseballRedBall,
    BaseballWhiteBall,
  ],
  pt: [],
};

const bigwheelImages = {
  default: [
    BigWheelLogo,
    BigWheelGuide1,
    BigWheelGuide2,
    BigWheelGuide3,
    BigWheelGuide4,
    BigWheelGuide5,
    BigWheelFakePane,
    BigWheelGremlin,
    BigWheelBarberPole,
    BigWheelStripe,
    BigWheelSpinBtn,
    BigWheelSpinWheel,
    BigWheelWheel,
    BigWheelWheel1,
    BigWheelWheel2,
    BigWheelWheel3,
    BigWheelWheelCircle,
    BigWheelLight1,
    BigWheelLight2,
    BigWheelGremlinBlue,
    BigWheelGremlinBlueIdle,
    BigWheelGremlinBlueLaunch,
    BigWheelGremlinPink,
    BigWheelGremlinPinkIdle,
    BigWheelGremlinPinkLaunch,
    BigWheelGremlinYellow,
    BigWheelGremlinYellowIdle,
    BigWheelGremlinYellowLaunch,
    BigWheelLaunchSmoke,
    BigWheelLandingSmoke,
    BigWheelLineWinContainer,
    BigWheelLineWinMessage,
    BigWheelLineMessageContainer,
    BigWheelLineRay,
    BigWheelStarIntro,
    BigWheelStarLoop,
    BigWheelNextConditionContainer,
    BigWheelBannerBonus,
    BigWheelRedCircleBonus,
    BigWheelTripleGremlin,
    TripleBonusRay,
    WinBgContainerEn,
    WinBgContainerPt,
    WinBgContainerEs,
    BigWheelSpinBtnOthers,
    FreeStarPane,
    BgHolesContainer,
    BgHole,
    BetPane,
    OneStrike,
    TwoStrike,
    NoStrike,
    Border,
    RollAgainDialog,
    RollAgainFemale,
    SideBetAccept,
    SideBetDecline,
    OutBar,
    CricketOutPerson,
    MetalBeamBackground,
    Pane,
    BetBackground,
    OneStrikeContainer,
    OneStrikeContainerPt,
    TwoStrikeContainer,
    SmallContainer,
    TwoStrikeContainerPt,
    NoStrikeContainer,
    NoStrikeContainerPt,
    ExtraActionsContainer,
    BigContainer,
    UmpireRed,
    BigWheelWinContainer,
    BigWheelBoardContainer,
    BigWheelGreenTileBg,
    BigWheelPurpleTileBg,
    BigWheelFreeRoll,
    BigWheelRoundedRectangle,
    BigWheelStar,
    BigWheelStrikesContainer,
    CoinVector,
    CheckMark,
    XMark,
    PayTableContainer,
    RedDice7,
    BalanceValue,
    JackPot,
    FreeStarLeft,
    FreeStarPane,
    BigWheelStarIntro,
    BigWheelStarLoop,
  ],
  es: [
    FreeStarLeftEs,
    FreeStarRightEs,
    FreeStarPaneEs,
    BigWheelStarIntroEs,
    BigWheelStarLoopEs,
  ],
  pt: [
    FreeStarLeftPt,
    FreeStarRightPt,
    FreeStarPanePt,
    BigWheelStarIntroPt,
    BigWheelStarLoopPt,
  ],
};

export const preloadImage = async (type, language) => {
  let collection = diceImages;
  const theme = type ? type.toLowerCase() : '';

  if (theme === 'cricket') {
    collection = cricketImages;
  } else if (theme === 'baseball') {
    collection = baseballImages;
  } else if (theme === 'bigwheel') {
    collection = bigwheelImages;
  }

  let images = collection.default;

  if (language !== 'en' && language !== 'en-US') {
    images = [...images, ...collection.pt];
  }

  window.sb_preload_memory = {};

  const requests = images.map((image) => {
    return new Promise((resolve) => {
      const img = new Image();

      img.src = image;
      img.onload = () => {
        resolve();
        window.sb_preload_memory[image] = img;
      };
    });
  });

  await Promise.all(requests);

  return new Promise((resolve) => {
    let delay = 0;

    if (type && type.toLowerCase() === 'cricket') {
      delay = 5000;
    }

    setTimeout(() => {
      resolve();
    }, delay);
  });
};
