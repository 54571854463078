import { Col, Row } from 'antd';
import React from 'react'
import { LogPaneContainer } from './style';

export default function LogPane({
  log = {},
}) {
  return (
    <LogPaneContainer>
      <Row>
        {Object.values(log.numbers).map((_number, _i) =>
          <Col key={_i} className={`log-pane-item ${_number.status}`} flex='30px'>
            {_number.value}
          </Col>
        )}
      </Row>
      {log.numbers.map}
    </LogPaneContainer>
  )
}

