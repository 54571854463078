import React from 'react';
import Theme from 'containers/Theme';
import { BG, BigwheelBG } from "assets/video";
import {
	CricketBackground,
	BaseballBackground
} from "assets/images";
import ThemeVisibility from "components/ThemeVisibility";

const BackgroundLayout = ({ styleModule, children }) => {
  const { BackgroundImage, BgContainer } = styleModule;

	return <BgContainer className="bg-wrapper">
		<ThemeVisibility themes={['dice']}>
			<video autoPlay muted loop id="bgVideo">
				<source src={BG} type="video/mp4"></source>
			</video>
		</ThemeVisibility>
		<ThemeVisibility themes={['bigwheel']}>
			<video autoPlay muted loop playsInline id="bgVideo">
				<source src={BigwheelBG} type="video/mp4"></source>
			</video>
		</ThemeVisibility>
		<ThemeVisibility themes={['cricket']}>
			<BackgroundImage src={CricketBackground} className="cricket-background" />
		</ThemeVisibility>
		<ThemeVisibility themes={['baseball']}>
			<BackgroundImage src={BaseballBackground} className="cricket-background" />
		</ThemeVisibility>
		{children}
	</BgContainer>
}

export default Theme(BackgroundLayout, 'layouts');
