import React from 'react';
import Theme from 'containers/Theme';
import ThemeVisibility from 'components/ThemeVisibility';
import Dice from './Dice'
import Bigwheel from './Bigwheel'

const TripleSevenAnimation = ({ win }) => {
	return (
		<>
			<ThemeVisibility themes={['dice', 'cricket', 'baseball']}>
				<Dice win={win} />
			</ThemeVisibility>
			<ThemeVisibility themes={['bigwheel']}>
				{win && <Bigwheel win={win} />}
			</ThemeVisibility>
		</>
	)
}

export default Theme(TripleSevenAnimation, 'components/TripleSevenAnimation');