import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const loadSentry = async () => {
  try {
    const data = await fetch('/site-data')
      .then(response => response.json())

    if (data.gtmId) {
      window.initGtm(window,document, 'script', 'dataLayer', data.gtmId);
    }

    Sentry.init({
      dsn: data.sentry_dsn,
      integrations: [new BrowserTracing()],
      tracesSampleRate: data.sentry_sampling_rate,
    });
  } catch (err) {
    console.log('Failed to retrieve site data');
  }

  ReactDOM.render(
      <App />,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

loadSentry();