import axios from 'axios';

export const configureAxios = () => {
	axios.interceptors.request.use(function (config) {
		const sessionId = sessionStorage.getItem('x-session-id');
		config.headers['X-SessionId'] =  sessionId;

		return config;
	});
}

export const delay = (request, duration) => {
	let hasResolved = false;
	let result = null;
	let timeoutElapsed = false;

	const promise = new Promise(async (resolve, reject) => {
		try {
			const timeout = setTimeout(() => {
				if (hasResolved) {
					resolve(result);
				}
				timeoutElapsed = true;
			}, duration);

			result = await request;
			hasResolved = true;

			if (timeoutElapsed) {
				clearTimeout(timeout);
				resolve(result);
			}
		} catch (err) {
			reject(err);
		}
	});

	return promise;
}