import styled from '@emotion/styled'

export const JackPotContainer = styled.div`
  height: 76px;
  width: 293px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;

  canvas.animated-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  span {
    z-index: 2;
  }
`;

// Cricket
export const DefaultLabel = styled.div`
  color: #fff;
  font-size: 18px;
  position: absolute;
  font-weight: bold;
  font-family: 'Barlow Semi Condensed Bold';
  z-index: 999;
`

export const NoOutsLabel = styled(DefaultLabel)`
  top: -2%;
  left: 40%;
`
