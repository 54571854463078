import React, { useContext, useEffect, useRef, useState } from 'react';
import Theme from 'containers/Theme';
import ApngComponent from 'react-apng';
import { DataContext } from 'contexts/DataContextContainer';
import { numbersTemp, cricketNumbersTemp } from "service";
import {
  CricketPitchIntro,
  CricketPitchOut,
  CricketPitchOutro,
  RolingRedBall,
  CricketOutRedBall,
} from 'assets/images';

const CricketNumberReveal = ({ styleModule }) => {
  const {
    gameEvents,
    streakStrikeRef,
    audioRef,
    showGameOverBanner,
    isPlayingSound,
    isTurbo
  } = useContext(DataContext);
  const { Wrapper, Number } = styleModule;
  const [rolledNum, setRolledNum] = useState(null);
  const wrapperRef = useRef();
  const rolledNumRef = useRef();
  const pitchRef = useRef();
  const pitchOutRef = useRef();
  const pitchOutroRef = useRef();
  const rollingBallRef = useRef();
  const redBallRef = useRef();
  const numberRef = useRef();
  const pitchAnimationDone = useRef(false);
  const pitchFromIntro = useRef(false);
  const turboRef = useRef(false);

  const toggleNumberRef = (opacity) => {
    try {
      numberRef.current.style.opacity = opacity;
    } catch (ex) {}
  }

  const toggleWrapperRef = (field, value) => {
    try {
      wrapperRef.current.style[field] = value;
    } catch (ex) {}
  }

  const onFrameChanged = (apngRef) => (f) => {
    if (!apngRef) {
      return;
    }

    if (f === 1) {
      audioRef.current.playBallsFillingHopper();
    }else if (f === 25) {
      audioRef.current.playSportRunningSound();
    }else if (f === 33) {
      audioRef.current.stopPlayBallsFillingHopper();
    }else if (f === 39) {
      audioRef.current.playBallThrow();
    }

    if (f === 50 && rolledNumRef.current === 7 && streakStrikeRef.current === 2) {
      rollingBallRef.current.refs.canvasBox.style['margin-bottom'] = '125px';
      apngRef.pause();
      animateRollingBall(apngRef);
      return;
    }

    if (f === 54 && rolledNumRef.current !== 7 && streakStrikeRef.current === 2) {
      rollingBallRef.current.refs.canvasBox.style['margin-bottom'] = '121px';
      apngRef.pause();
      animateRollingBall(apngRef);
      return;
    }

    if (f >= apngRef.apng.frames.length - 5 && rolledNumRef.current !== 7) {
      toggleNumberRef(1);
    }

    if (rolledNumRef.current === 7 && streakStrikeRef.current < 3 && f === 55) {
			isPlayingSound && audioRef.current.playSoundStrike();
    }

    if (rolledNumRef.current === 7 && streakStrikeRef.current < 3 && f == 59) {
			isPlayingSound && audioRef.current.playSoundBoo();
    }

    if (f >= apngRef.apng.frames.length - 1 && !pitchAnimationDone.current) {
      toggleWrapperRef('z-index', 1);
      pitchAnimationDone.current = true;
      gameEvents.emit('pitch-animation-done');
    } else {
      pitchAnimationDone.current = false;
    }
  }

  const animate = (apngRef) => {
    toggleWrapperRef('opacity', 1);
    toggleWrapperRef('z-index', 3);
    if (apngRef && apngRef.player) {
      apngRef.refs.canvasBox.style.opacity = 1;
      toggleNumberRef(0);

      if (apngRef.player._events.frame === undefined) {
        apngRef.player.on('frame', onFrameChanged(apngRef));
      }

      if(turboRef.current){
        pitchFromIntro.current = false;
        apngRef.stop();
        while(apngRef.player._currentFrameNumber < apngRef.apng.frames.length - 1){
          apngRef.player.renderNextFrame();
        }
      }else{
        if (pitchFromIntro.current) {
          pitchFromIntro.current = false;
          apngRef.continue();
        } else {
          apngRef.one();
        }
      }
    }
  }

  const onApiDone = (number) => {
    pitchOutroRef.current.refs.canvasBox.style.opacity = 0;
    pitchAnimationDone.current = false;
    let refToAnimate = pitchRef;

    if (number !== 7) {
      pitchOutRef.current.refs.canvasBox.style.opacity = 0;
    } else {
      pitchRef.current.refs.canvasBox.style.opacity = 0;
      refToAnimate = pitchOutRef;
    }

    rolledNumRef.current = number;
    setRolledNum(number);
    animate(refToAnimate.current);
  }

  const handlePickAnimation = (f) => {
    if (f >= pitchOutroRef.current.apng.frames.length - 1) {
      toggleWrapperRef('z-index', 1);
    }
  }

  const onAnimatePick = () => {
    if (rolledNumRef.current === 7) {
      return;
    }

    try {
      toggleWrapperRef('z-index', 3);
      audioRef.current.playBatImpact();
      audioRef.current.playCrowdCheer();
      pitchOutRef.current.refs.canvasBox.style.opacity = 0;
      pitchRef.current.refs.canvasBox.style.opacity = 0;
      pitchOutroRef.current.refs.canvasBox.style.opacity = 1;

      if (pitchOutroRef.current.player._events.frame === undefined) {
        pitchOutroRef.current.player.on('frame', handlePickAnimation);
      }
      pitchOutroRef.current.one();
      toggleNumberRef(0);
    } catch (ex) {
      console.log('An error occurred while trying to animate pick');
    }
  }

  const animateRollingBall = (apngRef) => {
    if (rollingBallRef.current) {
      if (rolledNumRef.current === 7) {
        wrapperRef.current.style['z-index'] = 3;
      }

      rollingBallRef.current.refs.canvasBox.style.visibility = 'visible';
      rollingBallRef.current.play();

      setTimeout(() => {
        if (rolledNumRef.current === 7) {
          apngRef.stop();
          rollingBallRef.current.stop();
          redBallRef.current.style.visibility = 'visible';
          gameEvents.emit('cricket-show-price');

          setTimeout(() => {
            pitchAnimationDone.current = true;
            gameEvents.emit('pitch-animation-done');
          }, 2000);
        } else {
          wrapperRef.current.style['z-index'] = 1;
          apngRef.continue();
          rollingBallRef.current.refs.canvasBox.style.visibility = 'hidden';
          rollingBallRef.current.stop();
        }
      }, 4000);
    }
  }

  const handleCheckFrameBeforePitch = (f) => {
    if (f === 1) {
      audioRef.current.playBallsFillingHopper();
    }

    if (f === 33) {
      audioRef.current.stopPlayBallsFillingHopper();
    }

    if (f === 33) {
      pitchRef.current.pause();
      pitchRef.current.player.off('frame', handleCheckFrameBeforePitch);
    }
  }

  const handleAnimateBeforePitch = () => {
    pitchFromIntro.current = true;
    pitchOutroRef.current.refs.canvasBox.style.opacity = 0;
    if(!turboRef.current){
      pitchRef.current.player.on('frame', handleCheckFrameBeforePitch);
      pitchRef.current.one();
    }
  }

  const getCricketNumber = (num) => {
    const index = numbersTemp.findIndex(n => n === num)
    if (index !== -1) {
      return cricketNumbersTemp[index];
    }

    return num;
  }

  useEffect(() => {
    if (showGameOverBanner) {
      redBallRef.current.style.visibility = 'hidden';
      wrapperRef.current.style['z-index'] = 1;
      rollingBallRef.current.refs.canvasBox.style.visibility = 'hidden';
    }
  }, [showGameOverBanner]);

	useEffect(() => {
		turboRef.current = isTurbo;
	}, [isTurbo]);

  useEffect(() => {
    toggleNumberRef(0);
    gameEvents.on('animate-before-pitch', handleAnimateBeforePitch);
    gameEvents.on('roll-api-done', onApiDone);
    gameEvents.on('on-click-number', onAnimatePick);

    return () => {
      if (pitchRef.current) {
        pitchRef.current.player.off('frame', onFrameChanged(pitchRef.current))
      }

      if (pitchOutRef.current) {
        pitchOutRef.current.player.off('frame', onFrameChanged(pitchOutRef.current))
      }

      if (pitchOutroRef.current) {
        pitchOutroRef.current.player.off('frame', handlePickAnimation);
      }

      gameEvents.off('animate-before-pitch', handleAnimateBeforePitch);
      gameEvents.off('roll-api-done', onApiDone);
      gameEvents.off('on-click-number', onAnimatePick);
    };
  }, []);

  return (
    <Wrapper ref={wrapperRef} className="cricket-number-reveal" key="cricket-number-reveal">
			<ApngComponent className="pitch-intro" ref={pitchRef} rate={1.1} src={CricketPitchIntro} />
			<ApngComponent className="pitch-out" ref={pitchOutRef} rate={1.1} src={CricketPitchOut} />
			<ApngComponent className="pitch-outro" ref={pitchOutroRef} rate={2} src={CricketPitchOutro} />
			<ApngComponent className="rolling-red-ball" ref={rollingBallRef} rate={1} src={RolingRedBall} />
      <img ref={redBallRef} src={CricketOutRedBall} className="stopped-red-ball" />
      {rolledNum && (
        <Number key={`rolled-num-${rolledNum}`} ref={numberRef}>{getCricketNumber(rolledNum)}</Number>
      )}
    </Wrapper>
  );
}

export default Theme(React.memo(CricketNumberReveal), 'components/CricketNumberReveal');