import React, { useContext } from 'react'
import Theme from 'containers/Theme';
import { DataContext } from "contexts/DataContextContainer";
import { useTranslation } from "react-i18next";

const isInsufficientFunds = error => error.indexOf('error.insufficient.funds') !== -1

const ErrorModal = ({ styleModule, message }) => {
  const { t } = useTranslation();
  const { CloseButton, Container, Box } = styleModule;
  const { setError, setIsRolling, showRollButton, setSideBet } = useContext(DataContext);

  if (message === '') {
    return null;
  }

  let showError = false;
  let messageStr = message;

  const handleClick = (param) => {
    if (messageStr.indexOf('Insufficient funds') !== -1) {
      setIsRolling(false);
      showRollButton(true);
      setSideBet(undefined);

      let dice1 = document.getElementById("dice1");
      let dice2 = document.getElementById("dice2");

      if (dice1 && dice2) {
        dice1.classList.toggle("indefinite", false);
        dice2.classList.toggle("indefinite", false);
      }
      setError('');
    } else if (param.action === 'close' || param.action === 'login') {
        setError('');
        window.open('', '_self', '');
        window.close();
    }
    else {
      setError('');
      window.location.reload();
    }
  }

  if (messageStr && isInsufficientFunds(messageStr)) {
    messageStr = t("error.insufficientFunds");
    showError = {
      label: t("error.close"),
      action: 'close',
    };
  } else if (messageStr && messageStr.indexOf('session') !== -1) {
    messageStr = t("error.timeOut");
    showError = {
      label: t("error.close"),
      action: 'login',
    };
  } else if (messageStr && messageStr.indexOf('network') !== -1) {
    messageStr = t("error.cantConnectToServer");
    showError = {
      label: t("common.refresh"),
      action: 'refresh',
    };
  }
  else if (messageStr && messageStr.indexOf('Out of Funds') !== -1) {
    messageStr = t("error.insufficientFunds");
    showError = {
      label: t("error.close"),
      action: 'close',
    };
  }
  else if (messageStr && messageStr.indexOf('unexpected') !== -1) {
    messageStr = t("error.unexpectedError");
    showError = {
      label: t("common.close"),
      action: 'close',
    };
  } else if (messageStr && messageStr.indexOf('Hourly wager limit') !== -1) {
    messageStr = t("error.hourly");
    showError = {
      label: t("common.refresh"),
      action: 'refresh',
    };
  } else if (messageStr && messageStr.indexOf('Daily wager limit') !== -1) {
    messageStr = t("error.daily");
    showError = {
      label: t("common.refresh"),
      action: 'refresh',
    };
  } else if (messageStr && messageStr.indexOf('Weekly wager limit') !== -1) {
    messageStr = t("error.weekly");
    showError = {
      label: t("common.refresh"),
      action: 'refresh',
    };
  } else if (messageStr && messageStr.indexOf('Monthly wager limit') !== -1) {
    messageStr = t("error.monthly");
    showError = {
      label: t("common.refresh"),
      action: 'refresh',
    };
  } else {
    messageStr = t("error.unexpectedError"); //
    showError = {
      label: t("common.refresh"),
      action: 'refresh',
    };
  }


  return <Container className="error-modal">
    <Box>
      <div>{messageStr}</div>
      {showError && <CloseButton onClick={() => handleClick(showError)}>{showError.label}</CloseButton>}
    </Box>
  </Container>;
}

export default Theme(ErrorModal, 'components/ErrorModal');