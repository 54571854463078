/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import Box from "components/Box";
import React, { Fragment, useContext, useEffect } from "react";
import { DataContext } from "contexts/DataContextContainer";
import StarsWin from "components/StarsWin";
import SingleAntImage from "components/SingleAntImage";
import { Ray } from "assets/images";
import { Bounce } from "react-awesome-reveal";
import SingleText from "components/SingleText";
import { useHistory } from "react-router";
import Theme from 'containers/Theme';
import { currencyFormatter } from 'utils/currency-formatter';

function GameOver(props) {
  const { styleModule } = props;
  const history = useHistory();

  const { bet, winnedLines, freeCnt, currencyFormat } = useContext(DataContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (freeCnt > 0) {
        history.push('/game/rolling')
      } else {
        history.push('/game/bet?next=true')
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [history.location.pathname])

  return (
    <styleModule.GameOverContainer>
      <Box className="rolling-wrapper">
        <div className="bet-pane-container">
          <Fragment>
            <h2 className="bet-pane-title">GET A FULL CARD AND WIN BIG!</h2>
            <p className="bet-pane-description">
              The more you bet the more you win!
            </p>
            <div className="one-two-strikes">
              <div className="two-strike-wrapper">
                <p className="bet-pane-card-title mb-0">FULL CARD</p>
                <div className="two-strike">
                  <span>{currencyFormatter(bet * 50, currencyFormat)}</span>
                </div>
              </div>
              <div className="one-strike-wrapper">
                <p className="bet-pane-card-title mb-0">FULL CARD</p>
                <div className="one-strike">
                  <span>{currencyFormatter(bet * 400, currencyFormat)}</span>
                </div>
              </div>
            </div>
            <div className="no-strikes">
              <p className="bet-pane-title mb-0">FULL CARD</p>
              <div className="no-strike-bg">
                <span>{currencyFormatter(bet, currencyFormat)}</span>
              </div>
            </div>
          </Fragment>
        </div>
        <div className="gameover-screen">
          <Box
            className="win-stars-wrapper"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {winnedLines > 0 && (
              <>
                <div className="stars-container">
                  <StarsWin />
                </div>
                <div className="win-ray">
                  <SingleAntImage src={Ray} width="100%" height="auto" />
                </div>
                <Bounce className="win-mark">
                  {winnedLines > 10 ? (
                    <SingleText
                      size={20}
                      color="#865005"
                      weight={900}
                      align="center"
                      marginBottom={15}
                    >
                      POCHINGO
                    </SingleText>
                  ) : (
                    <SingleText
                      size={16}
                      color="#865005"
                      weight={900}
                      align="center"
                    >
                      {winnedLines} LINE <br />
                      <b style={{ fontSize: 34 }}>WIN</b>
                    </SingleText>
                  )}
                </Bounce>
              </>
            )}
          </Box>
        </div>
      </Box>
    </styleModule.GameOverContainer>
  );
}

export default Theme(GameOver, 'components/GameOver');