import React from 'react'
import PropTypes from 'prop-types';
import { Star1, Star2, Star3, Star4, Star5, Star6 } from 'assets/images';
import Theme from 'containers/Theme';

const handle = 50;
const items = [
  { hey: "1", color: "red", left: 10 + handle, bottom: 110 + handle, time: 3, delay: 2, image: Star1, },
  { hey: "2", color: "green", left: 30 + handle, bottom: 90 + handle, time: 4, delay: 1, image: Star2, },
  { hey: "3", color: "yellow", left: 50 + handle, bottom: 70 + handle, time: 1, delay: 0, image: Star3, },
  { hey: "4", color: "gray", left: 70 + handle, bottom: 50 + handle, time: 1, delay: 1, image: Star4, },
  { hey: "5", color: "blue", left: 90 + handle, bottom: 30 + handle, time: 2, delay: 3, image: Star5, },
  { hey: "6", color: "red", left: 80 + handle, bottom: 10 + handle, time: 3, delay: 0, image: Star6, },
  { hey: "7", color: "blue", left: 120 + handle, bottom: 10 + handle, time: 4, delay: 1, image: Star1, },
  { hey: "8", color: "green", left: 20 + handle, bottom: 80 + handle, time: 2, delay: 0, image: Star2, },
  { hey: "9", color: "green", left: 40 + handle, bottom: 90 + handle, time: 3, delay: 1, image: Star3, },
  { hey: "10", color: "green", left: 120 + handle, bottom: 100 + handle, time: 2, delay: 0, image: Star4, },

  { hey: "11", color: "red", left: -(10 + handle), bottom: 110 + handle, time: 2, delay: 0, image: Star5, },
  { hey: "12", color: "green", left: -(30 + handle), bottom: 90 + handle, time: 3, delay: 1, image: Star6, },
  { hey: "13", color: "yellow", left: -(50 + handle), bottom: 70 + handle, time: 2, delay: 0, image: Star6, },
  { hey: "14", color: "gray", left: -(70 + handle), bottom: 50 + handle, time: 2, delay: 1, image: Star1, },
  { hey: "15", color: "blue", left: -(90 + handle), bottom: 30 + handle, time: 1, delay: 3, image: Star2, },
  { hey: "16", color: "red", left: -(80 + handle), bottom: 10 + handle, time: 2, delay: 0, image: Star3, },
  { hey: "17", color: "blue", left: -(120 + handle), bottom: 10 + handle, time: 3, delay: 1, image: Star4, },
  { hey: "18", color: "green", left: -(20 + handle), bottom: 80 + handle, time: 3, delay: 0, image: Star5, },
  { hey: "19", color: "green", left: -(40 + handle), bottom: 90 + handle, time: 2, delay: 1, image: Star6, },
  { hey: "20", color: "green", left: -(120 + handle), bottom: 100 + handle, time: 3, delay: 0, image: Star1, },

  { hey: "21", color: "red", left: -(10 + handle), bottom: -(110 + handle), time: 2, delay: 2, image: Star2, },
  { hey: "22", color: "green", left: -(30 + handle), bottom: -(90 + handle), time: 3, delay: 0, image: Star3, },
  { hey: "23", color: "yellow", left: -(50 + handle), bottom: -(70 + handle), time: 2, delay: 2, image: Star4, },
  { hey: "24", color: "gray", left: -(70 + handle), bottom: -(50 + handle), time: 3, delay: 0, image: Star5, },
  { hey: "25", color: "blue", left: -(90 + handle), bottom: -(30 + handle), time: 3, delay: 3, image: Star6, },
  { hey: "26", color: "red", left: -(80 + handle), bottom: -(10 + handle), time: 1, delay: 2, image: Star1, },
  { hey: "27", color: "blue", left: -(120 + handle), bottom: -(10 + handle), time: 2, delay: 0, image: Star2, },
  { hey: "28", color: "green", left: -(20 + handle), bottom: -(80 + handle), time: 3, delay: 2, image: Star3, },
  { hey: "29", color: "green", left: -(40 + handle), bottom: -(90 + handle), time: 2, delay: 0, image: Star4, },
  { hey: "30", color: "green", left: -(120 + handle), bottom: -(100 + handle), time: 1, delay: 2, image: Star5, },

  { hey: "31", color: "red", left: 10 + handle, bottom: -(110 + handle), time: 1, delay: 2, image: Star6, },
  { hey: "32", color: "green", left: 30 + handle, bottom: -(90 + handle), time: 2, delay: 0, image: Star1, },
  { hey: "33", color: "yellow", left: 50 + handle, bottom: -(70 + handle), time: 3, delay: 2, image: Star2, },
  { hey: "34", color: "gray", left: 70 + handle, bottom: -(50 + handle), time: 2, delay: 0, image: Star3, },
  { hey: "35", color: "blue", left: 90 + handle, bottom: -(30 + handle), time: 3, delay: 3, image: Star4, },
  { hey: "36", color: "red", left: 80 + handle, bottom: -(10 + handle), time: 4, delay: 2, image: Star5, },
  { hey: "37", color: "blue", left: 120 + handle, bottom: -(10 + handle), time: 2, delay: 0, image: Star6, },
  { hey: "38", color: "green", left: 20 + handle, bottom: -(80 + handle), time: 3, delay: 2, image: Star1, },
  { hey: "39", color: "green", left: 40 + handle, bottom: -(90 + handle), time: 1, delay: 0, image: Star2, },
  { hey: "40", color: "green", left: 120 + handle, bottom: -(100 + handle), time: 3, delay: 2, image: Star3, },
];

const StarsWin = ({
  children, 
  className, 
  framer, 
  onClick = () => {}, 
  styleModule,
  controller,
  themeSounds,
  ...otherProps
}) => {

  return (
    <styleModule.StarsWinContainer>
      {items.map((_item, _i) => (
        <styleModule.StarItemContainer
          key={_i}
          hey={_item.hey}
          color={_item.color}
          left={_item.left}
          bottom={_item.bottom}
          time={_item.time}
          delay={_item.delay}
          image={_item.image}
        >
          {_item.hey}
        </styleModule.StarItemContainer>
      ))}
    </styleModule.StarsWinContainer>
  )
}

StarsWin.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  framer: PropTypes.object,
  onClick: PropTypes.func,
};

export default Theme(StarsWin, 'components/StarsWin');