/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React from "react";

function Test() {
  return (
    <div>
      <button id="btn1" onClick={() => window.play1()}>Play 777 Animation</button>
      <button id="btn2">Play Miss Animation</button>
    </div>
  );
}

export default Test;
